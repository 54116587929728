export const masterFieldLabel = {
  FULL_NAME: "Full Name (As per SSC/HSC marksheet)",
  EMAIL: "Email",
  MOBILE_NO: "Mobile No",
  FATHERS_NAME: "Father's Name (First Name Only)",
  MOTHERS_NAME: "Mother's Name (First Name Only)",
  MARITAL_STATUS: "Marital Status",
  SPOUSE_NAME: "Spouse Name",
  GENDER: "Gender",
  CONFIRM_GENDER:"Confirm Gender",
  DOB: "DOB (DD/MM/YYYY)",
  RELIGION: "Religion",
  REGION: "Region",
  MOTHER_TONGUE: "Mother Tongue",
  ANNUAL_FAMILY_INCOME: "Annual Family Income",
  NATIONALITY: "Nationality",
  ENTER_DOB: "Enter DOB",
  SELECT_STATE_FOR_EXAMINATION_CENTER: "Select State for Examination Center",
  SAME_AS_PERMANENT_ADDRESS: "Same as Permanent Address",
  PERMANENT_ADDRESS_LINE_1: "Permanent Address Line 1",
  PERMANENT_ADDRESS_LINE_2: "Permanent Address Line 2",
  PERMANENT_ADDRESS_LINE_3: "Permanent Address Line 3",
  PERMANENT_STATE: "Permanent State",
  PERMANENT_DISTRICT: "Permanent District",
  PERMANENT_TALUKA: "Permanent Taluka",
  PERMANENT_VILLAGE: "Permanent Village",
  PERMANENT_PINCODE: "Permanent Pincode",
  COMMUNICATION_ADDRESS_LINE_1: "Communication Address Line 1",
  COMMUNICATION_ADDRESS_LINE_2: "Communication Address Line 2",
  COMMUNICATION_ADDRESS_LINE_3: "Communication Address Line 3",
  COMMUNICATION_STATE: "Communication State",
  COMMUNICATION_DISTRICT: "Communication District",
  COMMUNICATION_TALUKA: "Communication Taluka",
  COMMUNICATION_VILLAGE: "Communication Village",
  COMMUNICATION_PINCODE: "Communication Pincode",
  STD_CODE:"STD Code",
  TELEPHONE_NO: "Telephone No",
  DOMICILE_CERTIFICATE: "Do you have Domicile Certificate?",
  MINORITY_CATEGORY: "Do you belong to Minority Category?",
  LINGUISTIC_MINORITY: "Linguistic Minority",
  RELIGIOUS_MINORITY: "Religious Minority",
  PERSON_WITH_DISABILITY: "Are you Person with Disability (PWD)?",
  TYPE_OF_DISABILITY: "Type of Disability",
  PWD_SUB_TYPE: "",
  RESERVED_CATEGORY: "Reserved Category",
  SELECT_CASTE: "Select Caste to Which you Belong",
  POSSESS_CASTE_CERTIFICATE: "Do you possess Caste Certificate?",
  CASTE_VALIDITY_CERTIFICATE_NO: "Caste Validity Certificate No",
  CASTE_CERTIFICATE_RECEIPT_NO:"Caste Certificate Receipt No",
  CASTE_CERTIFICATE_NO: "Caste Certificate No",
  POSSESS_CASTE_VALIDITY: "Do you possess Caste Validity?",
  CASTE_CERTIFICATE_APPLICATION_DATE: "Caste Certificate Application Date",
  CASTE_VALIDITY_APPLICATION_NO:
    "Caste / Tribe Validity Certificate Application Number",
  CASTE_VALIDITY_APPLICATION_DATE:
    "Caste / Tribe Validity Certificate Application Date",
  CASTE_VALIDITY_ISSUING_AUTH_NAME:
    "Caste / Tribe Validity Certificate Issuing Authority Name",
  CASTE_VALIDITY_ISSUING_DISTRICT:
    "Caste / Tribe Validity Certificate Issuing District",
  CANDIDATE_NAME_AS_CASTE_CERTIFICATE:
    "Candidate Name as per Caste Certificates",
  ENTER_CASTE_NAME: "Enter Caste Name",
  NON_CREAMY_LAYER_CERTIFICATE:
    "Do you have Non Creamy Layer Certificate valid up to << Due Date >>",
  NON_CREAMY_LAYER_CERTIFICATE_APP_NO:
    "Non-Creamy Layer Certificate Application Number",
  NON_CREAMY_LAYER_CERTIFICATE_APP_DATE:
    "Non-Creamy Layer Certificate Application Date",
  NON_CREAMY_LAYER_AUTHORITY: "NCL Authority",

  EWS: "Do you want to Apply for EWS (Economically Weaker Section) Seats ?",
  EWS_CERTIFICATE_STATUS:
    "Select EWS Certificate(Eligibility Certificate for Economically Weaker  Section) Status",
  EWS_Certificate_Application_Number: "EWS Certificate Application Number ",
  EWS_Certificate_Application_Date: "EWS Certificate Application Date",
  EWS_Certificate_Issuing_District: "EWS Certificate Issuing District",
  EWS_Certificate_Issuing_Taluka: "EWS Certificate Issuing Taluka",
  ORPHAN: "Are you Orphan?",
  ORPHAN_TYPE: "Orphan Type",
  SSC_PASSED_FROM_INDIA: "SSC Passed From India",
  SSC_SCHOOL_STATE: "Select SSC School State",
  EXAM_STATE_PREFERENCE: "Select State for Examination Center",
  SSC_SCHOOL_DISTRICT: "SSC School District",
  SSC_SCHOOL_TALUKA: "SSC School Taluka",
  SSC_PASSING_YEAR: "Select SSC/Equivalent Passing Year",
  SSC_SCHOOL_NAME: "SSC School Name",
  SSC_BOARD: "Select SSC/Equivalent Board",
  SSC_TOTAL_PERCENTAGE: "SSC/Equivalent Total Percentage",
  APPEARING_FOR_HSC: "Are you Appearing/Appeared for 12th (HSC) exam in 2025",
  HSC_COLLEGE_STATE: "HSC College State",
  HSC_COLLEGE_DISTRICT: "HSC College District",
  HSC_COLLEGE_TALUKA: "HSC College Taluka",
  HSC_PASSING_YEAR: "Select HSC Passing Year",
  HSC_BOARD: "Select HSC Board",
  HSC_COLLEGE_NAME: "HSC College Name",
  HSC_MARKS_TYPE: "HSC Marks Type",
  HSC_MARKS_OBTAINED: "HSC Marks Obtained",
  HSC_MARKS_OUT_OF: "HSC Marks Out Of",
  HSC_CALCULATED_PERCENTAGE: "HSC Percentage",
  HSC_ENG_MARKS_OBTAINED: "HSC English Marks Obtained",
  HSC_ENG_MARKS_OUT_OF: "HSC English Marks Out Of",
  HSC_ENG_CALCULATED_PERCENTAGE: "HSC English Percentage",
  CANDIDATE_NAME_ON_HSC: "Candidate's Name as on HSC Marksheet",
  CANDIDATE_MOTHER_NAME_ON_HSC: "Candidate's Mother Name as on HSC Marksheet",
  SELECT_SUBJECT_GROUP: "Select Subject Group for <<Exam/Course Name>>",
  SELECT_LANGUAGE: "Select Language / Medium of the Question Paper",
  CONFIRM_LANGUAGE: "Confirm Language / Medium of the Question Paper",
  EXAM_CENTER_DISTRICT_PREFERENCE_1:
    "Select Exam Center District at preference Number 1",
  EXAM_CENTER_DISTRICT_PREFERENCE_2:
    "Select Exam Center District at preference Number 2",
  EXAM_CENTER_DISTRICT_PREFERENCE_3:
    "Select Exam Center District at preference Number 3",
  EXAM_CENTER_DISTRICT_PREFERENCE_4:
    "Select Exam Center District at preference Number 4",
  IS_MH_CANDIDATE: "Are you Domiciled in the State of Maharashtra ?",
  REQUIRE_EXTRA_TIME: "Require Extra Time ?",
  REQUIRE_SCRIBE: "Require Scribe ?",
  IS_SPORT: "Is Sports ?",
  SPORT_PARTICIPATION_LEVEL: "Sports Participation Level",
  SPORT_PARTICIPATION_YEAR: "Sports Participation Year",
  SPORT_RANK: "Sports Rank",
  SPORT_NAME: "Sports Name",
  SPORT_BODY: "Sports Body",
  IS_STUDIED_PHY_EDU: "Is Studied Physical Education ?",
  IS_TEACHING_EXP: "Is Teaching Experience ?",
  IS_APPLIED_ELCT: "Is Applied For ELCT ?",
  IS_APPLIED_KSU: "Is Applied For KSU ?",
  IS_APPLIED_SPECIAL_EDU: "Is Applied For Special Education ?",
  SPECIAL_EDU_METHODS: "Special Education Methods ?",
  ELIGIBLITY_QUALIFICATION: "Eligibility Qualification",
  GRADUATION_STATUS: "Graduation Status",
  GRADUATION_PASSING_YEAR: "Graduation Passing Year",
  GRADUATION_BRANCH_NAME: "Graduation Branch Name",
  GRADUATION_MARK_TYPE: "Graduation Marks Type",
  GRADUATION_MARK_OBTAINED: "Graduation Marks Obtained",
  GRADUATION_MARK_OUT_OF: "Graduation Marks Out Of",
  GRADUATION_MARK_CALCULATED_PERCENTAGE: "Graduation Percentage",
  AWARDED_CLASS_NAME: "Awarded Class Name",
  GRADUATION_DURATION: "Graduation Duration",
  DOCUMENT_FOR_TYPE_A: "Document For Type A",
  DOCUMENT_OF: "Document Of",
  CASTE_NAME_FOR_OPEN: "Caste Name For Open",
  CET_EXAM_NAME: "CET Exam Name",
  WHETHER_PASS_INSTUTE_ON_MH: "Whether Pass Institution Situated MH ?",
  PF_GRADUATION_DATE: "PF Graduation Date",
  INTERN_TRAINING_DATE: "Internship Training Date",
  MH_COUNCIL_REGISTRATION: "MH Council Registration",
  RE_COUNCIL_REGISTRATION: "Re Council Registration",
  ALREADY_ADMITED: "Already Admitted",
  G_CollegeName: "College Name",
  University_Name: "University Name",
  FY_MARKS_OBTAINED: "FY Marks Obtained",
  FY_MARKS_OUT_OF: "FY Marks Out Of",
  FY_CALCULATED_PERCENTAGE: "FY Percentage",
  FY_NO_OF_ATTEMPTS: "FY No Of Attempts",
  SY_MARKS_OBTAINED: "SY Marks Obtained",
  SY_MARKS_OUT_OF: "SY Marks Out Of",
  SY_CALCULATED_PERCENTAGE: "SY Percentage",
  SY_NO_OF_ATTEMPTS: "SY No Of Attempts",
  TY_MARKS_OBTAINED: "TY Marks Obtained",
  TY_MARKS_OUT_OF: "TY Marks Out Of",
  TY_CALCULATED_PERCENTAGE: "TY Percentage",
  TY_NO_OF_ATTEMPTS: "TY No Of Attempts",
  FOUR_Y_MARKS_OBTAINED: "FourY Marks Obtained",
  FOUR_Y_MARKS_OUT_OF: "FourY Marks Out Of",
  FOUR_Y_CALCULATED_PERCENTAGE: "FourY Percentage",
  FOUR_Y_NO_OF_ATTEMPTS: "FourY No Of Attempts",
  AGG_MARKS_OBTAINED: "Aggregate Marks Obtained",
  AGG_MARKS_OUT_OF: "Aggregate Marks Out Of",
  AGG_CALCULATED_PERCENTAGE: "Aggregate Percentage",
  CATEGORY: "Category",
};

// Function to check if a field should be disabled
export const isFieldDisabled = (masterFields, fieldLabel, graduationStatus,isPaidValue) => {
  const isReadOnly =
    masterFields?.find((field) => field?.masterfieldlabel === fieldLabel)
      ?.isreadonly === "1";
      const isPaid = isPaidValue === '1' ? true : false
      const isGraduationAppearing = graduationStatus === 1;
      return isReadOnly || isGraduationAppearing || isPaid;
};

import React, { useState, useEffect } from "react";
import Logo2 from "../assets/images/navbar/image 9.png";
import { CiLogout } from "react-icons/ci";
import { RiDashboardHorizontalFill } from "react-icons/ri";
import {
  PiExam,
  PiGraduationCap,
  PiHand,
  PiIdentificationCard,
  PiNote,
  PiNotePencilBold,
  PiQuestionBold,
  PiReceipt,
} from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../redux/auth/actions";
import candidatesActions from "../redux/candidates/actions";
import { Box, AppBar, Divider } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { cssProperties } from "../utils/commonCssProperties";
import { CgPlayTrackPrevR, CgProfile } from "react-icons/cg";
import reg from "../../src/assets/images/sidebar/registration.svg";
import scorecard from "../../src/assets/images/sidebar/score card.svg";
import objection from "../../src/assets/images/sidebar/objection-tracker.svg";
import reghover from "../../src/assets/images/sidebar/registration-active.svg";
import scorecardhover from "../../src/assets/images/sidebar/score card-active.svg";
import objectionhover from "../../src/assets/images/sidebar/objection-tracker-active.svg";
import { PiCreditCardLight } from "react-icons/pi";
import { MdHomeFilled } from "react-icons/md";
import { MdOutlineLogout } from "react-icons/md";


function Sidebar() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;
  // console.log(uploadDetails,"upd from sidebar")
  const handleLogout = () => {
    dispatch({ type: authActions.CANDIDATE_LOGOUT });
  };
  useEffect(() => {
    dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
  }, [])

  const { personalDetails,uploadDetails,addressDetails,otherDetails } = useSelector((state) => state.candidatesReducer);

  const allProfiledetailsDraft = personalDetails?.[0]?.candidatedraftid ==null 
  && addressDetails?.[0]?.candidateaddressdraftid ==null 
  && otherDetails?.[0]?.candidateotherdetailsdraftid  ==null 
  && uploadDetails?.[0]?.candidateuploaddraftid ==null  ;

// console.log('otherDetails---',otherDetails);
console.log('allProfiledetailsDraft---',allProfiledetailsDraft);

  const items = [
    {
      name: "Home",
      icon: <MdHomeFilled size={24} />,
      hoverIcon: <MdHomeFilled size={24} style={{ color: "#ffffff" }} />,

      path: uploadDetails.length === 0 ? null : `/${Cookies.get("mhet_cnd_project")}/home`,
      disabled: uploadDetails.length === 0,
    },
    {
      name: "Profile",
      icon: <CgProfile size={24} />,
      hoverIcon: <CgProfile size={24} style={{ color: "#ffffff" }} />,

      path: `/${Cookies.get(
        "mhet_cnd_project"
      )}/registration?page=personaldetails`,
      subPath: [
        `/${Cookies.get("mhet_cnd_project")}/registration?page=address`,
        `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`,
        `/${Cookies.get("mhet_cnd_project")}/registration?page=uploads`,
      ],
    },
    {
      name: "CET Registration",
      icon: <img src={reg} style={{ width: "24px", height: "24px" }} />,
      hoverIcon: <img src={reghover} style={{ width: "24px", height: "24px" }} />,
      path: (uploadDetails.length === 0 || !allProfiledetailsDraft   )? null : (`/${Cookies.get("mhet_cnd_project")}/course-selection`),
      disabled: (uploadDetails.length === 0   || !allProfiledetailsDraft)
      // path: `/${Cookies.get("mhet_cnd_project")}/course-selection`,

    },
    {
      name: "Payment History",
      icon: <PiCreditCardLight size={24} />,
      hoverIcon: <PiCreditCardLight size={24} style={{ color: "#ffffff" }} />,
      path:uploadDetails.length === 0 ? null : `/${Cookies.get("mhet_cnd_project")}/payment-receipts`,
      disabled: uploadDetails.length === 0
    },
    {
      name: "Hall ticket or admit card",
      icon: <PiIdentificationCard size={24} />,
      hoverIcon: <PiIdentificationCard size={24} style={{ color: "#ffffff" }} />,
      path: `/${Cookies.get("mhet_cnd_project")}/admit-card-list`,
      // disabled: true,

    },
    {
      name: "Score card download",
      icon: <img src={scorecard} style={{ width: "24px", height: "24px" }} />,
      hoverIcon: (
        <img src={scorecardhover} style={{ width: "24px", height: "24px" }} />
      ),
      disabled: true,
    },
    {
      name: "Objection tracker",
      icon: <img src={objection} style={{ width: "24px", height: "24px" }} />,
      hoverIcon: (
        <img src={objectionhover} style={{ width: "24px", height: "24px" }} />
      ),
      // path: `/${Cookies.get("mhet_cnd_project")}/answer-key?page=tablelist`,
      disabled: true,

    },
    {
      name: "Raise a query",
      icon: <PiQuestionBold size={24} />,
      hoverIcon: <PiQuestionBold size={24} style={{ color: "#ffffff" }} />,
      // path: `/${Cookies.get("mhet_cnd_project")}/query-list`,
      // subPath: [`/${Cookies.get("mhet_cnd_project")}/raise-query`],
      disabled: true,
    },
    {
      name: "College selection",
      icon: <PiGraduationCap size={24} />,
      hoverIcon: <PiGraduationCap size={24} style={{ color: "#ffffff" }} />,
      disabled: true,
    },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "85px",
        height: "100vh",
        left: 0,
        backgroundColor: "#FFFFFF",
        scrollbarWidth: "thin",
        justifyContent: "space-between",
        // padding: "24px 0px 24px 24px",
        boxShadow: "none",
        padding: "16px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/${Cookies.get("mhet_cnd_project")}/home`)}
      >
        <img style={{ width: "auto", height: "40px" }} src={Logo2} alt="logo" />
      </Box>
      <Divider style={{ width: "100%" }} />

      <Box
        sx={{
          height: "75vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            // margin: "10px 0px",
            paddingRight: "4px",
          }}
        >
          {items.map((item, index) => {
            const isActive =
              pathname === item.path ||
              pathname + search === item.path ||
              (Array.isArray(item.subPath) &&
                item.subPath.some((subPath) =>
                  (pathname + search).includes(subPath)
                ));

            const handleMouseEnter = () => setHoveredItem(item.name);
            const handleMouseLeave = () => setHoveredItem(null);

            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  cursor: item.disabled ? "not-allowed" : "pointer",
                  padding: "0px",
                  "& .item-icon": {
                    backgroundColor:
                      isActive || hoveredItem === item.name
                        ? `${cssProperties?.hoverbgcolor?.sidebar}`
                        : "inherit",
                    borderRadius: "52px",
                    color:
                      isActive || hoveredItem === item.name
                        ? "#FFFFFF"
                        : `${cssProperties?.color?.textsecondary}`,
                    color:
                      isActive || hoveredItem === item.name
                        ? "#FFFFFF"
                        : `${cssProperties?.color?.textsecondary}`,
                  },
                  "& .item-name": {
                    color:
                      isActive || hoveredItem === item.name
                        ? `${cssProperties?.color?.primary2}`
                        : `${cssProperties?.color?.textsecondary}`,
                  },
                }}
                onClick={() => (item.path ? navigate(item.path) : null)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Box
                  className={"item-icon"}
                  sx={{
                    color: "#666666",
                    width: "64px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "38px",
                  }}
                >
                  {hoveredItem === item.name || isActive
                    ? item.hoverIcon
                    : item.icon}
                </Box>

                <Box
                  className={"item-name"}

                  sx={{
                    color: "#666666",
                    fontSize: "12px",
                    textWrap: "wrap",
                    textAlign: "center",
                    font: "Graphik",
                    cursor: item.disabled ? "not-allowed" : "pointer",
                    marginTop: "6px",
                  }}
                >
                  {item.name}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Divider style={{ width: "100%" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#3F41D1",
          paddingRight: "12px",
        }}
      >
        <Box
          className={"item-icon"}
          sx={{ cursor: "pointer" }}
          onClick={() => handleLogout()}
        >
          <MdOutlineLogout
            style={{
              "&:hover": {
                backgroundColor: "#E1E8F5",
                borderRadius: "10%",
                "& .item-icon, & .item-name": {
                  color: "#0F4F96",
                },
              },
            }}
            size={24}
            color="#666666"
          />
        </Box>
        <Box
          className={"item-name"}
          sx={{
            color: "#0F4F96",
            fontSize: "12px",
            textWrap: "wrap",
            textAlign: "center",
            font: "Graphik",
            cursor: "pointer",
          }}
        >
          {"Logout"}
        </Box>
      </Box>
    </AppBar>
  );
}

export default Sidebar;

import React from "react";
import DataTable from "../../common/dataTable";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import BackButton from "../../common/backButton";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import TablePagination from "../../common/tablePagination";

export default function QuestionListTable() {
  const navigate = useNavigate();

  const questionKeysHeaders = [
    { name: "Question NO", accessor: "questionNo" },
    { name: "Question Details", accessor: "questionDetails" },
    { name: "Answer Key", accessor: "answerKey" },
  ];

  const questionKeysData = [
    {
      questionNo: 1,
      questionDetails:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "A",
    },
    {
      questionNo: 2,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "B",
    },
    {
      questionNo: 3,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "A",
    },
    {
      questionNo: 4,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "D",
    },
  ];

  const handleRaiseObjection = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/answer-key?page=objectiontracker`);
  }

  return (
    <Grid>
      <Box sx={{display:"flex",justifyContent:"flex-end"}}>
        <MauvelousPrimaryButton title={"Raise Objection"} handleClick={handleRaiseObjection} />
      </Box>
      <Grid sx={{display:"flex",justifyContent:"space-between"}} pt={2}>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Typography>Course : <b>B.ED - CET2024</b></Typography>
          <Typography>Exam Date : <b>05.02.2024</b> </Typography>
        </Box>
        <Box>
          <FormControl sx={{width: "9rem"}} size="small">
            <InputLabel htmlFor="questionno">
            Question No
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }} size="small" id="questionno" label="Question No">
              <MenuItem value={1}>01</MenuItem>
              <MenuItem value={2}>02</MenuItem>
              <MenuItem value={3}>03</MenuItem>
              <MenuItem value={4}>04</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Typography>View answer keys for the question that you wrote</Typography>
      <DataTable keys={questionKeysHeaders} values={questionKeysData} />
      <TablePagination />
      <Box mt={2}>
        <BackButton />
      </Box>
    </Grid>
  );
}

import CryptoJS from "crypto-js";
const secretPass = "XkhZG4fW2t2W";

export const EncryptFunction = (text) => {
    const encrypted = CryptoJS.AES.encrypt(text?.toString(), secretPass).toString();
    return encodeURIComponent(encrypted);
};

export const DecryptFunction = (text) => {
    let decrypted;
    try {
        decrypted = CryptoJS.AES.decrypt(decodeURIComponent(text), secretPass);
    } catch(e) {
        console.error(e); // logs URIError: URI malformed
    }
    return decrypted?.toString(CryptoJS.enc.Utf8);
};

export const EncryptObjectFunction = (object) => {
    const text = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(text?.toString(), secretPass).toString();
    return encodeURIComponent(encrypted);
};

export const DecryptObjectFunction = (text) => {
    let decrypted;
    try {
        decrypted = CryptoJS.AES.decrypt(decodeURIComponent(text), secretPass);
        const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
        if (decryptedText) {
            return JSON.parse(decryptedText);
        } else {
            console.warn("Decryption returned empty or invalid text.");
            return null; 
        }
    } catch (e) {
        console.error("Error during decryption or JSON parsing:", e);
        return null; 
    }
};


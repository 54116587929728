import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import videoUrl from '../../assets/videos/tutorialVideo.mp4';
import { PiX } from "react-icons/pi";

const style = {
    width: 900,
    height: 560,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px',
    position: 'relative'
};

export default function HelpManualVideoModal(props) {
    const { handleClose, showModal } = props;

    return (
        <Modal
            open={showModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflowY: 'auto'
            }}
        >
            <Box sx={style}>
                <Box display="flex" alignItems="center" marginBottom="24px">
                    <Box flexGrow={1}>
                        <Typography variant="h5" sx={{ color: `#212121`, fontWeight: '500', fontSize: '20px' }}>
                            Tutorial Video
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <PiX />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ height: '400px' }}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    ></iframe>
                </Box>
            </Box>
        </Modal>
    );
}

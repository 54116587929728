import React from "react";
import { Box, Divider, Grid,useMediaQuery, useTheme } from "@mui/material";
import govLogo1 from "../assets/images/navbar/govLogo1.png";
import projectLogo from "../assets/images/navbar/cet.png";
import govLogo2 from "../assets/images/navbar/govLogo2.png";

export default function BeforeLoginHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        sx={{
          background: "transparent",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 50px",
        }}
      >
        <Grid
          item
          lg={9}
          sx={{ display: "flex", alignItems: "center", gap: "16px" }}
        >
          <Box>
            <img src={govLogo1} alt="logo" style={{height:isMobile? "40px" : "60px", width: "auto" }} />
          </Box>
          <Divider orientation="vertical" />
          <Box>
            <img src={projectLogo} alt="logo" style={{height:isMobile? "40px" : "auto", width: "auto" }} />
          </Box>
        </Grid>
        <Grid item lg={3} sx={{ display: "flex", justifyContent: "flex-end",alignItems:"center" }}>
          <Box>
            <img
              src={govLogo2}
              alt="logo"
              style={{ height:isMobile? "40px" : "88px", width: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import Index from "../components/paymentConfirmation";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";
import { DecryptObjectFunction, EncryptObjectFunction } from "../utils/cryptoFunction";

export default function PaymentConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;

  const [searchParams] = useSearchParams();
  const encryptedData = searchParams.get("c_data");
  const decrypted = DecryptObjectFunction(encryptedData);
  const courseName = decrypted?.coursename;
  const edit = decrypted?.edit;
  const courseIdVal = decrypted?.courseid;
  let encryptedParams = {
    courseid: courseIdVal,
    coursename:courseName,
    edit:edit
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      style={{ textDecoration: "none" }}
      component={NavLink}
      to={`/${Cookies.get("mhet_cnd_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </Link>,

    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={ edit ? `/${Cookies.get("mhet_cnd_project")}/editing-facility?c_data=${EncryptObjectFunction(encryptedParams)}` : `/${Cookies.get("mhet_cnd_project")}/course-selection`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      { edit ? 'Edit Details' : 'Course selection'}
    </Link>,
    
    !edit &&
    <Link
      underline="hover"
      key="1"
      style={{ textDecoration: "none" }}
      onClick={() =>
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=personalinformation`,
          { state: { courseid: courseid || courseIdVal, coursename: coursename || courseName } }
        )
      }
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        color: cssProperties?.fontcolor?.primary,
        cursor: "pointer",
      }}
    >
      {coursename || courseName}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Payment confirmation
      <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];

  return (
    <>
      <Layout>
        <Header breadcrumbs={breadcrumbs} />
        <Sidebar />
        <Index />
      </Layout>
    </>
  );
}

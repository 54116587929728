import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box, CardContent, Grid, Link, Paper, Typography } from "@mui/material";
import authActions from "../../redux/auth/actions";
import { HiArrowLongRight } from "react-icons/hi2";
import reg from "../../../src/assets/images/sidebar/registration.svg";
import scorecard from "../../../src/assets/images/sidebar/score card.svg";
import objection from "../../../src/assets/images/sidebar/objection-tracker.svg";
import checklist from "../../../src/assets/images/sidebar/checklist.png";
import survey from "../../../src/assets/images/sidebar/survey.png";
import user from "../../../src/assets/images/sidebar/user.png";
import bell from "../../../src/assets/images/sidebar/bell-school.png";
import credit from "../../../src/assets/images/sidebar/creditcard.png";
import doubts from "../../../src/assets/images/sidebar/doubts-button.png";
import idBadge from "../../../src/assets/images/sidebar/id-badge.png";
import idCard from "../../../src/assets/images/sidebar/id-card.png";



export default function Index() {
  const dispatch = useDispatch();
  const { candidateTokenDetails, profileRegistrationActiveTab } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    if (candidateTokenDetails?.candidateid) {
      dispatch({
        type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
        payload: { id: candidateTokenDetails?.candidateid },
      });
    }
  }, [candidateTokenDetails]);

  useEffect(() => {
    if (!profileRegistrationActiveTab) {
      return;
    } else if (
      profileRegistrationActiveTab &&
      profileRegistrationActiveTab === "new"
    ) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
      );
    } else {
      if (
        !profileRegistrationActiveTab?.cu1_status &&
        profileRegistrationActiveTab?.other_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=uploads`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        profileRegistrationActiveTab?.address_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        profileRegistrationActiveTab?.candidate_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=address`
        );
      } else if (
        !profileRegistrationActiveTab?.cu1_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        !profileRegistrationActiveTab?.candidate_status
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=personaldetails`
        );
      }
    }
  }, [profileRegistrationActiveTab]);

  const items = [
    {
      icon: (
        <img
          src={user}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Profile",
      details:
        "Provide basic details, latest photo, signature, and identity document.",
      path: `/${Cookies.get(
        "mhet_cnd_project"
      )}/registration?page=personaldetails`,
    },

    {
      icon: (
        <img
          src={reg}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CET Registration",
      details:
        "Register for course(s) of your choice and manage your applications.",
      path: `/${Cookies.get("mhet_cnd_project")}/course-selection`,
    },

    {
      icon: (
        <img
          src={credit}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Payment History",
      details: "View your fees payments and transaction history.",
      path: `/${Cookies.get("mhet_cnd_project")}/payment-receipts`,
    },

    {
      icon: (
        <img
          src={idCard}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Hall Ticket Or Admit Card",
      disabled: true,
      details: "Download your admit card for upcoming examinations.",
      path: `/${Cookies.get("mhet_cnd_project")}/admit-card-list`,
    },
    {
      icon: (
        <img
          src={scorecard}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Score Card",
      details: "Access and download your examination score cards.",
      disabled: true,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
    },

    {
      icon: (
        <img
          src={objection}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      disabled: true,
      name: "Objection Tracker",
      details:
        "Raise and track objections or discrepancies in your exam results.",
      path: `/${Cookies.get("mhet_cnd_project")}/objection-tracker`,
    },
    {
      icon: (
        <img
          src={survey}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CAP Registration",
      details: "Register for the Centralized Admission Process (CAP) here.",
      disabled: true,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
    },

    {
      icon: (
        <img
          src={doubts}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      disabled: true,
      name: "Help Desk",
      details: "Contact support for assistance with any queries or issues",
      path: `/${Cookies.get("mhet_cnd_project")}/raise-query`,
    },
    {
      icon: (
        <img
          src={bell}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CET Notification",
      details:
        "Stay updated with the latest notifications related to CET exams.",
      disabled: false,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
      link: "https://cetcell.mahacet.org/notices"
    },
    {
      icon: (
        <img
          src={bell}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "CAP Notification",
      details:
        "Stay updated with the latest notifications  related to CAP activities.",
      disabled: true,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
    },
    {
      icon: (
        <img
          src={idBadge}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "ARA",
      details: "Access ARA Portal.",
      disabled: true,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
    },
    {
      icon: (
        <img
          src={checklist}
          style={{
            width: "58px",
            height: "58px",
            filter:
              "brightness(0) saturate(100%) invert(21%) sepia(35%) saturate(3938%) hue-rotate(199deg) brightness(93%) contrast(93%)",
          }}
        />
      ),
      name: "Response sheet",
      details: "View your response sheet here",
      disabled: true,
      path: `/${Cookies.get("mhet_cnd_project")}/`,
    },
  ];

  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} lg={4} key={index}>
          <Paper
            elevation={1}
            sx={{
              padding: 2,
              height: 120,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: item.disabled ? "not-allowed" : "pointer",
              opacity: item.disabled ? 0.6 : 1,
              backgroundColor: item.disabled ? "#f0f0f0" : "#fff",
              pointerEvents: item.disabled ? "none" : "auto",
            }}
            onClick={() => {
              if (!item.disabled && item.path) {
                navigate(item.path);
              }
              if (item?.link) {

                window.open("https://cetcell.mahacet.org/notices", "_blank")
              }
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Box
                  sx={{
                    color: item.disabled ? "#9e9e9e" : "#0F4F96",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 62,
                    height: 65,
                    opacity: item.disabled ? 0.5 : 1,
                  }}
                >
                  {item.icon}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <CardContent sx={{ padding: "16px " }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontSize: 16, fontWeight: 500, fontFamily: "inter" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#5683B5",
                      paddingTop: "7px",
                    }}
                  >
                    {item.details}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={2}>
                <HiArrowLongRight
                  size={20}
                  style={{
                    color: "#5683B5",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

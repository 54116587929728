import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  createTheme,
  Button
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import authActions from "../../redux/auth/actions";
import {
  InputTrimAndLowercaseFunction,
  InputTrimFunction,
  handleEmailValidation,
  passwordValidation,
} from "../../utils/validations";
import ButtonLoader from "../../common/buttonLoader";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { cssProperties } from "../../utils/commonCssProperties";
import PageHeading from "../../layouts/pageHeading";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import BackgroundImage from "../../assets/images/backgroundImage.png";
import BeforeLoginInformation from "../../common/beforeLoginInformation";
import SecondaryButton from "../../common/button/secondaryButton";
import PrimaryButton from "../../common/button/primaryButton";
import ForgetPassword from "./forgetpassword";
import ForgetEmail from "./fogetemail";
import HelpManualVideoModal from "../modals/helpManualVideoModal";
import pdf from "../../assets/document/userManual.pdf"

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({ show: false, type: null });
  const [isTouched, setIsTouched] = useState(false);

  const handleFocus = () => {
    setIsTouched(true);
  };

  const handleChange = (event) => {
    InputTrimAndLowercaseFunction(event);
    setIsTouched(true);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        laptop: 1200,
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));


  const { buttonLoader } = useSelector((state) => state.commonReducer);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [passState, setPassState] = useState(false);
  const [show, setShow] = useState(true)
  const onSubmit = (data) => {
    dispatch({
      type: authActions.CANDIDATE_LOGIN,
      payload: { navigate: navigate, data: data },
    });
  };

  const handleTutorialVideo = () => {
    setShowModal({ show: true, type: "video" })
  };

  const handleSignup = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/signup`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <Grid container spacing={3} sx={{ padding: "20px 50px" }}>
        <Grid item lg={8} md={8} xs={12}>
          <Grid
            sx={{
              background: `${cssProperties?.backgroundcolor?.primary2}`,
              border: `1px solid ${cssProperties?.bordercolor?.tertiary}`,
              borderRadius: "21px 21px 10px 10px",
              minHeight: "568px",
              display: "flex",
              alignItems: "center",
              boxShadow: "2px 3px 12px 0px #0F4F9666",
            }}
          >
            {show ?
              <Grid
                component="form"

                container
                spacing={2}
                p={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={5} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: `${cssProperties?.color?.primary}`,
                    }}
                  >
                    Sign In (Existing user only)
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: `${cssProperties?.color?.primary}`,
                    }}
                  >
                    Registered Email ID
                  </Typography>
                  {/* <TextField
                    inputProps={
                      {
                        // style: { height: "43px", boxSizing: "border-box" },
                      }
                    }
                    size="small"
                    fullWidth
                    id="emailid"
                    //   label={
                    //     <span>
                    //       Email id
                    //       <span className="error" style={{ color: "red" }}>
                    //         {" "}
                    //         *
                    //       </span>
                    //     </span>
                    //   }
                    type="text"
                    variant="outlined"
                    error={!!errors.emailid}
                    {...register("emailid", {
                      required: "Email id is required",
                      validate: handleEmailValidation,
                    })}
                    onInput={InputTrimAndLowercaseFunction}
                    helperText={errors.emailid?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                  /> */}
                  <TextField
                    inputProps={{
                      // style: { height: "43px", boxSizing: "border-box" },
                    }}
                    size="small"
                    fullWidth
                    id="emailid"
                    type="text"
                    variant="outlined"
                    error={isTouched && !!errors.emailid}
                    {...register("emailid", {
                      required: "Email id is required",
                      validate: handleEmailValidation,
                    })}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    helperText={isTouched && errors.emailid?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                  />
                  <Typography
                    sx={{
                      marginBottom: 1,
                      marginTop: 2,
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: `${cssProperties?.color?.primary}`,
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    id="temppassword"
                    type={passState ? "text" : "password"}
                    //   label={
                    //     <span>
                    //       Password
                    //       <span className="error" style={{ color: "red" }}>
                    //         {" "}
                    //         *
                    //       </span>
                    //     </span>
                    //   }
                    InputProps={{
                      // style: { height: "43px", boxSizing: "border-box" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setPassState(!passState)}
                            sx={{ paddingRight: "0" }}
                          >
                            {passState ? (
                              <FiEyeOff size={18} />
                            ) : (
                              <FiEye size={18} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.temppassword}
                    {...register("temppassword", {
                      required: "Password is required",
                      // minLength: {
                      //   value: 8,
                      //   message: "Password must have at least 8 characters",
                      // },
                      // validate: passwordValidation,
                    })}
                    onInput={InputTrimFunction}
                    helperText={errors.temppassword?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                  />

                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    sx={{ marginTop: "12px" }}
                  >
                    <Link
                      // to={`/${Cookies.get("mhet_cnd_project")}/forgot-password`}
                      style={{
                        textDecoration: "none",
                        color: `${cssProperties?.color?.primary}`,
                        fontWeight: 500,
                        fontSize: "14px",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setShow(false);
                        window.scrollTo({ top: 0, behavior: 'smooth' });

                      }}
                    >
                      Forgot your password? Click here to reset{" "}
                    </Link>
                  </Box>
                  <Box mt={2}>
                    <PrimaryButton
                      text={"Sign In"}
                      handleClick={handleSubmit(onSubmit)}
                    // type="submit"
                    // handleClick={handleSubmit(onSubmit)}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={2}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: isMobile ? "row" : "column",
                  }}
                >
                  <Divider
                    orientation={isMobile ? "horizontal" : "vertical"}
                    sx={{
                      height: isMobile ? "10vh" : "50vh",
                      width: isMobile ? "50vw" : "0",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 40,
                        height: 40,
                        background: `${cssProperties?.backgroundcolor?.primary2}`,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: `${cssProperties?.color?.primary}`,
                        border: `1px solid ${cssProperties?.bordercolor?.tertiary}`,
                        fontSize: "14px",
                        boxShadow: "2px 3px 12px 0px #0F4F9666",
                        fontWeight: "bold",
                      }}
                    >
                      OR
                    </Box>
                  </Divider>
                </Grid>

                <Grid item lg={5} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: `${cssProperties?.color?.tertiary}`,
                      textAlign: "center",
                    }}
                  >
                    New User?
                  </Typography>
                  <SecondaryButton
                    text={"Register now"}
                    handleClick={() => handleSignup()}
                  />
                </Grid>

                <Grid item lg={12} xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "25px" }}>

                  <Typography sx={{

                    fontWeight: "bold",
                    fontSize: "14px",
                    color: `${cssProperties?.color?.tertiary}`,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center"
                  }}>
                    Registration -
                  </Typography>

                  <Typography ><span style={{ fontSize: "14px", color: `${cssProperties?.color?.primary}`, fontWeight: "bold" }}>
                    <a style={{ cursor: "pointer", textDecoration: "none", color: `${cssProperties?.color?.primary}` }} href={pdf} without rel="noopener noreferrer" target="_blank">User Manual  </a>
                    |  <span style={{ cursor: "pointer" }} onClick={handleTutorialVideo}>Tutorial Video</span>
                  </span></Typography>

                </Grid>
              </Grid> :
              <Grid container>
                <Grid item lg={12}>
                  <ForgetPassword show={show} setShow={setShow} />

                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <BeforeLoginInformation />
        </Grid>
      </Grid>
      {/* <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: '24px'
                }}
            >
                <Grid container sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
                    <Grid item md={6} sm={11}

                    > <Box
                        sx={{
                            borderRadius: "8px",
                            // padding: '16px',
                            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                            height: '100%',
                            overflow: 'hidden'
                        }}
                    >
                            <img style={{ width: '100%', height: 'inherit', objectFit: 'cover' }} src={BackgroundImage} alt="background" />
                        </Box></Grid>
                    <Grid item md={6} sm={11}>
                        <Box
                            sx={{
                                borderRadius: "8px",
                                padding: '24px',
                                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,

                                height: '100%'
                            }}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ marginBottom: '24px' }}>
                                    <PageHeading page={'Candidate Login'} />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} 
                                    // md={6} lg={6}
                                    >
                                        <TextField
                                         inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                                            
                                            fullWidth
                                            id="emailid"
                                            label={
                                                <span>
                                                    Email id
                                                    <span className="error" style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                </span>
                                            }
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.emailid}
                                            {...register("emailid", {
                                                required: "Email id is required",
                                                validate: handleEmailValidation,
                                            })}
                                            onInput={InputTrimAndLowercaseFunction}
                                            helperText={errors.emailid?.message}
                                            FormHelperTextProps={{
                                                style: { margin: 0 },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}
                                    //  lg={6} md={6}
                                     >
                                        <TextField
                                            fullWidth
                                            
                                            id="temppassword"
                                            type={passState ? "text" : "password"}
                                            label={
                                                <span>
                                                    Password
                                                    <span className="error" style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                </span>
                                            }
                                            InputProps={{
                                                style:{height:'56px',boxSizing:'border-box'},
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setPassState(!passState)} sx={{ paddingRight: '0' }}>
                                                            {passState ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={!!errors.temppassword}
                                            {...register("temppassword", {
                                                required: 'Password is required',
                                                minLength: {
                                                    value: 8,
                                                    message: "Password must have at least 8 characters",
                                                },
                                                validate: passwordValidation
                                            })}
                                            onInput={InputTrimFunction}
                                            helperText={errors.temppassword?.message}
                                            FormHelperTextProps={{
                                                style: { margin: 0 },
                                            }}
                                        />

                                        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '12px' }}>
                                            <Link
                                                to={`/${Cookies.get("mhet_cnd_project")}/forgot-password`}
                                                style={{
                                                    textDecoration: "none",
                                                    color: `${cssProperties?.bordercolor?.primary2blue}`,
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    textTransform:'uppercase',
                                                }}
                                            >
                                                Forgot password
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
                                    <BluePrimaryButton
                                        type="submit"
                                        title={buttonLoader ? <ButtonLoader /> : "Login"}
                                    />
                                </Box>
                            </form>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <Typography variant="body2" color={cssProperties?.fontcolor?.primary}>Don't have an account?</Typography>
                                <Link
                                    to={`/${Cookies.get("mhet_cnd_project")}/signup`}
                                    style={{
                                        marginLeft: 4,
                                        fontWeight: 500,
                                        color: `${cssProperties?.bordercolor?.primary2blue}`,
                                        fontSize: '14px',
                                        textDecoration: "none",
                                        textTransform:'uppercase',
                                    }}
                                >
                                    Sign Up
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box> */}
      <HelpManualVideoModal
        showModal={showModal?.type === "video" && showModal?.show}
        handleClose={() => setShowModal({ show: false, type: null })} />
    </>
  );
}

import "./App.css";
import { BrowserRouter } from "react-router-dom";
import ToastMessage from "./common/toastMessage";
import AppRoutes from "./routes/appRoutes";
import PageLoader from "./common/pageLoader";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

}

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <ToastMessage />
        <PageLoader />
        <AppRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;

import { Box, Grid, Link, Table, TableCell, TableContainer, TableHead, TableRow ,Typography} from '@mui/material'
import React from 'react'
import barCode from '../../assets/images/barcode.png'
import cetIcon from '../../assets/images/courseRegistration/Cet.png'
import emblem from '../../assets/images/courseRegistration/Government.png'
import PrintDownloadButton from '../../common/button/printDownloadButton'
import { RiDownloadLine } from 'react-icons/ri'
import { SlPrinter } from "react-icons/sl";
import { useReactToPrint } from 'react-to-print'
import { Margin, usePDF } from 'react-to-pdf'

export default function Index() {
  const { toPDF, targetRef } = usePDF({ filename: 'Admit Card.pdf',page:{format:'A3',margin:Margin.SMALL} });

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  })
  return (
    <Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box > {PrintDownloadButton({ name: "Download Admit Card", startIcon: <RiDownloadLine />, handleSubmit: toPDF })}</Box>
          <Box > {PrintDownloadButton({
            name: "Print Admit Card", startIcon: <SlPrinter />,handleSubmit:handlePrint
          })}</Box>
        </Box>
      </Box>
      <Grid container >
        <Grid item xs={12} sm={12} md={12} lg={12} px={2} py={2} >
          <Grid container >
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"left"}> <span style={{ fontWeight: "600" }}>Venue: </span>{"venue"}</Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"center"}><span style={{ fontWeight: "600" }}>Date</span>:{"date"}</Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} textAlign={"right"}><span style={{ fontWeight: "600" }}>Time</span>:{"time"}</Grid>
          </Grid>
        </Grid>
        <div ref={targetRef}>
          <Grid className='admit_card' item xs={12} sm={12} md={12} lg={12} p={2} sx={{ backgroundColor: "#FFFFFF", border: "1px solid rgba(213, 213, 213, 1)", borderRadius: "8px", minHeight: "50vh", widht: "100vw", wordBreak: "break-word", position: "relative" }}>
          <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={3} sm={3} md={3} lg={3} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Box>
                    <img style={{width:"100%",marginTop:'70px',marginLeft:'40px'}} src={cetIcon} /> 
                    </Box>
                      
                    </Grid>
                  
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontWeight: 600 ,"@media print": {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center" ,
      marginLeft:'37px'
    }}}>
                  <Box>
                    <img style={{width:"100%",height:'100px'}} src={emblem} /> 
                    </Box>
                    <Box item>Government of Maharashtra</Box>
                    <Box mt={2}>State Common Entrance Test Cell, Mumbai</Box>
                    <Box mt={2}>MAH - LL.B 3yrs CET - 2024 Online Examination </Box>
                    <Box mt={2}>ADMIT CARD</Box>
                  </Grid>
                
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={4} border={"1px solid #BDBDBD"}>
                <Grid container>
                  <Grid item xs={10} sm={10} md={10} lg={10} >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                      <Grid item px={1} py={1} xs={12} sm={12} md={12} lg={12}  border={"1px solid #BDBDBD"}></Grid>

                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Candidate's Full Name</Grid>
                          <Grid item px={1} py={1} xs={9} sm={9} md={9} lg={9} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> MILIND PATEL</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Roll Number/UserName</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 2487462643</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>DOB/Password</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 03/06/2001</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container sx={{width:'100%',height:'110px',}}>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"} sx={{justifyContent:'start',alignItems:'center',display:'flex'}}>Date of CET Examination</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600,justifyContent:'start',alignItems:'center',display:'flex' }} border={"1px solid #BDBDBD"}> 03/06/2024</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"} sx={{justifyContent:'start',alignItems:'center',display:'flex'}}>Examination Time</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 ,justifyContent:'start',alignItems:'center',display:'flex'}} border={"1px solid #BDBDBD"}> 02:00 PM to 4:00 PM</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Reporting Time at the CET Examination Center</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 12.30 PM</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Gate Closure Time</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 01.30 PM</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Question Paper Language</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> English/Marathi</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Name of the course</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> MAH - LL.B 3yrs</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Gender</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> Male</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Application Number</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> 248600001</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Person with Disability(PWD)"}</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> No</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Disability Type</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Extra Time Required"}</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>Scribe</Grid>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}> </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container>
                          <Grid item px={1} py={1} xs={3} sm={3} md={3} lg={3} border={"1px solid #BDBDBD"}>{"Venue Code"}</Grid>
                          <Grid item px={1} py={1} xs={9} sm={9} md={9} lg={9} sx={{ fontWeight: 600 }} border={"1px solid #BDBDBD"}>NAGP02</Grid>
                        </Grid>
                      </Grid>
                      <Grid item px={1} py={1} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} bgcolor={'#BDBDBD'} border={"1px solid #000000"}>
                        <Box>CET Exam Center Address</Box>
                      </Grid>
                      <Grid item px={1} pt={1} pb={7} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} border={"1px solid #BDBDBD"}>
                        <Box py={1}>MODERN COLLEGE</Box>
                        <Box py={1}>MIDC,T-POINT WAADI,AMRAWATI ROAD,OPP RAHUL HOTEL,WAADI,OPP RAHUL HOTEL,NAGPUR 440023</Box>
                      </Grid>
                      <Grid item px={1} py={1} xs={12} sm={12} md={12} lg={12} fontWeight={"600"} border={"1px solid #BDBDBD"} >
                        <Box py={1}>Please bring this hall ticket with  currently valid photo identity proof in original- THIS IS ESSENTIAL. Currently valid
                          Original photo identity proof may be PAN Card/Permanent Driving Licencse/Voter’s Card/Bank Passbook  with
                          photograph/photo Identity proof issued by a Gazetted Officer on official letterhead/Photo Identity proof issued by a
                          People’s Representative on official letterhead/Recent Identity Card issued by a recognized College/University/Aadhaar
                          Card and Learner’s Driving License and soft copy of teh Photo ID card will NOT be accepted as valid ID proof for this CET.</Box>
                      </Grid>

                    </Grid>

                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} display={"flex"} flexDirection={"column"} gap={3} justifyContent={"center"} alignItems={"center"} py={4}  >
                      <Grid item xs={12} sm={12} md={12} lg={12}>

                        <Box sx={{  width: { xs:"90px",sm:"100px",md: "135px" }, height: "150px" }} border={"1px solid #BDBDBD"}>
                        </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{  width: { xs:"90px",sm:"100px", md: "135px" }, height: "43px" }} border={"1px solid #BDBDBD"}>
                        </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ width: { xs:"100px",sm:"100px", md: "135px" },  height: { xs: "240px", md: "150px" }, display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }} px={1} border={"5px double #BDBDBD"}>
                        <Box textAlign="center">
      PLEASE AFFIX YOUR<br />
      RECENT COLOR<br />
      PHOTO & SIGN<br />
      ACROSS IT
    </Box>
                        </Box>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>


                  <Grid container>

                    <Grid item xs={12} sm={12} md={12} lg={12} border={"1px solid #BDBDBD"}>
                      <Grid container>

                      <Grid item pt={2} sx={{ width: "80%", height: "161px", border: "1px solid #BDBDBD", display: "flex", flexDirection: "column", alignItems: "center" }} xs={6} sm={6} md={6} lg={6} >

  <Box sx={{ height: "90px", width: "80%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center" }}>
 
    <Box sx={{ pt: 1, fontWeight: 600 }}>
   Candidate’s Signature
    </Box>
   </Box>
   <Box sx={{ pt: 2, fontWeight: 600 }}>
   To be signed in front of Invigilator
    </Box>
    </Grid>


                        <Grid item pt={2} sx={{ width: "80%", height: "161px", border: "1px solid #BDBDBD", display: "flex", flexDirection: "column", alignItems: "center" }} xs={6} sm={6} md={6} lg={6} >

                          <Box sx={{ height: "90px", width: "80%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center" }}>

                            <Box sx={{ pt: 1, fontWeight: 600 }}>
                              Invigilator’s Signature
                            </Box>
                          </Box>
                          <Box sx={{ pt: 2, fontWeight: 600 ,textAlign:'center'}}>
                            Invigilator  should verify Candidate and then sign here
                          </Box>
                        </Grid>


                      </Grid>


                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} border={"1px solid #BDBDBD"}>

                      <Box sx={{ height: "161px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                     <Box>
                        <img style={{width:"100%"}} src={barCode} />
                      </Box> 
                        <Box sx={{ fontWeight: "600", mt: "5px" }}>24150703483</Box>
                      </Box>
                    </Grid>
                    <Grid item sx={{ height: "161px", border: "1px solid #BDBDBD", display: "flex", flexDirection: "column", alignItems: "center" }} xs={12} sm={12} md={12} lg={12} >

                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600" }}>

                        <Link>Click here to download scribe declaration fornm for PWD Candidates only</Link>
                      </Box>
                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600" }}>

                        <Link sx={{textAlign:'center'}}>Click here to download the undertaking for correction in Name/Photo/Signature/DOB/Gender</Link>
                      </Box>
                      <Box sx={{ height: "80px", width: "100%", border: "1px solid #BDBDBD", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600", color: "#212121" }}>

                        Please turn to next page for instruction
                      </Box>

                    </Grid>

                  </Grid>
                </Grid>


              </Grid>
            </Grid>
          </Grid>

          
          <Grid className='admit_card_2' item xs={12} sm={12} md={12} lg={12} p={2} mt={4} sx={{ backgroundColor: "#FFFFFF", border: "1px solid rgba(213, 213, 213, 1)", borderRadius: "8px", minHeight: "50vh", widht: "100vw", wordBreak: "break-word" }}>
            <Grid container>

              <Grid item xs={12} sm={12} md={12} lg={12} py={2} fontWeight={600} display={"flex"} justifyContent={"center"} alignItems={"center"} border={"1px solid #BDBDBD"}>

                INSTRUCTIONS
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} py={2} fontWeight={600} display={"flex"} justifyContent={"center"} alignItems={"center"} border={"1px solid #BDBDBD"}>
                <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ px: 2 }}>
  <Typography variant="body1" paragraph>
    1. Candidate should reach the CET Examination Center before the Gate Closure Time mentioned on the Admit Card. Candidate will not be allowed to enter the CET examination Center after the Gate Closure Time.
  </Typography>
  <Typography variant="body1" paragraph>
    2. You will be required to enter the User name (Roll Number) & Password (DOB in Format DDMMYYYY), as mentioned in the Admit Card, to enter the Online exam site. Please ensure that your Name and other details which will subsequently appear on the screen are correct. You must maintain the secrecy of the username & password. The examination will be conducted online.
  </Typography>
  <Typography variant="body1" paragraph sx={{fontWeight:'bold'}}>
    3. You are required to bring this Hall Ticket with currently valid photo identity proof in original. Please note that your name as appearing on the Hall Ticket (provided by you during the process of registration) should reasonably match with the name as appearing on the photo identity proof. Female candidates who have changed first/last/middle name post marriage must take special note of this. If there is any mismatch between the name indicated in the Hall Ticket and Photo Identity Proof, you will not be allowed to appear for the exam. In case of candidates who have changed their name will be allowed only if they produce Gazette notification/their marriage certificate/affidavit.
  </Typography>
  <Typography variant="body1" paragraph>
    4. Candidates opted for Scribe shall bring the duly filled prescribed pro-forma & disability certificate. Candidates opted for extra time (including Learning Disability) shall produce disability certificate issued by the appropriate authority in original for verification to the invigilator.
  </Typography>
  <Typography variant="body1" paragraph>
    5. Brochure giving information/instructions about the nature of examination is available on the website of State CET CELL. Please download the Brochure and study it carefully.
  </Typography>
  <Typography variant="body1" paragraph>
    6. You must bring a ball point pen with you. You will have to sign the attendance sheet in the presence of the Invigilator.
  </Typography>
  <Typography variant="body1" paragraph>
    7. The mere fact that the Hall Ticket has been issued to you does not imply that your candidature has been finally cleared by State CET Cell or that the entries made by you in your application for examination have been accepted by State CET Cell as true and correct. It may be noted that State CET Cell will take up verification of eligible candidates, i.e., educational qualifications and category with reference to original documents, only at the time of document verification of candidates who qualify on the basis of result of the online examination. You may therefore please note that if at any stage you do not satisfy the eligibility criteria as given in the notification or the information furnished by you in the application is incorrect, your candidature is liable to be canceled forth-with with such further actions as State CET Cell may like to take.
  </Typography>
  <Typography variant="body1" paragraph sx={{fontWeight:'bold'}}>
    8. Your responses (answers) will be analyzed with other candidates to detect patterns of similarity of right and wrong answers. If in the analytical procedure adopted in this regard, it is inferred/concluded that the responses have been shared and scores obtained are not genuine/valid, your candidature may be canceled and/or the result withheld.
  </Typography>
  <Typography variant="body1" paragraph sx={{fontWeight:'bold'}}>
    9. You must report at the examination venue 30 minutes before the time as printed on this Hall Ticket. Candidates arriving late will not be permitted to appear for the online examination.
  </Typography>
  <Typography variant="body1" paragraph sx={{fontWeight:'bold'}}>
    10. Use of books, notebooks, calculators, watch calculators, pagers, mobile phones, etc., is not permitted in this examination. Candidates are advised not to bring any of the banned items including mobile phones/pagers to the venue of examination as safety arrangement cannot be assured. Any candidate found resorting to any unfair means or malpractice or any misconduct while appearing for the examination, including giving/receiving help to/from any candidate during the examination, will be disqualified. The candidate should be vigilant to ensure that no other candidate is able to copy from his/her answers.
  </Typography>
  <Typography variant="body1" paragraph>
    11. Any request for change of date/session/center/venue will not be entertained.
  </Typography>
  <Typography variant="body1" paragraph>
    12. The possibility of occurrence of some problem in the administration of the examination cannot be ruled out completely which may impact test delivery and/or result from being generated. In that event, every effort will be made to rectify such problem, which may include movement of candidates, delay in test. Conduct of a re-exam is at the absolute discretion of the test conducting body. Candidates will not have any claim for a re-test. Candidates not willing to move or not willing to participate in the delayed process of test delivery shall be summarily rejected from the process.
  </Typography>
  <Typography variant="body1" paragraph>
    13. More than one session may be required if the node's capacity is less or some technical disruption takes place at any center or for any candidate.
  </Typography>
  <Typography variant="body1" paragraph>
    14. Instances of providing incorrect information and/or process violation by a candidate detected at any stage of the admission process will lead to disqualification of the candidate from the admission process and he/she will not be allowed to appear in the admission process of the State CET Cell in the future. If such instances go undetected during the current process but are detected subsequently, such disqualification will take place with retrospective effect.
  </Typography>
  <Typography variant="body1" paragraph>
    15. Anyone found to be disclosing, publishing, reproducing, transmitting, storing, or facilitating transmission and storage of test contents in any form or any information therein, in whole or part thereof, or by any means, verbal or written, electronic or mechanical, or taking away the papers supplied in the examination hall or found to be in unauthorized possession of test content is likely to be prosecuted.
  </Typography>
  <Grid item xs={12} sm={12} md={12} lg={12}  mt={2} fontWeight={600}>
                  <Typography variant="body1" paragraph>
                  For any issue, please contact helpdesk no.<span style={{ color: "#E6393E" }}>  07969134401, 07969134402 </span></Typography>
                  </Grid>
</Grid>

            
                  <Grid item xs={12} sm={12} md={12} lg={12} px={4} mt={2} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
                    <Box>STATE CET CELL </Box>
                    <Box> 8th Floor, New Excelsior Building, A. K. Nayak Marg, Fort, Mumbai- 400 001 </Box>
                    <Box>  ******************************************************  </Box>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>

            <Grid item py={1} xs={12} sm={12} md={12} lg={12} display="flex" justifyContent={"space-around"} alignItems={"center"} mt={1}>
              <Box>Application Number:<span style={{ fontWeight: "600" }}> 248500001 </span></Box>
              <Box>IP Address: <span style={{ fontWeight: "600" }}>45.64.204.206 </span></Box>
              <Box>Date of Downloading: <span style={{ fontWeight: "600" }}> 15/03/2024 01:03:13 </span></Box>
            </Grid>
          </Grid>
        </div>

      </Grid>
    </Grid>
  )
}

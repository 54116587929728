import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import { cssProperties } from "../../utils/commonCssProperties";
import { AlphabetsValidationUppercase } from "../../utils/validations";

export default function ReservationDetailsForm({
  nextPageName,
  previousPageName,
}) {
  const [disability, setDisability] = useState("");
  const [years, setYears] = useState([]);
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  const statusForRedirect = location.state?.status;
  const { masterFields, courseFormDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const date = new Date();
  const currentYear = date.getFullYear();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();
  // console.log(parseInt(watch("castecertificatestatus")),'certsta')
  // console.log(
  //   "dis id watch-----",
  //   parseInt(watch("disabilitytypeid")),
  //   watch("isdisability")
  // );

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const {
    pwdType,
    pwdSubType,
    category,
    caste,
    mothertongue,
    religion,
    minority,
    sportParticipationLevel,
    sportRank,
    sportName,
    sportBody,
    MaritalStatus,
    orphanType,
    ewsDistrict,
    ewsTaluka,
    cvcDistrict,
    nclAuthority,
  } = useSelector((state) => state.masterReducer);
  // console.log('sportParticipationLevel----', sportParticipationLevel);
  // console.log('sportRank----', sportRank);
  // console.log('sportName----', sportName);
  // console.log('sportBody----', sportBody);
  // console.log('MaritalStatus----', MaritalStatus);
  // console.log('orphanType----', orphanType);
  // console.log('ewsDistrict----', ewsDistrict);
  // console.log('ewsTaluka----', ewsTaluka);
  // console.log('cvcDistrict----', cvcDistrict);
  // console.log("religion----", religion);

  // console.log(religion);
  const { courseFullRegistrationDetails, otherDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  console.log(otherDetails, "otherdetails");
  console.log(courseFullRegistrationDetails, "courseFullRegistrationDetails");

  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const isPaid = courseFormDetails[0]?.ispaid;

  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = 1994; i <= currentYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    };
    setYears(generateYears());
  }, []);

  useEffect(() => {
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_PWD_TYPE });
    dispatch({ type: masterActions.GET_CATEGORY });
    dispatch({ type: masterActions.GET_CASTE });
    dispatch({ type: masterActions.GET_RELIGION });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_MINORITY });
    dispatch({ type: masterActions.GET_SPORT_PARTICIPATION_LEVEL });
    dispatch({ type: masterActions.GET_SPORT_RANK });
    dispatch({ type: masterActions.GET_SPORT_NAME });
    dispatch({ type: masterActions.GET_SPORT_BODY });
    // dispatch({ type: masterActions.GET_ORPHAN_TYPE });
    dispatch({ type: masterActions.GET_EWS_DISTRICT });

    dispatch({ type: masterActions.GET_CVC_DISTRICT });
    dispatch({ type: masterActions.GET_NCL_AUTHORITY });
  }, []);

  useEffect(() => {
    if (courseFullRegistrationDetails[0]?.corcandidatereservationid) {
      // console.log("insie courseFullRegistrationDetails");
      reset({ ...courseFullRegistrationDetails[0] });
      dispatch({
        type: masterActions.GET_EWS_TALUKA,
        payload: courseFullRegistrationDetails[0]?.ewsdistrictid,
      });

      let disabilitytypeid = courseFullRegistrationDetails[0]?.disabilitytypeid;
      !isNaN(parseInt(disabilitytypeid)) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: disabilitytypeid },
        });
      if (disabilitytypeid) reset({ ...courseFullRegistrationDetails[0] });
      setValue(
        "isextratime",
        parseInt(courseFullRegistrationDetails[0]?.isextratime)
      );
      setValue(
        "isdisability",
        parseInt(courseFullRegistrationDetails[0]?.isdisability)
      );
      setValue(
        "isscribe",
        parseInt(courseFullRegistrationDetails[0]?.isscribe)
      );
      setValue(
        "pwdsubtypeid",
        parseInt(courseFullRegistrationDetails[0]?.pwdsubtypeid)
      );
      setValue(
        "disabilitytypeid",
        parseInt(courseFullRegistrationDetails[0]?.disabilitytypeid)
      );
      !isNaN(parseInt(getValues("disabilitytypeid"))) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
        });
      setValue(
        "religiousminorityid",
        parseInt(courseFullRegistrationDetails[0]?.religiousminorityid)
      );
      setValue(
        "linguisticminorityid",
        parseInt(courseFullRegistrationDetails[0]?.linguisticminorityid)
      );
      setValue(
        "ismhcandidate",
        parseInt(courseFullRegistrationDetails[0]?.ismhcandidate)
      );
      setValue(
        "isminority",
        parseInt(courseFullRegistrationDetails[0]?.isminority)
      );
    } else if (courseFullRegistrationDetails.length > 0) {
      reset({ ...otherDetails[0] });
      setValue("isextratime", parseInt(otherDetails[0]?.isextratimerequired));
      setValue("isdisability", parseInt(otherDetails[0]?.isdisability));
      setValue("isscribe", parseInt(otherDetails[0]?.isscriberequired));
      setValue("pwdsubtypeid", parseInt(otherDetails[0]?.subdisabilitytypeid));
      setValue("disabilitytypeid", parseInt(otherDetails[0]?.disabilitytypeid));
      !isNaN(parseInt(getValues("disabilitytypeid"))) &&
        dispatch({
          type: masterActions.GET_PWDSUB_TYPE,
          payload: { pwdTypeID: parseInt(getValues("disabilitytypeid")) },
        });
      setValue(
        "religiousminorityid",
        parseInt(otherDetails[0]?.religiousminorityid)
      );
      setValue(
        "linguisticminorityid",
        parseInt(otherDetails[0]?.linguisticminorityid)
      );
      setValue("ismhcandidate", parseInt(otherDetails[0]?.ismhcandidate));
      setValue("isminority", parseInt(otherDetails[0]?.isminority));
    }
  }, [courseFullRegistrationDetails, otherDetails]);
  console.log(minority, "isminority");

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  const handleDisability = (e) => {
    setValue("isdisability", parseInt(e.target.value));
    if (parseInt(e.target.value) !== 1) {
      setValue("disabilitytypeid", "");
      setValue("pwdsubtypeid", "");
      setValue("isscribe", "");
      setValue("isextratime", "");
    }

    setDisability(e.target.value);
    trigger("isdisability");
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  const handleProcced = (data) => {
    console.log("data", data, courseid);
    const corcandidatereservationid =
      courseFullRegistrationDetails[0]?.corcandidatereservationid;
    dispatch({
      type: candidatesActions.COURSE_RESERVATION_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatereservationid
            ? parseInt(corcandidatereservationid)
            : 0,
          candidateid: candidateid,
          status: 1,
          createdby: candidateid,
          updatedby: corcandidatereservationid ? parseInt(candidateid) : "",
          courseid: courseid,
          coursename: coursename,
        },
        navigate: navigate,
        nextPageName: nextPageName,
        statusForRedirect: statusForRedirect,
      },
    });
  };

  // console.log(
  //   'getValues("castevaliditystatus")-------',
  //   getValues("castevaliditystatus")
  // );
  console.log(getValues("categoryid"), "categoryid");

  return (
    <Grid
      p={3}
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "2px solid #0F4F96",
      }}
    >
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.domiicilecertificatestatus}>
            <InputLabel
              shrink={!!watch("domiicilecertificatestatus") || watch("domiicilecertificatestatus") === 0}
              htmlFor="domiicilecertificatestatus"
            >
              Are you Domiciled in the state of Maharashtra?
              <span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="domiicilecertificatestatus"
              label="Are you Domiciled in the state of Maharashtra?"
              {...register("domiicilecertificatestatus", {
                required: "Is MH Candidate is required",
              })}
              onChange={(e) => {
                setValue("domiicilecertificatestatus", e.target.value);
                trigger("domiicilecertificatestatus");
              }}
              value={watch("domiicilecertificatestatus") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.domiicilecertificatestatus && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.domiicilecertificatestatus.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
        ) && (
            <Grid item xs={12} md={12}>
              <Typography
                pt={1}
                sx={{
                  fontSize: "24px",
                  color: `${cssProperties?.color?.tertiary}`,
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {" "}
                SPORTS INFORMATION{" "}
              </Typography>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.issports}>
                <InputLabel
                  shrink={!!watch("issports") || watch("issports") === 0}
                  htmlFor="issports"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="issports"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.IS_SPORT
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_SPORT
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_SPORT
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "56px",
                          boxSizing: "border-box",
                          border: "2px solid red",
                        },
                        maxLength: 50,
                      }}
                      {...field}
                      id="issports"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_SPORT
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_SPORT
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_SPORT,
                        null,
                        isPaid
                      )}
                      onChange={(e) => {
                        field.onChange(e);

                        setValue("sportsparticipationlevelid", "");
                        setValue("sportsparticipationyear", "");
                        setValue("sportsrankid", "");
                        setValue("sportsnameid", "");
                        setValue("sportsbodyid", "");

                      }}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.issports && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.issports.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.SPORT_PARTICIPATION_LEVEL
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sportsparticipationlevelid}>
                <InputLabel
                  shrink={
                    !!watch("sportsparticipationlevelid") ||
                    watch("sportsparticipationlevelid") === 0
                  }
                  htmlFor="sportsparticipationlevelid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                    )?.fieldlabel
                  }
                  {
                    // masterFields?.find(
                    //   (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                    // )?.ismandatory
                    parseInt(watch("issports")) === 1 && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )
                  }
                </InputLabel>
                <Controller
                  name="sportsparticipationlevelid"
                  control={control}
                  rules={{
                    required:
                      // masterFields?.find(
                      //   (field) => field?.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                      // )?.ismandatory === "1"
                      parseInt(watch("issports")) === 1
                        ? "Sports participation level is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="sportsparticipationlevelid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPORT_PARTICIPATION_LEVEL
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("issports")) !== 1 || isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {sportParticipationLevel?.map((val, i) => (
                        <MenuItem key={i} value={val?.sportsparticipationid}>
                          {val?.sportsparticipation}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sportsparticipationlevelid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sportsparticipationlevelid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SPORT_PARTICIPATION_YEAR
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sportsparticipationyear}>
                <InputLabel
                  shrink={
                    !!watch("sportsparticipationyear") ||
                    watch("sportsparticipationyear") === 0
                  }
                  htmlFor="sportsparticipationyear"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SPORT_PARTICIPATION_YEAR
                    )?.fieldlabel
                  }
                  {parseInt(watch("issports")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Controller
                  name="sportsparticipationyear"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("issports")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_PARTICIPATION_YEAR
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_PARTICIPATION_YEAR
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="sportsparticipationyear"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPORT_PARTICIPATION_YEAR
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPORT_PARTICIPATION_YEAR
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("issports")) !== 1 || isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {years.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sportsparticipationyear && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sportsparticipationyear.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_RANK
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sportsrankid}>
                <InputLabel
                  shrink={!!watch("sportsrankid") || watch("sportsrankid") === 0}
                  htmlFor="sportsrankid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SPORT_RANK
                    )?.fieldlabel
                  }
                  {parseInt(watch("issports")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Controller
                  name="sportsrankid"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("issports")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_RANK
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_RANK
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="sportsrankid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPORT_RANK
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPORT_RANK
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("issports")) !== 1 || isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {sportRank.map((val, i) => (
                        <MenuItem key={i} value={val?.sportsrankid}>
                          {val?.sportsrank}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sportsrankid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sportsrankid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sportsnameid}>
                <InputLabel
                  shrink={!!watch("sportsnameid") || watch("sportsnameid") === 0}
                  htmlFor="sportsnameid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SPORT_NAME
                    )?.fieldlabel
                  }
                  {parseInt(watch("issports")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  ) && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="sportsnameid"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("issports")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_NAME
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_NAME
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="sportsnameid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPORT_NAME
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPORT_NAME
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("issports")) !== 1 || isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {sportName?.map((val, i) => (
                        <MenuItem key={i} value={val?.sportsid}>
                          {val?.sportsname}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sportsnameid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sportsnameid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SPORT_BODY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sportsbodyid}>
                <InputLabel
                  shrink={!!watch("sportsbodyid") || watch("sportsbodyid") === 0}
                  htmlFor="sportsbodyid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SPORT_BODY
                    )?.fieldlabel
                  }
                  {parseInt(watch("issports")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  ) && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="sportsbodyid"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("issports")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_BODY
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPORT_BODY
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="sportsbodyid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPORT_BODY
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPORT_BODY
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("issports")) !== 1 || isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {sportBody?.map((val, i) => (
                        <MenuItem key={i} value={val?.sportsbodyid}>
                          {val?.sportsbody}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.sportsbodyid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sportsbodyid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.IS_STUDIED_PHY_EDU
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isphysicaleducation}>
                <InputLabel
                  shrink={
                    !!watch("isphysicaleducation") ||
                    watch("isphysicaleducation") === 0
                  }
                  htmlFor="isphysicaleducation"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.IS_STUDIED_PHY_EDU
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.IS_STUDIED_PHY_EDU
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isphysicaleducation"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.IS_STUDIED_PHY_EDU
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_STUDIED_PHY_EDU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_STUDIED_PHY_EDU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isphysicaleducation"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_STUDIED_PHY_EDU
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_STUDIED_PHY_EDU
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_STUDIED_PHY_EDU,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isphysicaleducation && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isphysicaleducation.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isteachingexperience}>
                <InputLabel
                  shrink={
                    !!watch("isteachingexperience") ||
                    watch("isteachingexperience") === 0
                  }
                  htmlFor="isteachingexperience"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.IS_TEACHING_EXP
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_TEACHING_EXP
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isteachingexperience"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.IS_TEACHING_EXP
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_TEACHING_EXP
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_TEACHING_EXP
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isteachingexperience"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_TEACHING_EXP
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_TEACHING_EXP
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_TEACHING_EXP,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isteachingexperience && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isteachingexperience.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isappliedforelct}>
                <InputLabel
                  shrink={
                    !!watch("isappliedforelct") || watch("isappliedforelct") === 0
                  }
                  htmlFor="isappliedforelct"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.IS_APPLIED_ELCT
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_ELCT
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isappliedforelct"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.IS_APPLIED_ELCT
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_ELCT
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_ELCT
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isappliedforelct"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_APPLIED_ELCT
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_APPLIED_ELCT
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_APPLIED_ELCT,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isappliedforelct && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isappliedforelct.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isappliedforksu}>
                <InputLabel
                  shrink={
                    !!watch("isappliedforksu") || watch("isappliedforksu") === 0
                  }
                  htmlFor="isappliedforksu"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.IS_APPLIED_KSU
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_APPLIED_KSU
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isappliedforksu"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.IS_APPLIED_KSU
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_KSU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_KSU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isappliedforksu"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_APPLIED_KSU
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_APPLIED_KSU
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_APPLIED_KSU,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isappliedforksu && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isappliedforksu.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.IS_APPLIED_SPECIAL_EDU
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={!!errors.isappliedforspecialeducation}
              >
                <InputLabel
                  shrink={
                    !!watch("isappliedforspecialeducation") ||
                    watch("isappliedforspecialeducation") === 0
                  }
                  htmlFor="isappliedforspecialeducation"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isappliedforspecialeducation"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isappliedforspecialeducation"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.IS_APPLIED_SPECIAL_EDU
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("isappliedforspecialeducation", e.target.value);
                        setValue("specialeducationmethods", "");
                      }}
                      value={watch("isappliedforspecialeducation") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_APPLIED_SPECIAL_EDU,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isappliedforspecialeducation && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isappliedforspecialeducation.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SPECIAL_EDU_METHODS
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.specialeducationmethods}>
                <InputLabel
                  shrink={
                    !!watch("specialeducationmethods") ||
                    watch("specialeducationmethods") === 0
                  }
                  htmlFor="specialeducationmethods"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SPECIAL_EDU_METHODS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SPECIAL_EDU_METHODS
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="specialeducationmethods"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("isappliedforspecialeducation")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPECIAL_EDU_METHODS
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.SPECIAL_EDU_METHODS
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="specialeducationmethods"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SPECIAL_EDU_METHODS
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SPECIAL_EDU_METHODS
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        watch("isappliedforspecialeducation") !== 1 ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.specialeducationmethods && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.specialeducationmethods.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            DISABILITY INFORMATION
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PERSON_WITH_DISABILITY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isdisability}>
                <InputLabel
                  shrink={!!watch("isdisability") || watch("isdisability") === 0}
                  htmlFor="isdisability"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.PERSON_WITH_DISABILITY
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.PERSON_WITH_DISABILITY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERSON_WITH_DISABILITY
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PERSON_WITH_DISABILITY
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="isdisability"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PERSON_WITH_DISABILITY
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERSON_WITH_DISABILITY
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("isdisability", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.PERSON_WITH_DISABILITY
                      )?.ismandatory === "1"
                        ? "Disability status is required"
                        : false,
                  })}
                  onChange={handleDisability}
                  value={watch("isdisability") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.PERSON_WITH_DISABILITY,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
                {errors.isdisability && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isdisability.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TYPE_OF_DISABILITY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.disabilitytypeid}>
                <InputLabel
                  shrink={!!watch("disabilitytypeid")}
                  htmlFor="disabilitytypeid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TYPE_OF_DISABILITY
                    )?.fieldlabel
                  }
                  {parseInt(watch("isdisability")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.TYPE_OF_DISABILITY
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.TYPE_OF_DISABILITY
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="disabilitytypeid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.TYPE_OF_DISABILITY
                          )?.fieldlabel
                        }
                      </span>
                      {parseInt(watch("isdisability")) === 1 && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                    </span>
                  }
                  {...register("disabilitytypeid", {
                    required:
                      parseInt(watch("isdisability")) === 1 &&
                      "Type of  is required",
                  })}
                  disabled={
                    parseInt(watch("isdisability")) !== 1 || isPaid === "1"
                  }
                  onChange={(e) => {
                    setValue("disabilitytypeid", e.target.value);
                    trigger("disabilitytypeid");
                    setValue("pwdsubtypeid", "");
                    setValue("isscribe", "");
                    setValue("isextratime", "");
                    const disabilitytypeid = e.target.value;
                    // console.log(disabilitytypeid, "disabilitytypeid");
                    if (disabilitytypeid && !isNaN(parseInt(disabilitytypeid))) {
                      dispatch({
                        type: masterActions.GET_PWDSUB_TYPE,
                        payload: { pwdTypeID: disabilitytypeid },
                      });
                    }
                  }}
                  value={watch("disabilitytypeid") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {pwdType?.map((val, i) => (
                    <MenuItem value={val?.pwdtypeid} key={i}>
                      {val?.pwdtype}
                    </MenuItem>
                  ))}
                </Select>
                {errors.disabilitytypeid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.disabilitytypeid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.PWD_SUB_TYPE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.pwdsubtypeid}>
                <InputLabel
                  shrink={!!watch("pwdsubtypeid")} // Shrink only when there is a value
                  htmlFor="pwdsubtypeid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.PWD_SUB_TYPE
                    )?.fieldlabel
                  }
                  {parseInt(watch("isdisability")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PWD_SUB_TYPE
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.PWD_SUB_TYPE
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                    maxLength: 50,
                  }}
                  id="pwdsubtypeid"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.PWD_SUB_TYPE
                    )?.fieldlabel
                  }
                  {...register("pwdsubtypeid", {
                    required:
                      parseInt(watch("isdisability")) === 1 &&
                      "Type of PWD is required",
                  })}
                  disabled={
                    parseInt(watch("isdisability")) !== 1 || isPaid === "1"
                  }
                  onChange={(e) => {
                    setValue("pwdsubtypeid", e.target.value);
                    trigger("pwdsubtypeid");
                    let extratimerequiredvalue = pwdSubType?.find(
                      (obj) =>
                        parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                    )?.extratimerequired;
                    setValue("isextratime", extratimerequiredvalue);
                    let isscriberequiredvalue = pwdSubType?.find(
                      (obj) =>
                        parseInt(obj.pwdsubtypeid) === parseInt(e.target.value)
                    )?.scriberequired;
                    setValue("isscribe", isscriberequiredvalue);
                  }}
                  value={watch("pwdsubtypeid") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {pwdSubType?.map((val, i) => (
                    <MenuItem value={val?.pwdsubtypeid} key={i}>
                      {val?.pwdsubtype}
                    </MenuItem>
                  ))}
                </Select>
                {errors.pwdsubtypeid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.pwdsubtypeid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.REQUIRE_EXTRA_TIME
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isextratime}>
                <InputLabel
                  shrink={!!watch("isextratime") || watch("isextratime") === 0}
                  htmlFor="isextratime"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.REQUIRE_EXTRA_TIME
                    )?.fieldlabel
                  }
                  {parseInt(watch("isdisability")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Controller
                  name="isextratime"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("isdisability")) === 1 &&
                      "Extra Time  is required",
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.REQUIRE_EXTRA_TIME
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.REQUIRE_EXTRA_TIME
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isextratime"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.REQUIRE_EXTRA_TIME
                              )?.fieldlabel
                            }
                          </span>
                          {parseInt(watch("isdisability")) === 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      disabled={
                        parseInt(watch("isdisability")) !== 1 ||
                        (watch("pwdsubtypeid") &&
                          pwdSubType.find(
                            (obj) =>
                              parseInt(obj.pwdsubtypeid) ===
                              parseInt(watch("pwdsubtypeid"))
                          )?.extratimerequired !== 2) ||
                        isPaid === "1"
                      }
                      value={field.value ?? ""}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isextratime && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isextratime.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.REQUIRE_SCRIBE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isscribe}>
                <InputLabel
                  shrink={!!watch("isscribe") || watch("isscribe") === 0}
                  htmlFor="isscribe"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.REQUIRE_SCRIBE
                    )?.fieldlabel
                  }
                  {parseInt(watch("isdisability")) === 1 && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </InputLabel>
                <Controller
                  name="isscribe"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("isdisability")) === 1 &&
                      "Type of  is required",
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.REQUIRE_SCRIBE
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.REQUIRE_SCRIBE
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isscribe"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.REQUIRE_SCRIBE
                              )?.fieldlabel
                            }
                          </span>
                          {parseInt(watch("isdisability")) === 1 && (
                            <span className="error" style={{ color: "red" }}>
                              {" "}
                              *
                            </span>
                          )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("isdisability")) !== 1 ||
                        (watch("pwdsubtypeid") &&
                          pwdSubType.find(
                            (obj) =>
                              parseInt(obj.pwdsubtypeid) ===
                              parseInt(watch("pwdsubtypeid"))
                          )?.scriberequired !== 2) ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isscribe && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isscribe.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            ORPHAN INFORMATION
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isorphan}>
                <InputLabel
                  shrink={!!watch("isorphan") || watch("isorphan") === 0}
                  htmlFor="isorphan"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.ORPHAN
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) => field?.masterfieldlabel === masterFieldLabel.ORPHAN
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isorphan"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.ORPHAN
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.ORPHAN
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.ORPHAN
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isorphan"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.ORPHAN
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.ORPHAN
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("isorphan", e.target.value);
                        setValue("orphantype", "");
                      }}
                      value={watch("isorphan") ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.ORPHAN,
                        null,
                        isPaid
                      )}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isorphan && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isorphan.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.orphantype}>
                <InputLabel
                  shrink={!!watch("orphantype") || watch("orphantype") === 0}
                  htmlFor="orphantype"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.ORPHAN_TYPE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="orphantype"
                  control={control}
                  rules={{
                    required:
                      watch("isorphan") === 1 ? "Select is required" : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.ORPHAN_TYPE
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.ORPHAN_TYPE
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="orphantype"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.ORPHAN_TYPE
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ORPHAN_TYPE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={watch("isorphan") !== 1 || isPaid === "1"}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>A</MenuItem>
                      <MenuItem value={2}>B</MenuItem>
                      <MenuItem value={3}>C</MenuItem>
                    </Select>
                  )}
                />
                {errors.orphantype && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.orphantype.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            CATEGORY INFORMATION
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.IS_MH_CANDIDATE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.ismhcandidate}>
                <InputLabel
                  shrink={
                    !!watch("ismhcandidate") || watch("ismhcandidate") === 0
                  }
                  htmlFor="ismhcandidate"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.IS_MH_CANDIDATE
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.IS_MH_CANDIDATE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_MH_CANDIDATE
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: isFieldDisabled(
                        masterFields,
                        masterFieldLabel.IS_MH_CANDIDATE
                      )
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  disabled={isPaid === "1"}
                  id="ismhcandidate"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.IS_MH_CANDIDATE
                    )?.fieldlabel
                  }
                  {...register("ismhcandidate", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel?.IS_MH_CANDIDATE
                      )?.ismandatory === "1"
                        ? "MH Candidate is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("ismhcandidate", e.target.value);
                    trigger("ismhcandidate");

                    setValue("categoryid", "");
                    setValue("ewsdistrictid", "");
                    setValue("ewstalukaid", "");
                    setValue("castecertificatestatus", "");
                    setValue("cvcdistrictid", "");
                    setValue("nclauthorityid", "");
                    setValue("castename", "");
                    setValue("casteid", "");
                    setValue("appliedforews", "");
                    setValue("ewscertificatestatus", "");
                    setValue("ewsapplicationdate", "");
                    setValue("ewsapplicationno", "");
                    setValue("castevaliditystatus", "");
                    setValue("cvcapplicationno", "");
                    setValue("cvcapplicationdate", "");
                    setValue("cvcauthority", "");
                    setValue("castecertificateno", "");
                    setValue("castevaliditycertificateno", "");
                    setValue("cvcname", "");
                    setValue("castecertificateappdate", "");
                    setValue("nclstatus", "");
                    setValue("nclapplicationdate", "");
                    setValue("nclauthorityid", "");
                  }}
                  value={watch("ismhcandidate") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
                {errors.ismhcandidate && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.ismhcandidate.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CATEGORY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.categoryid}>
                <InputLabel
                  shrink={!!watch("categoryid") || watch("categoryid") === 0}
                  htmlFor="categoryid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel?.CATEGORY
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.CATEGORY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  id="categoryid"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel?.CATEGORY
                    )?.fieldlabel
                  }
                  {...register("categoryid", {
                    required:
                      parseInt(watch("ismhcandidate")) === 1 &&
                      "Category is required",
                  })}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 || isPaid === "1"
                  }
                  onChange={(e) => {
                    setValue("categoryid", e.target.value);
                    setValue("ewsdistrictid", "");
                    setValue("ewstalukaid", "");
                    setValue("castecertificatestatus", "");
                    setValue("cvcdistrictid", "");
                    setValue("nclauthorityid", "");
                    setValue("castename", "");
                    setValue("casteid", "");
                    setValue("appliedforews", "");
                    setValue("ewscertificatestatus", "");
                    setValue("ewsapplicationdate", "");
                    setValue("ewsapplicationno", "");
                    setValue("castevaliditystatus", "");
                    setValue("cvcapplicationno", "");
                    setValue("cvcapplicationdate", "");
                    setValue("cvcauthority", "");
                    setValue("castecertificateno", "");
                    setValue("castevaliditycertificateno", "");
                    setValue("cvcname", "");
                    setValue("castecertificateappdate", "");
                    setValue("nclstatus", "");
                    setValue("nclapplicationdate", "");
                    setValue("nclapplicationno", "");
                    setValue("nclauthorityid", "");

                    trigger("categoryid");
                  }}
                  value={watch("categoryid") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  {category?.map((val) => (
                    <MenuItem key={val?.categoryid} value={val?.categoryid}>
                      {val?.category}
                    </MenuItem>
                  ))}
                </Select>
                {errors.categoryid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.categoryid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ENTER_CASTE_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ENTER_CASTE_NAME
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ENTER_CASTE_NAME
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter Caste Name"
                {...register("castename", {
                  required:
                    parseInt(watch("categoryid")) === 1
                      ? "Caste name is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.castename}
                helperText={errors.castename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("castename"),
                }}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) !== 1 ||
                  isPaid === "1"
                }
              />
            </Grid>
          )}
        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SELECT_CASTE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.casteid}>
                <InputLabel
                  shrink={!!watch("casteid") || watch("casteid") === 0}
                  htmlFor="casteid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SELECT_CASTE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="casteid"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("categoryid")) > 1
                        ? "Select casteid is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) <= 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) <= 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="casteid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.SELECT_CASTE
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SELECT_CASTE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) <= 1 ||
                        isNaN(parseInt(watch("categoryid"))) ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {caste?.map((val) => (
                        <MenuItem key={val?.casteid} value={val?.casteid}>
                          {val?.caste}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.casteid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.casteid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.EWS
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.appliedforews}>
                <InputLabel
                  shrink={
                    !!watch("appliedforews") || watch("appliedforews") === 0
                  }
                  htmlFor="appliedforews"
                >
                  {
                    masterFields?.find(
                      (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) => field?.masterfieldlabel === masterFieldLabel.EWS
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="appliedforews"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("categoryid")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="appliedforews"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel === masterFieldLabel.EWS
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel === masterFieldLabel.EWS
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("appliedforews", e.target.value);
                        setValue("ewsdistrictid", "");
                        setValue("ewstalukaid", "");
                        setValue("ewscertificatestatus", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        dispatch({
                          type: masterActions.SET_EWS_TALUKA,
                          payload: [],
                        });
                      }}
                      value={watch("appliedforews") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.appliedforews && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.appliedforews.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.EWS_CERTIFICATE_STATUS
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.ewscertificatestatus}>
                <InputLabel
                  shrink={
                    !!watch("ewscertificatestatus") ||
                    watch("ewscertificatestatus") === 0
                  }
                  htmlFor="ewscertificatestatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EWS_CERTIFICATE_STATUS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_CERTIFICATE_STATUS
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="ewscertificatestatus"
                  control={control}
                  rules={{
                    required:
                      watch("appliedforews") === 1 ? "Select is required" : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1 ||
                              parseInt(watch("appliedforews")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1 ||
                              parseInt(watch("appliedforews")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="ewscertificatestatus"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.EWS_CERTIFICATE_STATUS
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EWS_CERTIFICATE_STATUS
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("ewscertificatestatus", e.target.value);
                        setValue("ewsdistrictid", "");
                        setValue("ewstalukaid", "");
                        setValue("ewsapplicationdate", "");
                        setValue("ewsapplicationno", "");
                        dispatch({
                          type: masterActions.SET_EWS_TALUKA,
                          payload: [],
                        });
                      }}
                      value={watch("ewscertificatestatus") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                      <MenuItem value={0}> Applied But Not Received</MenuItem>
                      <MenuItem value={2}> Not Available</MenuItem>
                    </Select>
                  )}
                />
                {errors.ewscertificatestatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.ewscertificatestatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Application_Number
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        parseInt(watch("ewscertificatestatus")) !== 0
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        parseInt(watch("ewscertificatestatus")) !== 0
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EWS_Certificate_Application_Number
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EWS_Certificate_Application_Number
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter Ews Certificate Application Number"
                {...register("ewsapplicationno", {
                  required:
                    watch("ewscertificatestatus") === 0
                      ? "EWS application number is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.ewsapplicationno}
                helperText={errors.ewsapplicationno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("ewsapplicationno"),
                }}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) !== 1 ||
                  parseInt(watch("appliedforews")) !== 1 ||
                  parseInt(watch("ewscertificatestatus")) !== 0 ||
                  isPaid === "1"
                }
              />
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Application_Date
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="ewsapplicationdate"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("ewscertificatestatus")) === 0
                        ? "EWS application date is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1 ||
                              parseInt(watch("appliedforews")) !== 1 ||
                              parseInt(watch("ewscertificatestatus")) !== 0
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) !== 1 ||
                              parseInt(watch("appliedforews")) !== 1 ||
                              parseInt(watch("ewscertificatestatus")) !== 0
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                        readOnly: true,
                      }}
                      {...field}
                      disableFuture
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.EWS_Certificate_Application_Date
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.EWS_Certificate_Application_Date
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      format="DD/MM/YYYY"
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) !== 1 ||
                        parseInt(watch("appliedforews")) !== 1 ||
                        parseInt(watch("ewscertificatestatus")) !== 0 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.ewsapplicationdate,
                          helperText: errors.ewsapplicationdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Issuing_District
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.ewsdistrictid}>
                <InputLabel
                  shrink={
                    !!watch("ewsdistrictid") || watch("ewsdistrictid") === 0
                  }
                  htmlFor="ewsdistrictid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.EWS_Certificate_Issuing_District
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_Certificate_Issuing_District
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  id="ismhcandidate"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.EWS_Certificate_Issuing_District
                    )?.fieldlabel
                  }
                  {...register("ewsdistrictid", {
                    required:
                      parseInt(watch("ewscertificatestatus")) === 0
                        ? "EWS application issuing district is required"
                        : false,
                  })}
                  onChange={(e) => {
                    const districtid = e.target.value;
                    // console.log("districtid---", districtid);
                    setValue("ewsdistrictid", e.target.value);

                    dispatch({
                      type: masterActions.GET_EWS_TALUKA,
                      payload: districtid,
                    });

                    trigger("ewsdistrictid");
                  }}
                  value={watch("ewsdistrictid") ?? ""}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) !== 1 ||
                    parseInt(watch("appliedforews")) !== 1 ||
                    parseInt(watch("ewscertificatestatus")) !== 0 ||
                    isPaid === "1"
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  {ewsDistrict?.map((val, i) => (
                    <MenuItem key={i} value={val?.districtid}>
                      {val?.district}
                    </MenuItem>
                  ))}
                </Select>
                {errors.ewsdistrictid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.ewsdistrictid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EWS_Certificate_Issuing_Taluka
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.ewstalukaid}>
                <InputLabel
                  shrink={!!watch("ewstalukaid") || watch("ewstalukaid") === 0}
                  htmlFor="ewstalukaid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.EWS_Certificate_Issuing_Taluka
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EWS_Certificate_Issuing_Taluka
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) !== 1 ||
                          parseInt(watch("appliedforews")) !== 1 ||
                          parseInt(watch("ewscertificatestatus")) !== 0
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  id="ewstalukaid"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.EWS_Certificate_Issuing_Taluka
                    )?.fieldlabel
                  }
                  {...register("ewstalukaid", {
                    required:
                      parseInt(watch("ewscertificatestatus")) === 0
                        ? "EWS application issuing taluka is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("ewstalukaid", e.target.value);
                    trigger("ewstalukaid");
                  }}
                  value={watch("ewstalukaid") ?? ""}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) !== 1 ||
                    parseInt(watch("appliedforews")) !== 1 ||
                    parseInt(watch("ewscertificatestatus")) !== 0 ||
                    isPaid === "1"
                  }
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {ewsTaluka?.map((val, i) => (
                    <MenuItem key={i} value={val?.talukaid}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
                </Select>
                {errors.ewstalukaid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.ewstalukaid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.POSSESS_CASTE_CERTIFICATE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.castecertificatestatus}>
                <InputLabel
                  shrink={
                    !!watch("castecertificatestatus") ||
                    watch("castecertificatestatus") === 0
                  }
                  htmlFor="castecertificatestatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="castecertificatestatus"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("categoryid")) > 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      {...field}
                      id="castecertificatestatus"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POSSESS_CASTE_CERTIFICATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("castecertificatestatus", e.target.value);

                        setValue("castecertificateno", "");
                        setValue("castevaliditystatus", "");
                        setValue("cvcapplicationno", "");
                        setValue("cvcapplicationdate", "");
                        setValue("cvcauthority", "");
                        setValue("cvcdistrictid", "");
                        setValue("cvcname", "");
                        setValue("castecertificateappdate", "");
                        setValue("nclstatus", "");
                        setValue("nclapplicationno", "");
                        setValue("nclapplicationdate", "");
                        setValue("nclauthorityid", "");
                        setValue("castevaliditycertificateno", "");
                      }}
                      value={watch("castecertificatestatus") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 0 ||
                        isNaN(parseInt(watch("categoryid"))) ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                      <MenuItem value={2}>Applied But Not Received</MenuItem>
                      <MenuItem value={3}>Not Applied</MenuItem>
                    </Select>
                  )}
                />
                {errors.castecertificatestatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.castecertificatestatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_CERTIFICATE_NO
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) > 2 ||
                        isNaN(parseInt(watch("castecertificatestatus")))
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) > 2 ||
                        isNaN(parseInt(watch("castecertificatestatus")))
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_CERTIFICATE_NO
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_CERTIFICATE_NO
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter the Casete Certificate Number"
                {...register("castecertificateno", {
                  required:

                    parseInt(watch("castecertificatestatus")) === 1 ||
                    parseInt(watch("castecertificatestatus")) === 2
                      ? "Caste certificate  number is required" : false,

                  // validate: percentageValidation,
                })}
                // onInput={NumbersValidation}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  // parseInt(watch("castecertificatestatus")) !== 1 ||
                  parseInt(watch("castecertificatestatus")) ===3 ||
                  isNaN(parseInt(watch("castecertificatestatus"))) ||
                  isPaid === "1"
                } 
                value={watch("castecertificateno") ?? ""}
                error={!!errors.castecertificateno}
                helperText={errors.castecertificateno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("castecertificateno"),
                }}
              />
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 2 ||
                        isNaN(parseInt(watch("castecertificatestatus")))
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 2 ||
                        isNaN(parseInt(watch("castecertificatestatus")))
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_CERTIFICATE_RECEIPT_NO
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter the Casete Certificate Receipt Number"
                {...register("castecertificatereceiptno", {
                  required:

                    parseInt(watch("castecertificatestatus")) === 2
                      ? "Caste certificate Receipt number is required" : false,

                  // validate: percentageValidation,
                })}
                // onInput={NumbersValidation}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("castecertificatestatus")) !== 2 ||
                  isNaN(parseInt(watch("castecertificatestatus"))) ||
                  isPaid === "1"
                }
                value={watch("castecertificatereceiptno") ?? ""}
                error={!!errors.castecertificatereceiptno}
                helperText={errors.castecertificatereceiptno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("castecertificatereceiptno"),
                }}
              />
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="castecertificateappdate"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("castecertificatestatus")) === 2
                        ? "Caste valdity date is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                        readOnly: true,
                      }}
                      {...field}
                      disableFuture
                      format="DD/MM/YYYY"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.CASTE_CERTIFICATE_APPLICATION_DATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 2 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.castecertificateappdate,
                          helperText: errors.castecertificateappdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.POSSESS_CASTE_VALIDITY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.castevaliditystatus}>
                <InputLabel
                  shrink={
                    !!watch("castevaliditystatus") ||
                    watch("castevaliditystatus") === 0
                  }
                  htmlFor="castevaliditystatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.POSSESS_CASTE_VALIDITY
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.POSSESS_CASTE_VALIDITY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="castevaliditystatus"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("castecertificatestatus")) === 1
                        ? "Caste Validity is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="castevaliditystatus"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.POSSESS_CASTE_VALIDITY
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.POSSESS_CASTE_VALIDITY
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("castevaliditystatus", e.target.value);
                        setValue("cvcapplicationno", "");
                        setValue("cvcapplicationdate", "");
                        setValue("cvcauthority", "");
                        setValue("cvcdistrictid", "");
                        setValue("cvcname", "");
                        setValue("nclstatus", "");
                        setValue("nclapplicationno", "");
                        setValue("nclapplicationdate", "");
                        setValue("nclauthorityid", "");
                        setValue("castevaliditycertificateno", "");
                      }}
                      value={watch("castevaliditystatus") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={0}>Not Applied</MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                      <MenuItem value={2}>Applied But Not Received</MenuItem>
                    </Select>
                  )}
                />
                {errors.castevaliditystatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.castevaliditystatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 1
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 1
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_CERTIFICATE_NO
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter cvc status"
                {...register("castevaliditycertificateno", {
                  required:
                    parseInt(watch("castevaliditystatus")) === 1
                      ? "Caste certificate no is required"
                      : false,
                  // validate: percentageValidation,
                })}
                // onInput={NumbersValidation}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("castevaliditystatus")) !== 1 ||
                  isPaid === "1"
                }
                error={!!errors.castevaliditycertificateno}
                helperText={errors.castevaliditycertificateno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("castevaliditycertificateno"),
                }}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_APPLICATION_NO
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter the SSC Total percentage"
                {...register("cvcapplicationno", {
                  required:
                    parseInt(watch("castevaliditystatus")) === 2
                      ? "Caste certificate status is required"
                      : false,
                  // validate: percentageValidation,
                })}
                // onInput={NumbersValidation}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("castevaliditystatus")) !== 2 ||
                  isPaid === "1"
                }
                error={!!errors.cvcapplicationno}
                helperText={errors.cvcapplicationno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("cvcapplicationno"),
                }}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="cvcapplicationdate"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("castevaliditystatus")) === 2
                        ? "Caste valdity application date is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },

                        readOnly: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castevaliditystatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("castevaliditystatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}

                      {...field}
                      disableFuture
                      format="DD/MM/YYYY"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (f) => f?.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (f) => f?.masterfieldlabel === masterFieldLabel.CASTE_VALIDITY_APPLICATION_DATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}*
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date && dayjs(date).isValid()) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      onKeyDown={(e) => e.preventDefault()}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.castecertificateappdate,
                          helperText: errors.castecertificateappdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },

                          inputProps: {
                            readOnly: true,
                          },

                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />


                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_VALIDITY_ISSUING_AUTH_NAME
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter the SSC Total percentage"
                {...register("cvcauthority", {
                  required:
                    parseInt(watch("castevaliditystatus")) === 2
                      ? "Caste certificate validity status is required"
                      : false,
                  // validate: percentageValidation,
                })}
                // onInput={NumbersValidation}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("castevaliditystatus")) !== 2 ||
                  isPaid === "1"
                }
                error={!!errors.cvcauthority}
                helperText={errors.cvcauthority?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("cvcauthority"),
                }}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.cvcdistrictid}>
                <InputLabel
                  shrink={
                    !!watch("cvcdistrictid") || watch("cvcdistrictid") === 0
                  }
                  htmlFor="cvcdistrictid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.CASTE_VALIDITY_ISSUING_DISTRICT
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_VALIDITY_ISSUING_DISTRICT
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("castevaliditystatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  id="cvcdistrictid"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.CASTE_VALIDITY_ISSUING_DISTRICT
                    )?.fieldlabel
                  }
                  {...register("cvcdistrictid", {
                    required:
                      parseInt(watch("castevaliditystatus")) === 2
                        ? "caste certificate issue district is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("cvcdistrictid", e.target.value);
                    trigger("cvcdistrictid");
                  }}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("castevaliditystatus")) !== 2 ||
                    isPaid === "1"
                  }
                  value={watch("cvcdistrictid") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {cvcDistrict?.map((val, i) => (
                    <MenuItem key={i} value={val?.districtid}>
                      {val?.district}
                    </MenuItem>
                  ))}
                </Select>
                {errors.cvcdistrictid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.cvcdistrictid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  maxLength: 50,
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("castevaliditystatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_NAME_AS_CASTE_CERTIFICATE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter the SSC Total percentage"
                {...register("cvcname", {
                  required:
                    parseInt(watch("categoryid")) === 1 ||
                      parseInt(watch("ismhcandidate")) !== 1 ||
                      parseInt(watch("castevaliditystatus")) !== 2
                      ? false
                      : "Caste certificate name is required",
                  // validate: percentageValidation,
                })}
                onInput={AlphabetsValidationUppercase}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("castevaliditystatus")) !== 2 ||
                  isPaid === "1"
                }
                error={!!errors.cvcname}
                helperText={errors.cvcname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("cvcname"),
                }}
              />
            </Grid>
          )}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOMICILE_CERTIFICATE
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.domiicilecertificatestatus}
            >
              <InputLabel
                shrink={
                  !!watch("domiicilecertificatestatus") ||
                  watch("domiicilecertificatestatus") === 0
                }
                htmlFor="domiicilecertificatestatus"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOMICILE_CERTIFICATE
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.DOMICILE_CERTIFICATE
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Controller
                name="domiicilecertificatestatus"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.DOMICILE_CERTIFICATE
                    )?.ismandatory === "1"
                      ? "Select is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                    {...field}
                    id="domiicilecertificatestatus"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.DOMICILE_CERTIFICATE
                            )?.fieldlabel
                          }
                        </span>
                        {masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.DOMICILE_CERTIFICATE
                        )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                      </span>
                    }
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.DOMICILE_CERTIFICATE
                    )}
                  >
                    <MenuItem value="" disabled>
                      Select One
                    </MenuItem>
                    <MenuItem value={1}>Yes</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                )}
              />
              {errors.domiicilecertificatestatus && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.domiicilecertificatestatus.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.religiousminorityid}
            >
              <InputLabel
                shrink={
                  !!watch("religiousminorityid") ||
                  watch("religiousminorityid") === 0
                }
                htmlFor="religiousminorityid"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.RELIGIOUS_MINORITY
                  )?.fieldlabel
                }
                <span className="error">*</span>
              </InputLabel>
              <Controller
                name="religiousminorityid"
                control={control}
                rules={{ required: "Select Religion is required" }}
                render={({ field }) => (
                  <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                    {...field}
                    id="religiousminorityid"
                    label={
                      <span>
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RELIGIOUS_MINORITY
                            )?.fieldlabel
                          }
                        </span>
                        *
                      </span>
                    }
                    value={field.value ?? ""}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {religion?.map((val) => (
                      <MenuItem key={val?.religionid} value={val?.religionid}>
                        {val?.religion}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.religiousminorityid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.religiousminorityid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.hscboardid}>
            <InputLabel shrink={!!watch("hscboardid")} htmlFor="hscboardid">
              HSC Board<span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="hscboardid"
              label="HSC Board"
              {...register("hscboardid", {
                required: " HSC Board is required",
              })}
              onChange={(e) => {
                setValue("hscboardid", e.target.value);
                trigger("hscboardid");
              }}
              value={watch("hscboardid") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {board?.map((val, i) => (
                <MenuItem value={val?.boardid} key={i}>
                  {val?.board}
                </MenuItem>
              ))}
            </Select>
            {errors.hscboardid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.hscboardid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.nclstatus}>
                <InputLabel
                  shrink={!!watch("nclstatus") || watch("nclstatus") === 0}
                  htmlFor="nclstatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                    )?.fieldlabel
                  }{" "}
                  {currentYear}?
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="nclstatus"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("categoryid")) > 3 &&
                        parseInt(watch("castecertificatestatus")) === 1
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("categoryid")) === 2 ||
                              parseInt(watch("categoryid")) === 3 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("categoryid")) === 2 ||
                              parseInt(watch("categoryid")) === 3 ||
                              parseInt(watch("castecertificatestatus")) !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="nclstatus"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      onChange={(e) => {
                        setValue("nclstatus", e.target.value);
                        setValue("nclapplicationno", "");
                        setValue("nclapplicationdate", "");
                        setValue("nclauthorityid", "");
                      }}
                      value={watch("nclstatus") ?? ""}
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 2 ||
                        parseInt(watch("categoryid")) === 3 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        isPaid === "1"
                      }
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Available</MenuItem>
                      <MenuItem value={2}> Applied But Not Received</MenuItem>
                      <MenuItem value={3}>Not Applied</MenuItem>
                    </Select>
                  )}
                />
                {errors.nclstatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.nclstatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 2 ||
                        parseInt(watch("categoryid")) === 3 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        parseInt(watch("nclstatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border:
                      parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 2 ||
                        parseInt(watch("categoryid")) === 3 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        parseInt(watch("nclstatus")) !== 2
                        ? "2px solid grey"
                        : "2px solid #3F41D1",
                  },
                }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_NO
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter Non Creamy Layer Application Number"
                {...register("nclapplicationno", {
                  required:
                    parseInt(watch("nclstatus")) === 2
                      ? "non creamy layer certificate application no is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.nclapplicationno}
                helperText={errors.nclapplicationno?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("nclapplicationno"),
                }}
                disabled={
                  parseInt(watch("ismhcandidate")) !== 1 ||
                  parseInt(watch("categoryid")) === 1 ||
                  parseInt(watch("categoryid")) === 2 ||
                  parseInt(watch("categoryid")) === 3 ||
                  parseInt(watch("castecertificatestatus")) !== 1 ||
                  parseInt(watch("nclstatus")) !== 2 ||
                  isPaid === "1"
                }
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="nclapplicationdate"
                  control={control}
                  rules={{
                    required:
                      parseInt(watch("nclstatus")) === 2
                        ? "Caste valdity date is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("categoryid")) === 2 ||
                              parseInt(watch("categoryid")) === 3 ||
                              parseInt(watch("castecertificatestatus")) !== 1 ||
                              parseInt(watch("nclstatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            parseInt(watch("ismhcandidate")) !== 1 ||
                              parseInt(watch("categoryid")) === 1 ||
                              parseInt(watch("categoryid")) === 2 ||
                              parseInt(watch("categoryid")) === 3 ||
                              parseInt(watch("castecertificatestatus")) !== 1 ||
                              parseInt(watch("nclstatus")) !== 2
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                        readOnly: true,
                      }}
                      {...field}
                      disableFuture
                      format="DD/MM/YYYY"
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.NON_CREAMY_LAYER_CERTIFICATE_APP_DATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        parseInt(watch("ismhcandidate")) !== 1 ||
                        parseInt(watch("categoryid")) === 1 ||
                        parseInt(watch("categoryid")) === 2 ||
                        parseInt(watch("categoryid")) === 3 ||
                        parseInt(watch("castecertificatestatus")) !== 1 ||
                        parseInt(watch("nclstatus")) !== 2 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.nclapplicationdate,
                          helperText: errors.nclapplicationdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                          inputProps: {
                            readOnly: true,
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.NON_CREAMY_LAYER_AUTHORITY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.nclauthorityid}>
                <InputLabel
                  shrink={
                    !!watch("nclauthorityid") || watch("nclauthorityid") === 0
                  }
                  htmlFor="nclauthorityid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.NON_CREAMY_LAYER_AUTHORITY
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.NON_CREAMY_LAYER_AUTHORITY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border:
                        parseInt(watch("ismhcandidate")) !== 1 ||
                          parseInt(watch("categoryid")) === 1 ||
                          parseInt(watch("categoryid")) === 2 ||
                          parseInt(watch("categoryid")) === 3 ||
                          parseInt(watch("castecertificatestatus")) !== 1 ||
                          parseInt(watch("nclstatus")) !== 2
                          ? "2px solid grey"
                          : "2px solid #3F41D1",
                    },
                  }}
                  inputProps={{
                    style: { height: "56px", boxSizing: "border-box" },
                  }}
                  id="nclauthorityid"
                  label={
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel?.NON_CREAMY_LAYER_AUTHORITY
                    )?.fieldlabel
                  }
                  {...register("nclauthorityid", {
                    required:
                      parseInt(watch("nclstatus")) === 2
                        ? "ncl authority is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("nclauthorityid", e.target.value);
                    trigger("nclauthorityid");
                  }}
                  disabled={
                    parseInt(watch("ismhcandidate")) !== 1 ||
                    parseInt(watch("categoryid")) === 1 ||
                    parseInt(watch("categoryid")) === 2 ||
                    parseInt(watch("categoryid")) === 3 ||
                    parseInt(watch("castecertificatestatus")) !== 1 ||
                    parseInt(watch("nclstatus")) !== 2 ||
                    isPaid === "1"
                  }
                  value={watch("nclauthorityid") ?? ""}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {nclAuthority?.map((val, i) => (
                    <MenuItem key={i} value={val?.nclauthorityid}>
                      {val?.nclauthority}
                    </MenuItem>
                  ))}
                </Select>
                {errors.nclauthorityid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.nclauthorityid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            MINORITY INFORMATION{" "}
          </Typography>
        </Grid>

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.MINORITY_CATEGORY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isminority}>
                <InputLabel
                  shrink={!!watch("isminority") || watch("isminority") === 0}
                  htmlFor="isminority"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.MINORITY_CATEGORY
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.MINORITY_CATEGORY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="isminority"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.MINORITY_CATEGORY
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.MINORITY_CATEGORY
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: isFieldDisabled(
                            masterFields,
                            masterFieldLabel.MINORITY_CATEGORY
                          )
                            ? "2px solid grey"
                            : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="isminority"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.MINORITY_CATEGORY
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.MINORITY_CATEGORY
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.MINORITY_CATEGORY,
                        null,
                        isPaid
                      )}
                      onChange={(e) => {
                        setValue("isminority", e.target.value);
                        if (e.target.value === 0) {
                          setValue("linguisticminorityid", "");
                          setValue("religiousminorityid", "");
                        }
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  )}
                />
                {errors.isminority && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.isminority.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.LINGUISTIC_MINORITY || "Linguistic minority"
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.linguisticminorityid}>
                <InputLabel
                  shrink={
                    !!watch("linguisticminorityid") ||
                    watch("linguisticminorityid") === 0
                  }
                  htmlFor="linguisticminorityid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.LINGUISTIC_MINORITY
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.LINGUISTIC_MINORITY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="linguisticminorityid"
                  control={control}
                  rules={{
                    required:
                      watch("isminority") === 1
                        ? "Select Linguistic Minority is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            getValues("isminority") !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            getValues("isminority") !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="linguisticminorityid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.LINGUISTIC_MINORITY
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.LINGUISTIC_MINORITY
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={getValues("isminority") !== 1 || isPaid === "1"}
                      value={field.value ?? ""}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {minority?.map(
                        (item) =>
                          item.type === "Linguistic Minority" && (
                            <MenuItem
                              key={item?.minorityid}
                              value={item?.minorityid || ""}
                            >
                              {item?.minority}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  )}
                />
                {errors.linguisticminorityid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.linguisticminorityid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RELIGIOUS_MINORITY
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.religiousminorityid}>
                <InputLabel
                  shrink={
                    !!watch("religiousminorityid") ||
                    watch("religiousminorityid") === 0
                  }
                  htmlFor="religiousminorityid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.RELIGIOUS_MINORITY
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.RELIGIOUS_MINORITY
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Controller
                  name="religiousminorityid"
                  control={control}
                  rules={{
                    required:
                      watch("isminority") === 1
                        ? "Religious Minority of Candidate is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:
                            getValues("isminority") !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border:
                            getValues("isminority") !== 1
                              ? "2px solid grey"
                              : "2px solid #3F41D1",
                        },
                      }}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        maxLength: 50,
                      }}
                      {...field}
                      id="religiousminorityid"
                      label={
                        <span>
                          <span>
                            {
                              masterFields?.find(
                                (field) =>
                                  field?.masterfieldlabel ===
                                  masterFieldLabel.RELIGIOUS_MINORITY
                              )?.fieldlabel
                            }
                          </span>
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.RELIGIOUS_MINORITY
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={getValues("isminority") !== 1 || isPaid === "1"}
                      value={field.value ?? ""}
                    >
                      <MenuItem value="" disabled>
                        Select One
                      </MenuItem>
                      {minority?.map(
                        (item) =>
                          item.type === "Religious Minority" && (
                            <MenuItem
                              key={item?.minorityid}
                              value={item?.minorityid || ""}
                            >
                              {item.minority}
                            </MenuItem>
                          )
                      )}
                    </Select>
                  )}
                />
                {errors.religiousminorityid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.religiousminorityid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small" error={!!errors.isscriberequired}>
            <InputLabel
              shrink={
                !!watch("isscriberequired") || watch("isscriberequired") === 0
              }
              htmlFor="isscriberequired"
            >
              Do You Require Scribe?<span className="error">*</span>
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="isscriberequired"
              label="Do You Require Scribe?"
              {...register("isscriberequired", {
                required: "Require Scribe is required",
              })}
              onChange={handleScribe}
              value={watch("isscriberequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isscriberequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isscriberequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.isextratimerequired}
          >
            <InputLabel
              shrink={!!watch("isextratimerequired")}
              htmlFor="isextratimerequired"
            >
              Do You Require Extra Time?
              {scribe !== 0 && <span className="error">*</span>}
            </InputLabel>
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              id="isextratimerequired"
              label="Do You Require Extra Time?"
              {...register("isextratimerequired", {
                required:
                  scribe !== 0 ? "Require extra time  is required" : false,
              })}
              disabled={scribe === 0 || getValues("isscribe") === 0}
              onChange={(e) => {
                setValue("isextratimerequired", e.target.value);
                trigger("isextratimerequired");
              }}
              value={watch("isextratimerequired") ?? ""}
            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
            {errors.isextratimerequired && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.isextratimerequired.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}

        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            disabled={isPaid === "1"}
            name={buttonLoader ? "loader" : "save and proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";
import { FcApproval } from "react-icons/fc";
import { BiSolidErrorCircle } from "react-icons/bi";

export default function ToastMessage() {

    const { alert } = useSelector(state => state.commonReducer);

    return (
        <>
            <Snackbar
                open={alert?.show === true && alert?.status === 'success'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* <Alert
                    color="warning"
                    sx={{ bgcolor: 'background.paper' }}
                    iconMapping={{
                        success: <VscVerifiedFilled
                            fontSize="inherit" />,
                    }}
                    variant="filled"
                severity="success"
                sx={{ width: '100%', backgroundColor: 'rgb(176 240 176)' }}
                >
                    {alert?.message}
                </Alert> */}
                <Alert icon={<FcApproval style={{ height: "24px", width: "24px" }} />}
                    sx={{backgroundColor:"#ffffff",borderLeftColor: 'rgb(139, 195, 74)', fontSize: "14px", fontWeight: "bold", justifyContent: "center", alignItems: "center", borderLeftStyle: 'solid', borderLeftWidth: '0.5em' }}
                    severity="success">
                    {alert?.message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={alert?.show === true && alert?.status === 'failed'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* <Alert
                    variant="filled"
                    severity="error"
                sx={{ width: '100%', backgroundColor: 'rgb(241 206 206)' }}
                >
                    {alert?.message}
                </Alert> */}
                <Alert icon={<BiSolidErrorCircle style={{ height: "24px", width: "24px" }} />}
                    sx={{backgroundColor:"#ffffff", borderLeftColor: 'red', fontSize: "14px", fontWeight: "bold", justifyContent: "center", alignItems: "center", borderLeftStyle: 'solid', borderLeftWidth: '0.5em' }}
                    severity="error">
                    {alert?.message}
                </Alert>
            </Snackbar>
        </>
    )
};

import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useForm } from "react-hook-form";
import {
    Typography,
    TextField,
    Grid,
    Button,
    Box
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { cssProperties } from "../../utils/commonCssProperties";
import { useDispatch } from "react-redux";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import authActions from "../../redux/auth/actions";
import { MobileNumberFirstNumberValidation, MobileNumberValidation } from "../../utils/validations";
import verifiedIcon from "../../assets/images/icons/verified.svg";

const ForgetEmail = (props) => {
    const { setemailForget, emailForget, setReset, setShow, setVerify } = props
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [Forget, setForget] = useState(true)
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [focusedInput, setFocusedInput] = useState(null);
    const [resendCountdown, setResendCountdown] = useState(120);
    const [verified, setVerified] = useState(true);
    const [error, setError] = useState("");
    const [otp, setOtp] = useState("");
    const [attempt, setAttempt] = useState(0);
    const [wrongOTP, setWrongotp] = useState(0);
    const otpValue = Cookies.get('mhet_cnd_forgetemail_otp');
    const modifyOtp = otp.split("");
    const [red, setRed] = useState(false)
    console.log(modifyOtp, "modify", otpValue);
    const [timer, setTimer] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(30 * 60);
    const [timeRemainingmin, setTimeRemainingmin] = useState(0);
    const [timeRemainingsec, setTimeRemainingsec] = useState(0);
    console.log(timeRemainingmin, "timeRemaining");
    let now = new Date();
    let endTime = new Date(Cookies.get('otpEndTime'));

    console.log(endTime, "endTime");
    const [diff, setDiff] = useState(false);
    let difference = endTime - now;
    console.log(difference, 'diff ');

    const handleClick = () => {
        if (attempt === 5) {
            if (timeRemaining === 0) {
                handleResendOTP();
            } else {
                handleBlock();
            }
        } else {
            handleResendOTP();
        }
    };
    useEffect(() => {
        if (timeRemainingmin > 0) {
            setAttempt(5)
            setWrongotp(5)
            setResendCountdown(0)
        }
    }, [])

    // useEffect(() => {
    //         setAttempt(0)
    //         setWrongotp(0)
    //         setResendCountdown(120)
    // },[timeRemainingmin==0])

    function formatTime(number) {
        return number < 10 ? '0' + number : number;
    }
    useEffect(() => {
        let remainingSeconds = Math.floor((difference / 1000) % 60);
        let remainingMinutes = Math.floor((difference / (1000 * 60)) % 60);
        setTimeRemainingmin(formatTime(remainingMinutes))
        setTimeRemainingsec(formatTime(remainingSeconds))
    });




    useEffect(() => {
        if (wrongOTP === 5 || attempt === 5) {
            console.log(wrongOTP, "wrongOTP");

            setDiff(true);
            const initialTime = difference ? (timeRemainingmin * timeRemainingsec) : (30 * 60);
            startTimer(initialTime);
        }
    }, [wrongOTP, attempt]);

    const startTimer = (initialTime) => {
        setResendCountdown(0);
        setTimeRemaining(initialTime);

        if (timer) {
            clearInterval(timer);
        }


        let now = new Date();
        let endTime = new Date(now);
        endTime.setMinutes(endTime.getMinutes() + 30);

        let hours = formatTime(now.getHours());
        let minutes = formatTime(now.getMinutes());
        let seconds = formatTime(now.getSeconds());
        Cookies.set('otpStartTime', new Date());

        let endHours = formatTime(endTime.getHours());
        let endMinutes = formatTime(endTime.getMinutes());
        let endSeconds = formatTime(endTime.getSeconds());

        Cookies.set('otpEndTime', endTime);

        console.log(hours, minutes, seconds, "start");
        console.log(endHours, endMinutes, endSeconds, "startend");



        const newTimer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(newTimer);
                    Cookies.remove('otpStartTime');
                    Cookies.remove('otpEndTime');
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        setTimer(newTimer);
    };
    useEffect(() => {
        const now = new Date();
        const endTime = new Date(Cookies.get('otpEndTime'));

        if (endTime && endTime > now) {
            const difference = endTime - now;
            const remainingSeconds = Math.floor((difference / 1000) % 60);
            const remainingMinutes = Math.floor((difference / (1000 * 60)) % 60);

            setTimeRemaining(remainingMinutes * 60 + remainingSeconds);
            setTimeRemainingmin(formatTime(difference ? timeRemainingmin : remainingMinutes));
            setTimeRemainingsec(formatTime(difference ? timeRemainingsec : remainingSeconds));

            // startTimer(remainingMinutes * 60 + remainingSeconds);
        }
    }, [])
    useEffect(() => {
        return () => {
            clearInterval(timer);
        };
    }, [timer]);


    const inputStyle = (isFocused) => ({
        width: "38px",
        height: "40px",
        margin: "5px",
        borderRadius: "8px",
        outline: "none",
        border: modifyOtp.join("") === otpValue
            ? "1px solid #504E50"
            : red
                ? "2px solid red"
                : isFocused
                    ? "5px solid #BDD8FE"
                    : ""
        ,
        // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
        // border: modifyOtp.join("") === otpValue ? "1px solid #504E50" :modifyOtp.join("") !== otpValue ? "2px solid red": isFocused ? "5px solid #BDD8FE" :"",
        textAlign: "center"
    });

    const onSubmit = (data) => {
        Cookies.set('mhet_cnd_forgetemail_mobileno', EncryptFunction(data?.mobileno))
        dispatch({ type: authActions.CANDIDATE_FORGET_EMAILID, payload: { data: data?.mobileno, setForget: setForget, setResendCountdown: setResendCountdown, setVerified: setVerified } });
        window.scrollTo({ top: 0, behavior: 'smooth' });

    };
    useEffect(() => {
        const cookieData = Cookies.get('mhet_email');
        if (cookieData) {
            setEmail(cookieData)
        }
    }, []);

    useEffect(() => {
        let timer;
        if (resendCountdown > 0) {
            timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendCountdown]);

    // useEffect(() => {
    //     if (modifyOtp.join("") === otpValue) {
    //         setRed(false)
    //         setWrongotp(0)
    //     }
    // }, [modifyOtp.join("") === otpValue])


    const handleVerify = () => {
        console.log(wrongOTP, "wrongOTP");
        setRed(false)
        if (modifyOtp.length === 6) {
            setRed(false)
            if (modifyOtp.join("") === otpValue) {
                // setWrongotp(0)
            }
            else {
                // setWrongotp(wrongOTP + 1)
                // setRed(true)
            }
        }
        else {
            setError("Enter valid OTP");
        }
        let mobileno = Cookies.get('mobile_no_forget_emailid')
        dispatch({ type: authActions.FORGOTMAIL_OTP_VERIFY, payload: { mobileno: mobileno, otp: otp, setForget: setForget, setVerified: setVerified, setRed: setRed, setWrongotp: setWrongotp, wrongOTP: wrongOTP } });
        dispatch({ type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION, payload: { validated: true } });

    };

    const handleResendOTP = () => {
        setAttempt(attempt + 1)
        setResendCountdown(120);
        let mobileno = Cookies.get('mobile_no_forget_emailid')
        dispatch({ type: authActions.CANDIDATE_FORGET_EMAILID, payload: { data: mobileno, setResendCountdown: setResendCountdown } });
    };

    const handleBlock = () => {

    }

    return (
        <div>
            <Grid container spacing={2} p={3} direction="column" alignItems="center">

                {Forget ? (
                    <Grid container spacing={2} p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    color: `#504E50`,
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <FaArrowLeftLong
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setReset(true); // Show ForgetPassword component
                                        setemailForget(false); // Hide ForgetEmail component
                                    }}
                                    style={{ cursor: "pointer", marginRight: "8px" }} // Adds space between arrow and text
                                />
                                Forgot Email ID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: `#504E50`,
                                    lineHeight: "16.94px"
                                }}
                            >
                                Registered mobile number
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: `rgba(80, 78, 80, 0.7)`,
                                    lineHeight: "14.54px",
                                }}
                            >
                                To retrieve your Email ID, please enter your registered mobile number.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            {/* <TextField
                                size="small"
                                {...register("mobileno", {
                                    required: "Mobile number is required",
                                    minLength: {
                                        value: 10,
                                        message: "Enter valid mobile number",
                                    },
                                    validate: MobileNumberFirstNumberValidation,
                                })}
                                error={!!errors.mobileno}
                                helperText={errors.mobileno ? errors.mobileno.message : ""}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "10px",
                                        minHeight: "52px",
                                        "& fieldset": {
                                            border: "2px solid rgba(189, 216, 254, 1)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(100, 150, 255, 1)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "rgba(50, 100, 255, 1)",
                                        },
                                        "&.Mui-error fieldset": {
                                            borderColor: "red",
                                        },
                                    },
                                    "& .MuiFormHelperText-root": {
                                        margin: 0,
                                        color: "red",
                                    },
                                }}

                                onInput={MobileNumberValidation}
                                inputProps={{
                                    // style: { height: "56px", boxSizing: "border-box" },
                                    maxLength: 10,
                                }}
                            /> */}
                            <TextField
                                size="small"
                                {...register("mobileno", {
                                    required: "Mobile number is required",
                                    minLength: {
                                        value: 10,
                                        message: "Mobile number must be exactly 10 digits",
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: "Mobile number must be exactly 10 digits",
                                    },
                                    validate: {
                                        firstDigit: MobileNumberFirstNumberValidation,
                                        validFormat: (value) => /^\d{10}$/.test(value) || "Mobile number must be exactly 10 digits",
                                    },
                                })}
                                error={!!errors.mobileno}
                                helperText={errors.mobileno ? errors.mobileno.message : ""}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "10px",
                                        minHeight: "52px",
                                        "& fieldset": {
                                            border: "2px solid rgba(189, 216, 254, 1)",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "rgba(100, 150, 255, 1)",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "rgba(50, 100, 255, 1)",
                                        },
                                        "&.Mui-error fieldset": {
                                            borderColor: "red",
                                        },
                                    },
                                    "& .MuiFormHelperText-root": {
                                        margin: 0,
                                        color: "red",
                                    },
                                }}
                                onInput={MobileNumberValidation}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `rgba(15, 79, 150, 1)`,
                                    lineHeight: "16.94px",

                                }}

                            >
                                <span
                                    style={{
                                        color: `rgba(15, 79, 150, 1)`,
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        window.scrollTo({ top: 0, behavior: 'smooth' });

                                        setReset(true);
                                        setShow(true);
                                    }}>

                                    Go back to Sign In page
                                </span>

                            </Typography>
                        </Grid>
                        {timeRemainingmin > 0 && timeRemainingsec > 0 && <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "bold",
                                lineHeight: "16.94px",
                                textAlign: "center",
                                color: "red"
                            }}>
                            <div>Session has been locked for {timeRemainingmin} :{timeRemainingsec}</div>
                        </Typography>}
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={
                                    handleSubmit(onSubmit)
                                }
                                disabled={timeRemainingmin > 0 && timeRemainingsec > 0}
                                sx={{
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    padding: "10px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: "rgba(15, 79, 150, 0.8)",
                                    },
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                ) : (verified ?
                    <Grid container p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    color: `#504E50`,
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <FaArrowLeftLong
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setReset(true); // Show ForgetPassword component
                                        setemailForget(false); // Hide ForgetEmail component
                                    }}
                                    style={{ cursor: "pointer", marginRight: "8px" }} // Adds space between arrow and text
                                />
                                Forgot Email ID
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `#504E50`,
                                    lineHeight: "16.94px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                Enter OTP
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(80, 78, 80, 0.6);
`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                We've sent a 6-digit verification code to your registered mobile number. <br />
                                Please enter it below to confirm your identity.
                            </Typography>
                        </Grid>

                        {wrongOTP >= 1 && <Typography
                            mt={2}
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "bold",
                                lineHeight: "16.94px",
                                textAlign: "center",
                                color: "red"
                            }}
                        > Wrong OTP entered ( {wrongOTP} / 5)            </Typography>}

                        <Box my={2}>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props, index) => (
                                    <input
                                        {...props}
                                        style={inputStyle(focusedInput === index)}
                                        onFocus={() => setFocusedInput(index)}
                                        onBlur={() => setFocusedInput(null)}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Typography variant="body2" sx={{ color: 'red', marginTop: '12px' }}>
                                {error && <small className="error">{error}</small>}
                            </Typography>
                        </Box>
                        {timeRemainingmin > 0 && timeRemainingsec > 0 && (wrongOTP === 5 || attempt === 5) && <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "bold",
                                lineHeight: "16.94px",
                                textAlign: "center",
                                color: "red"
                            }}>
                            <div>User have to wait for {timeRemainingmin} :{timeRemainingsec} to enable Resend OTP </div>
                        </Typography>}
                        <Grid item xs={12} lg={3} sx={{
                            // justifyContent: "center",
                            // alignItems: "center",
                            // textAlign: "center"
                            padding: 0
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={otp?.length !== 6 || (wrongOTP === 5)}
                                onClick={handleVerify}
                                sx={{
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    maxwidth: "320px",
                                    width: "290px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: `${cssProperties?.color?.buttonHover}`,
                                    },

                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                        <Grid
                            sx={{
                                width: "18rem",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {!resendCountdown > 0 && (
                                <Typography
                                    mt={2}
                                    sx={{
                                        cursor: attempt === 5 || wrongOTP === 5 ? (timeRemaining === 0 ? "pointer" : "not-allowed") : "pointer",
                                        color: attempt === 5 || wrongOTP === 5 ? (timeRemaining === 0 ? `${cssProperties?.color?.primary}` : "grey") : `${cssProperties?.color?.primary}`,
                                        fontWeight: "bold",
                                    }}
                                    onClick={
                                        attempt === 5 || wrongOTP === 5 ? handleBlock : handleClick}
                                >
                                    Resend OTP
                                </Typography>
                            )}

                            {(resendCountdown > 0) && (

                                <Typography
                                    sx={{
                                        color: `6B6B6B`,
                                        fontWeight: "bold",
                                    }}
                                    textAlign={"center"}
                                    mt={2}

                                >
                                    RESEND OTP IN &nbsp;
                                    {resendCountdown > 0 ? `(${resendCountdown}) SEC` : "0 SEC"}
                                </Typography>

                            )}
                            <Typography
                                mt={2}
                                sx={{
                                    color: `6B6B6B`,
                                    fontWeight: "bold",
                                }}
                            >
                                {attempt} / 5 &nbsp;
                            </Typography>

                        </Grid>
                    </Grid> :
                    <Grid container spacing={2} p={3} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{
                            width: "100%", marginBottom: 2,
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <img src={verifiedIcon} alt="verified" style={{
                                width: "96px",
                                height: "91.73px"
                            }} />
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Verification successful
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                Thank you for verifying your identity. Your registered email is:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {`${Cookies.get("mhet_email")}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{
                            width: "100%", display: "flex",
                            justifyContent: "center"
                        }}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    marginBottom: 2,
                                    background: "rgba(15, 79, 150, 1)",
                                    color: "#D7E2E9",
                                    padding: "10px",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    minHeight: "40px",
                                    width: "300px",
                                    lineHeight: "16.4px",
                                    "&:hover": {
                                        backgroundColor: `${cssProperties?.color?.buttonHover}`,
                                    },

                                }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShow(true)
                                }}
                            >
                                Go to Sign In Page
                            </Button>
                        </Grid>
                        <Grid>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: `rgba(0, 0, 0, 0.87)`,
                                    lineHeight: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >
                                OR                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ width: "100%", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    marginBottom: 1,
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: `rgba(15, 79, 150, 1)`,
                                    lineHeight: "16.94px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}
                            >

                                <span
                                    style={{
                                        color: `rgba(15, 79, 150, 1)`,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setVerify(false);
                                        setReset(true);
                                        setemailForget(false)
                                    }}
                                >
                                    Reset Password
                                </span>
                            </Typography>

                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div >
    );
}

export default ForgetEmail;

import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar.js";
import { Link, Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import { NavLink } from 'react-router-dom';
import Cookies from "js-cookie";
import Index from "../components/PaymentReciptList/index.js";
import PageHeading from "../layouts/pageHeading.js";

const PaymentReciptList = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_cnd_project')}/home`} sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        textDecoration : "none"
    }}>
        Dashboard
    </Link>,
    <Typography key="3" color="text.primary"
        sx={{
            display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: "bold",
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}
    >
        Payment Receipts <MdKeyboardArrowDown size={16} />
    </Typography>,
];
  return (
    <Layout>
      <Header breadcrumbs={breadcrumbs} />
      <Sidebar />
      <PageHeading page="Payment Receipts" />
      <Index />
    </Layout>
  );
};

export default PaymentReciptList;

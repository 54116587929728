const masterActions = {
    GET_GENDER : 'GET_GENDER',
    SET_GENDER : 'SET_GENDER',
    GET_MOTHERTONGUE : 'GET_MOTHERTONGUE',
    SET_MOTHERTONGUE : 'SET_MOTHERTONGUE',
    GET_RELIGION : 'GET_RELIGION',
    SET_RELIGION : 'SET_RELIGION',
    GET_REGION : 'GET_REGION',
    SET_REGION : 'SET_REGION',
    GET_NATIONALITY : 'GET_NATIONALITY',
    SET_NATIONALITY : 'SET_NATIONALITY',
    GET_ANNUALINCOME : 'GET_ANNUALINCOME',
    SET_ANNUALINCOME : 'SET_ANNUALINCOME',
    GET_STATE : 'GET_STATE',
    SET_STATE : 'SET_STATE',
    GET_DISTRICT : 'GET_DISTRICT',
    SET_DISTRICT : 'SET_DISTRICT',
    GET_TALUKA : 'GET_TALUKA',
    SET_TALUKA : 'SET_TALUKA',
    GET_VILLAGE : 'GET_VILLAGE',
    SET_VILLAGE : 'SET_VILLAGE',
    SET_CATEGORY : 'SET_CATEGORY',
    GET_CATEGORY : 'GET_CATEGORY',
    SET_CASTE : 'SET_CASTE',
    GET_CASTE : 'GET_CASTE',
    SET_PWD_TYPE : 'SET_PWD_TYPE',
    GET_PWD_TYPE : 'GET_PWD_TYPE',
    SET_PWDSUB_TYPE : 'SET_PWDSUB_TYPE',
    GET_PWDSUB_TYPE : 'GET_PWDSUB_TYPE',
    GET_BOARD : 'GET_BOARD',
    SET_BOARD : 'SET_BOARD',
    GET_LANGUAGE : 'GET_LANGUAGE',
    SET_LANGUAGE : 'SET_LANGUAGE',
    GET_ALL_DISTRICT : 'GET_ALL_DISTRICT',
    SET_ALL_DISTRICT : 'SET_ALL_DISTRICT',
    GET_GRIEVANCE : 'GET_GRIEVANCE',
    SET_GRIEVANCE : 'SET_GRIEVANCE',
    SET_MINORITY : 'SET_MINORITY',
    GET_MINORITY : 'GET_MINORITY',
    GET_DOCUMENT_TYPE : 'GET_DOCUMENT_TYPE',
    SET_DOCUMENT_TYPE : 'SET_DOCUMENT_TYPE',

    // GET_STATE_FOR_REGISTRATION: 'GET_STATE_FOR_REGISTRATION',
    // SET_STATE_FOR_REGISTRATION: 'SET_STATE_FOR_REGISTRATION',
    GET_DISTRICT_FOR_REGISTRATION: 'GET_DISTRICT_FOR_REGISTRATION',
    SET_DISTRICT_FOR_REGISTRATION: 'SET_DISTRICT_FOR_REGISTRATION',
    GET_TALUKA_FOR_REGISTRATION: 'GET_TALUKA_FOR_REGISTRATION',
    SET_TALUKA_FOR_REGISTRATION: 'SET_TALUKA_FOR_REGISTRATION',
    GET_VILLAGE_FOR_REGISTRATION: 'GET_VILLAGE_FOR_REGISTRATION',
    SET_VILLAGE_FOR_REGISTRATION: 'SET_VILLAGE_FOR_REGISTRATION',

    GET_DISTRICT_FOR_COURSE: 'GET_DISTRICT_FOR_COURSE',
    SET_DISTRICT_FOR_COURSE: 'SET_DISTRICT_FOR_COURSE',
    GET_TALUKA_FOR_COURSE: 'GET_TALUKA_FOR_COURSE',
    SET_TALUKA_FOR_COURSE: 'SET_TALUKA_FOR_COURSE',
    GET_VILLAGE_FOR_COURSE: 'GET_VILLAGE_FOR_COURSE',
    SET_VILLAGE_FOR_COURSE: 'SET_VILLAGE_FOR_COURSE',

    GET_SPORT_PARTICIPATION_LEVEL:"GET_SPORT_PARTICIPATION_LEVEL",
    SET_SPORT_PARTICIPATION_LEVEL:"SET_SPORT_PARTICIPATION_LEVEL",
    GET_SPORT_RANK:"GET_SPORT_RANK",
    SET_SPORT_RANK:"SET_SPORT_RANK",
    GET_SPORT_NAME:"GET_SPORT_NAME",
    SET_SPORT_NAME:"SET_SPORT_NAME",
    GET_SPORT_BODY:"GET_SPORT_BODY",
    SET_SPORT_BODY:"SET_SPORT_BODY",
    
    GET_MARITAL_STATUS:"GET_MARITAL_STATUS",
    SET_MARITAL_STATUS:"SET_MARITAL_STATUS",

    GET_ORPHAN_TYPE : "GET_ORPHAN_TYPE",
    SET_ORPHAN_TYPE : "SET_ORPHAN_TYPE",

    GET_EWS_DISTRICT : "GET_EWS_DISTRICT",
    SET_EWS_DISTRICT: "SET_EWS_DISTRICT",
    GET_EWS_TALUKA:"GET_EWS_TALUKA",
    SET_EWS_TALUKA:"SET_EWS_TALUKA",
    
    GET_CVC_DISTRICT : "GET_CVC_DISTRICT",
    SET_CVC_DISTRICT : "SET_CVC_DISTRICT",

    GET_SSC_SCHOOL_DISTRICT:"GET_SSC_SCHOOL_DISTRICT",
    SET_SSC_SCHOOL_DISTRICT:"SET_SSC_SCHOOL_DISTRICT",
    GET_SSC_SCHOOL_TALUKA:"GET_SSC_SCHOOL_TALUKA",
    SET_SSC_SCHOOL_TALUKA:"SET_SSC_SCHOOL_TALUKA",

    
    GET_HSC_SCHOOL_DISTRICT:"GET_HSC_SCHOOL_DISTRICT",
    SET_HSC_SCHOOL_DISTRICT:"SET_HSC_SCHOOL_DISTRICT",
    GET_HSC_SCHOOL_TALUKA:"GET_HSC_SCHOOL_TALUKA",
    SET_HSC_SCHOOL_TALUKA:"SET_HSC_SCHOOL_TALUKA",

    GET_GRADUATION_BRANCH_NAME : "GET_GRADUATION_BRANCH_NAME",
    SET_GRADUATION_BRANCH_NAME : "SET_GRADUATION_BRANCH_NAME",
    GET_AWARDED_CLASS :"GET_AWARDED_CLASS",
    SET_AWARDED_CLASS :"SET_AWARDED_CLASS",
    GET_PWD_SUB_TYPE : "GET_PWD_SUB_TYPE",
    SET_PWD_SUB_TYPE : "SET_PWD_SUB_TYPE",
    GET_NCL_AUTHORITY : "GET_NCL_AUTHORITY",
    SET_NCL_AUTHORITY : "SET_NCL_AUTHORITY",
    
};

export default masterActions;
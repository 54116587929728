import React from "react";
import Index from "../components/courseRegistration/index";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import PageHeading from "../layouts/pageHeading";
import { cssProperties } from "../utils/commonCssProperties";
import { Typography, Link } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import {
  NavLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import Cookies from "js-cookie";
import { DecryptObjectFunction, EncryptObjectFunction } from "../utils/cryptoFunction";

export default function CourseRegistration() {
  const location = useLocation();
  const coursename = location?.state?.coursename;
  const [searchParams] = useSearchParams();
  const encryptedData = searchParams.get("c_data");
  const decrypted = DecryptObjectFunction(encryptedData);
  const courseName = decrypted?.coursename;
  const edit = decrypted?.edit;
  const courseIdVal = decrypted?.courseid;
  let encryptedParams = {
    courseid: courseIdVal,
    coursename:courseName,
    edit:edit
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_cnd_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={ edit ? `/${Cookies.get("mhet_cnd_project")}/editing-facility?c_data=${EncryptObjectFunction(encryptedParams)}` : `/${Cookies.get("mhet_cnd_project")}/course-selection`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      {edit ? 'Edit Details' : 'Course selection'}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      {coursename || courseName}
      <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];
  return (
    <>
      <Layout>
        <Header breadcrumbs={breadcrumbs} />
        <Sidebar />
        <PageHeading />
        <Index />
      </Layout>
    </>
  );
}

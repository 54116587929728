import { Box, MenuItem, Radio, Select, Typography } from '@mui/material'
import React from 'react'
import PrintDownloadButton from '../../common/button/printDownloadButton'
import { MdDownload, MdVisibility } from 'react-icons/md'
import DataTable from '../../common/dataTable'

const Index = () => {
    const columnData = [
        {
          name: 'Question No',
          accessor: 'question_no'
        },
        {
          name: 'Question Details',
          accessor: 'question_details'
        },
        {
          name: 'Answer Key Response',
          accessor: 'answer_key'
        },
        {
          name: 'Reference Details',
          accessor: 'reference_details'
        },
        {
          name: 'Accept',
          accessor: 'accept'
        },
        {
            name: 'Reject',
            accessor: 'reject'
          },
      ]
      let tableData = [
        {
          question_no: 1,
          question_details: 'Registration',
          answer_key: 1000,
          reference_details: '12/04/2024',
          accept:<Radio name="question_1" color='success'  />,
          reject:<Radio name="question_1" color="error" />,
        },
    ]    
    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
                <Typography pb={1} variant="h5"  >Dashboard | Objection Tracker</Typography>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Select  MenuProps={{
                  disableScrollLock: true,
              }} defaultValue={1} size="small">
                        <MenuItem value={1}>Project Name</MenuItem>
                    </Select>
                    <Select  MenuProps={{
                  disableScrollLock: true,
              }} defaultValue={1} size="small">
                        <MenuItem value={1}>Course Name</MenuItem>
                    </Select>
                    <Select  MenuProps={{
                  disableScrollLock: true,
              }} defaultValue={1} size="small">
                        <MenuItem value={1}>Select Question No</MenuItem>
                    </Select>
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <PrintDownloadButton name="View Raised Objection" startIcon={<MdVisibility />} />
                </Box>
            </Box>
            <Box>
        <DataTable keys={columnData} values={tableData} />

            </Box>


        </>
    )
}

export default Index

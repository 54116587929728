import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  handleNameValidation,
  AlphabetsValidation,
  handleEmailValidation,
  AlphabetsValidationUppercase,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import commonActions from "../../redux/common/actions";

export default function PersonalDetailsForm({ preprocessFormData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [maritalStatus, setMaritalStatus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleMaritalStatusChange = (event) => {
    console.log(`watch('maritalstatusid')---`, watch('maritalstatusid'));

    const selectedValue = event.target.value;
    setValue("maritalstatusid", selectedValue);

    if (selectedValue == 2) {
      setValue("spousename", "");
    }
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { uploadDetails } = useSelector((state) => state.candidatesReducer);

  console.log("uploadDetails---", uploadDetails);

  const { gender, mothertongue, region, religion, nationality, annualIncome, MaritalStatus } =
    useSelector((state) => state.masterReducer);
  const { personalDetails } = useSelector((state) => state.candidatesReducer);
  console.log(personalDetails);
  const candidatedraftid = personalDetails[0]?.candidatedraftid;
  console.log('personalDetails---', personalDetails);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  // useEffect(() => {
  //   if (personalDetails.length > 0) {
  //     const {

  //       status
  //     } = personalDetails[0];
  //     if(status === 2){
  //       console.log(status,'status')
  //       setDisabled(true)
  //     }else{
  //       console.log(status,'status false')
  //       setDisabled(false)
  //     }

  //   }
  // }, []);
  useEffect(() => {
    if (personalDetails.length > 0) {


      if (personalDetails[0].status === 2 || personalDetails[0].status === 1) {
        // console.log(personalDetails[0].status, "status");
        setDisabled(true);
      } else {
        // console.log(personalDetails[0].status, "status false");
        setDisabled(false);
      }






      const {
        genderid,
        fathername,
        mothername,
        mothertongueid,
        religionid,
        regionid,
        nationalityid,
        familyincomeid,
        maritalstatusid,
        emailid,
        mobileno,
        spousename,
        status,
      } = personalDetails[0];
      if (status === 2) {
        console.log(status, "status");
        setDisabled(true);
      } else {
        console.log(status, "status false");
        setDisabled(false);
      }
      setValue("candidatename", personalDetails[0]?.candidatename);
      setValue("genderid", genderid);
      setValue("dob", personalDetails[0]?.dob);
      setValue("fathername", fathername === 'null' ? '' : fathername);
      setValue("mothername", mothername === 'null' ? '' : mothername);
      setValue("mothertongueid", mothertongueid);
      setValue("religionid", religionid);
      setValue("regionid", regionid);
      setValue("nationalityid", nationalityid ? nationalityid : nationality?.find((item, index) => { return item?.nationality == 'Indian' })?.nationalityid ? nationality?.find((item, index) => { return item?.nationality == 'Indian' })?.nationalityid : "");
      setValue("familyincomeid", familyincomeid);
      setValue("maritalstatusid", maritalstatusid ? maritalstatusid : 0);
      setValue("emailid", emailid);
      setValue("mobileno", mobileno);
      setValue("spousename", spousename === null ? "" : spousename);
      console.log(`watch('maritalstatusid')---`, watch('maritalstatusid'));



    }
  }, [personalDetails, setValue]);

  useEffect(() => {
    dispatch({ type: masterActions.GET_GENDER });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_REGION });
    dispatch({ type: masterActions.GET_RELIGION });
    dispatch({ type: masterActions.GET_NATIONALITY });
    dispatch({ type: masterActions.GET_ANNUALINCOME });
    dispatch({ type: masterActions.GET_MARITAL_STATUS });

    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
  }, [candidateid]);


  useEffect(() => {
    if (candidateid) {
      setTimeout(() => {
        dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      }, 1000)
    }
  }, [candidateid]);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: commonActions.SHOW_SIDEBAR, payload: uploadDetails?.length == 0 ? false : uploadDetails[0]?.candidateuploaddraftid ? false : true });
    }
  }, [candidateid, uploadDetails]);

  console.log(getValues("mothername"), "mothername");

  const handleDraft = (getValues) => {
    const values = getValues();
    let operation = candidatedraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: 1,
          operation: operation,
          candidateid: parseInt(candidateid),
          mothername: watch("mothername") === null ? null : getValues("mothername"),
          fathername: getValues("mothername") === null ? null : getValues("fathername")
        },
      },
    });
    console.log(operation);


    setEditMode(false)


    console.log(`watch('maritalstatusid')----`, watch('maritalstatusid'));
  };

  const handleSave = (data) => {
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
        },
        navigate: navigate,
      },
    });
  };

  const handleCancel = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
  };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Box mb={2}>
        <Typography
          variant="h5"
          sx={{ fontSize: 14, fontWeight: 400, color: "#666666" }}
        >
          * Marked fields are required
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            variant="outlined"
            // sx={{
            //   '& .MuiInputBase-input.Mui-disabled': {

            //       color: '#a3a3a3',

            //   }
            // }}
            type="text"
            label={
              <span>
                Name (As per SSC/HSC Marksheet)
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter name"
            {...register("candidatename", {
              required: "Name is required",
              validate: handleNameValidation,
            })}
            disabled
            onInput={AlphabetsValidation}
            InputLabelProps={{
              shrink: !!watch("candidatename")?.trim(),
            }}
          />
          {/* {errors.candidatename && (
            <small className="error">{errors.candidatename.message}</small>
          )} */}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            label={
              <span>
                DOB
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Click to set date"
            InputLabelProps={{
              shrink: !!watch("dob"),
            }}
            type="date"
            {...register("dob", {
              required: "DOB is required",
            })}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Email
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter email"
            {...register("emailid", {
              required: "Email is required",
              validate: handleEmailValidation,
            })}
            disabled={true}
            InputLabelProps={{
              shrink: !!watch("emailid")?.trim(),
            }}
          />
          {/* {errors.emailid && (
            <small className="error">{errors.emailid.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Mobile No
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter mobile number"
            {...register("mobileno", {
              required: "Mobile number is required",
            })}
            disabled={true}
            InputLabelProps={{
              shrink: !!watch("mobileno")?.trim(),
            }}
          />
          <Box pt={1} sx={{ display: "flex", flexDirection: "column" }}>
            {/* {errors.mobileno && (
              <small className="error">{errors.mobileno.message}</small>
            )} */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            disabled={disabled}
            label={
              <span>
                Father's Full Name
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Father's Full Name"
            {...register("fathername", {
              required: "\u00A0 \u00A0 Father's Name is required",
              // \u00A0 which will act like &nbsp; here
              validate: handleNameValidation,
            })}
            onInput={AlphabetsValidationUppercase}
            error={!!errors.fathername}
            helperText={
              errors.fathername?.message ||
              "Enter a dot (.) In Case of Blank/ Not applicable"
            }
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              shrink: !!watch("fathername")?.trim(),
            }}
          />
          {/* {errors.fathername && (
            <small className="error">{errors.fathername.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
            fullWidth
            type="text"
            variant="outlined"
            label={
              <span>
                Mother's Full Name
                <span className="error" style={{ color: "#666666" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            disabled={disabled}
            placeholder="Mother's Full Name"
            {...register("mothername", {
              required: "\u00A0 \u00A0 Mother's Name is required",
              validate: handleNameValidation,
            })}
            error={!!errors.mothername}
            helperText={
              errors.mothername?.message ||
              "Enter a dot (.) In Case of Blank/ Not applicable"
            }
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            onInput={AlphabetsValidationUppercase}
            InputLabelProps={{
              shrink: !!watch("mothername")?.trim(),
              // shrink: "mothername"?.trim(),
            }}
          />
          {/* {errors.mothername && (
            <small className="error">{errors.mothername.message}</small>
          )} */}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.genderid} variant="outlined">
            <TextField
              select
              label={
                <span>
                  Gender
                  <span style={{ color: "#666666" }}> *</span>
                </span>
              }
              variant="outlined"
              fullWidth
              value={watch("genderid") || ""}
              onChange={(e) => {
                setValue("genderid", e.target.value);
                trigger("genderid");
              }}
              {...register("genderid", { required: "Gender is required" })}
              InputLabelProps={{
                shrink: !!watch("genderid"),
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("genderid");
                  setValue("genderid", e.target.value);
                  trigger("genderid");

                }
              }}
              disabled={disabled}
              error={!!errors.genderid}
              helperText={errors.genderid ? errors.genderid.message : ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {gender?.map((val, i) => (
                <MenuItem value={val?.genderid} key={i}>
                  {val?.gender}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.religionid}>

            <TextField
              label={
                <span>
                  Religion
                  <span style={{ color: "#666666" }}> *</span>
                </span>
              }
              select
              fullWidth
              variant="outlined"
              MenuProps={{
                disableScrollLock: true,
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("religionid");
                  setValue("religionid", e.target.value);
                  trigger("religionid");
                }
              }}
              disabled={disabled}
              {...register("religionid", {
                required: "Religion is required",
              })}
              InputLabelProps={{
                shrink: !!watch("religionid"),
              }}

              error={!!errors.religionid}
              value={watch("religionid") || ""}
              helperText={errors.religionid ? errors.religionid.message : ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {religion?.map((val, i) => (
                <MenuItem value={val?.religionid} key={i}>
                  {val?.religion}
                </MenuItem>
              ))}
            </TextField>

          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.regionid}>
            <TextField

              InputLabelProps={{
                shrink: !!watch("regionid"),
              }}
              label={
                <span>
                  Region
                  <span style={{ color: "#666666" }}> *</span>
                </span>
              }
              select
              fullWidth
              variant="outlined"
              MenuProps={{
                disableScrollLock: true,
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("regionid");
                  setValue("regionid", e.target.value);
                  trigger("regionid");
                }
              }}
              disabled={disabled}
              {...register("regionid", { required: "Region is required" })}


              value={watch("regionid") || ""}
              error={!!errors.regionid}
              helperText={errors.regionid ? errors.regionid.message : ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {region?.map((val, i) => (
                <MenuItem value={val?.regionid} key={i}>
                  {val?.region}
                </MenuItem>
              ))}
            </TextField>

          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.mothertongueid}>

            <TextField
              label={
                <span>
                  Mother Tongue
                  <span style={{ color: "#666666" }}> *</span>
                </span>
              }
              InputLabelProps={{
                shrink: !!watch("mothertongueid"),
              }}
              select
              fullWidth
              variant="outlined"
              MenuProps={{
                disableScrollLock: true,
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("mothertongueid");
                  setValue("mothertongueid", e.target.value);
                  trigger("mothertongueid");
                }
              }}

              disabled={disabled}
              {...register("mothertongueid", {
                required: "Mother Tongue is required",
              })}

              value={watch("mothertongueid") || ""}
              error={!!errors.mothertongueid}
              helperText={errors.mothertongueid ? errors.mothertongueid.message : ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {mothertongue?.map((val, i) => (
                <MenuItem value={val?.mothertongueid} key={i}>
                  {val?.mothertongue}
                </MenuItem>
              ))}
            </TextField>

          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.nationalityid}>
            <InputLabel
              shrink={!!watch("nationalityid")}
              htmlFor="nationalityid"
            >
              Nationality
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select MenuProps={{
              disableScrollLock: true,
            }}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label="Nationality"
              disabled={disabled}
              {...register("nationalityid", {
                required: "Nationality is required",
              })}
              onChange={(e) => {
                setValue("nationalityid", e.target.value);
                trigger("nationalityid");
              }}
              value={watch("nationalityid") || ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {nationality?.map((val, i) => (
                <MenuItem value={val?.nationalityid} key={i}>
                  {val?.nationality}
                </MenuItem>
              ))}
            </Select>
            {errors.nationalityid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.nationalityid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.familyincomeid}>

            <TextField
              label={
                <span>
                  Annual Family Income
                  <span style={{ color: "#666666" }}> *</span>
                </span>
              }
              select
              fullWidth
              variant="outlined"
              MenuProps={{
                disableScrollLock: true,
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("familyincomeid");
                  setValue("familyincomeid", e.target.value);
                  trigger("familyincomeid");
                }
              }}
              disabled={disabled}
              {...register("familyincomeid", {
                required: "Annual Family Income is required",
              })}

              InputLabelProps={{
                shrink: !!watch("familyincomeid"),
              }}
              value={watch("familyincomeid") || ""}
              error={!!errors.familyincomeid}
              helperText={errors.familyincomeid ? errors.familyincomeid.message : ""}
            >
              <MenuItem value="" disabled>Select One</MenuItem>
              {annualIncome?.map((val, i) => (
                <MenuItem value={val?.familyincomeid} key={i}>
                  {val?.incomerange}
                </MenuItem>
              ))}
            </TextField>

          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.maritalstatusid}>
            <InputLabel
              shrink={
                !!watch("maritalstatusid")
              }
              htmlFor="maritalstatusid"
            >
              Marital Status
              <span className="error" style={{ color: "#666666" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select MenuProps={{
              disableScrollLock: true,
            }}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              label={<span>Marital Status</span>}
              disabled={disabled}
              {...register("maritalstatusid", {
                required: "Marital status is required",
                validate: (value) =>
                  (value !== "" && value !== 0) ||
                  "Marital status is required",
              })}
              onChange={handleMaritalStatusChange}
              value={watch("maritalstatusid") ?? ""}
            >
              <MenuItem value="" disabled>
                Select One
              </MenuItem>
              {
                MaritalStatus?.map((val, i) =>
                  (<MenuItem key={i} value={val?.maritalstatusid}> {val?.maritalstatus}</MenuItem>)
                )
              }
            </Select>
            {errors.maritalstatusid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.maritalstatusid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            error={!!errors.maritalstatusid}
            variant="outlined"
          >
            <TextField
              select
              label={
                <span>
                  Marital Status
                  <span className="error" style={{ color: "#666666" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              disabled={disabled}
              variant="outlined"
              fullWidth
              value={watch("maritalstatusid") ?? ""}
              onChange={handleMaritalStatusChange}
              {...register("maritalstatusid", {
                required: "Marital status is required",
                validate: (value) =>
                  (value !== "" && value !== 0) ||
                  "Marital status is required",
              })}
              InputLabelProps={{
                shrink: !!watch("maritalstatusid")
              }}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
                style: { height: "56px", boxSizing: "border-box" },
                onChange: (e) => {
                  clearErrors("maritalstatusid");
                  handleMaritalStatusChange(e);
                }
              }}
              error={!!errors.maritalstatusid}
              helperText={errors.maritalstatusid ? errors.maritalstatusid.message : ""}
            >
              <MenuItem value="" disabled>
                Select One
              </MenuItem>
              {MaritalStatus?.map((val, i) => (
                <MenuItem key={i} value={val?.maritalstatusid}>
                  {val?.maritalstatus}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>


        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!errors.spousename}>
            <TextField
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              fullWidth
              type="text"
              label="Spouse Full Name"
              onInput={AlphabetsValidationUppercase}
              placeholder="Spouse Full Name"
              {...register("spousename", {
                validate: (value) => {
                  if (getValues("maritalstatusid") == 1 && !value?.trim()) {
                    return "\u00A0 \u00A0 Spouse name is required.";
                  }
                  return true;
                },
              })}
              disabled={watch("maritalstatusid") == 0 || watch("maritalstatusid") == 2 || disabled}
              InputLabelProps={{
                shrink: !!watch("spousename")?.trim(),
              }}
              error={!!errors.spousename}
            />
            {errors.spousename && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.spousename.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
          flexDirection: isMobile ? "column" : null,
        }}
      >
        {personalDetails[0]?.status !== 1 &&
          personalDetails[0]?.status !== 2 && (
            <>
              {/* <BackButton
                name={"Clear"}
                handleBack={() => {
                  setDisabled(true);
                  setEditMode(false);
                  handleCancel();
                }}
              /> */}
              <SaveButton
                name={draftButtonLoader ? "loader" : "Save As Draft"}
                handleDraft={() => handleDraft(getValues)}
              />
              <SubmitButton
                name={buttonLoader ? "loader" : "Next"}
                handleSubmit={handleSubmit(handleSave)}
              />
            </>
          )}

        {editMode && (
          <>
            {/* <BackButton
              name={"Clear"}
              handleBack={() => {
                setDisabled(true);
                setEditMode(false);
                handleCancel();
              }}
            /> */}
            <SaveButton
              name={draftButtonLoader ? "loader" : "Save As Draft"}
              handleDraft={() => handleDraft(getValues)}
            />
            <SubmitButton
              name={buttonLoader ? "loader" : "Next"}
              handleSubmit={handleSubmit(handleSave)}
            />
          </>
        )}

        {!editMode && (personalDetails[0]?.status === 1 ||
          personalDetails[0]?.status === 2) && (
            <>
              <SaveButton
                name={"Edit"}
                handleDraft={() => {
                  setDisabled(false);
                  setEditMode(true);
                }}
              />
            </>
          )}
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/action";
import Cookies from "js-cookie";
import {
  AlphabetsValidation,
  cgpaValidation,
  percentageNumberValidation,
  percentageValidation,
} from "../../utils/validations";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../common/backButton";
import SubmitButton from "../../common/submitButton";
import candidatesActions from "../../redux/candidates/actions";
import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import { cssProperties } from "../../utils/commonCssProperties";

export default function AcademicDetailsForm({
  nextPageName,
  previousPageName,
}) {
  console.log(nextPageName);
  const [years, setYears] = useState([]);
  const [hscYears, setHscYears] = useState([]);
  const [graduationYears, setGraduationYears] = useState([]);
  const location = useLocation();
  const courseid = location.state?.courseid;
  const statusForRedirect = location.state?.status;
  const coursename = location.state?.coursename;
  const {
    state,
    board,
    sscSchoolTaluka,
    sscSchoolDistrict,
    hscSchoolDistrict,
    hscSchoolTaluka,
    graduationBranchName,
    awardedClass,
  } = useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  const { courseFullRegistrationDetails, otherDetails, courseFormDetails } =
    useSelector((state) => state.candidatesReducer);
  console.log(otherDetails, "otherdetails");

  //   console.log('sscSchoolDistrict---',sscSchoolDistrict);
  // console.log('sscSchoolTaluka---',sscSchoolTaluka);

  //   console.log('hscSchoolDistrict---',hscSchoolDistrict);
  // console.log('hscSchoolTaluka---',hscSchoolTaluka);
  console.log("awardedClass---", awardedClass);
  console.log("graduationBranchName---", graduationBranchName);

  console.log("masterFields----", masterFields);
  console.log(courseFullRegistrationDetails);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    getValues,
    control,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const date = new Date();
  const currentYear = date.getFullYear();
  const isPaid = courseFormDetails[0]?.ispaid;

  useEffect(() => {
    dispatch({ type: masterActions.GET_STATE });
    dispatch({ type: masterActions.GET_BOARD });
    dispatch({ type: masterActions.GET_AWARDED_CLASS });
    dispatch({ type: masterActions.GET_GRADUATION_BRANCH_NAME });

    const getYears = () => {
      const array = [];
      for (let i = 1945; i <= currentYear; i++) {
        array.push(i);
      }
      return array;
    };
    setYears(getYears());
  }, []);

  // useEffect(() => {
  //   const selectedHscYear = watch("hscpassingyear");
  //   if (selectedHscYear) {
  //     const graduationStartYear = parseInt(selectedHscYear);
  //     const availableGraduationYears = years.filter(
  //       (year) => year >= graduationStartYear
  //     );
  //     setGraduationYears(availableGraduationYears);
  //   }
  //   else {
  //     const graduationStartYear = parseInt(1960);
  //     const availableGraduationYears = years.filter(
  //       (year) => year >= graduationStartYear
  //     );
  //     setGraduationYears(availableGraduationYears);

  //   }
  //   console.log('hscpassingstatus-----', getValues('hscpassingstatus'));
  // }, [watch("hscpassingyear"), years]);

  const handleProcced = (data) => {
    const corcandidateacademicid =
      courseFullRegistrationDetails[0]?.corcandidateacademicid;
    console.log(corcandidateacademicid);
    dispatch({
      type: candidatesActions.COURSE_ACADEMIC_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidateacademicid
            ? parseInt(corcandidateacademicid)
            : 0,
          candidateid: parseInt(candidateid),
          courseid: courseid,
          coursename: coursename,
          status: 1,
          createdby: parseInt(candidateid),
          updatedby: corcandidateacademicid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
        nextPageName: nextPageName,
        statusForRedirect: statusForRedirect,
      },
    });
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };
  console.log(courseFullRegistrationDetails, "courseFullRegistrationDetails");
  useEffect(() => {
    if (
      courseFullRegistrationDetails[0]?.corcandidateacademicid &&
      courseFullRegistrationDetails[0]?.corcandidateacademicid > 0
    ) {
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.sscschoolstateid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.sscschooldistrictid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.hsccollegestateid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.hsccollegedistrictid,
      });
      reset({ ...courseFullRegistrationDetails[0] });

      setValue(
        "sscpassingyear",
        courseFullRegistrationDetails[0]?.sscpassingyear
      );
      setValue(
        "ssctotalpercentage",
        courseFullRegistrationDetails[0]?.ssctotalpercentage
      );
      setValue("sscboardid", courseFullRegistrationDetails[0]?.sscboardid);
      setValue(
        "sscschoolstateid",
        courseFullRegistrationDetails[0]?.sscschoolstateid
      );
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: getValues("sscschoolstateid"),
      });
      setValue("hscboardid", courseFullRegistrationDetails[0]?.hscboardid);
      setValue(
        "hscmarksobtained",
        courseFullRegistrationDetails[0]?.hscmarksobtained
      );
      setValue(
        "hscmarksoutof",
        courseFullRegistrationDetails[0]?.hscmarksoutof
      );
      setValue(
        "hscpassingyear",
        courseFullRegistrationDetails[0]?.hscpassingyear
      );
      setValue(
        "hscpercentage",
        courseFullRegistrationDetails[0]?.hscpercentage
      );
      setValue("hscmarktype", courseFullRegistrationDetails[0]?.marktype);
      setValue(
        "hscpassingstatus",
        courseFullRegistrationDetails[0]?.hscpassingstatus
      );
      trigger("hscpassingyear");
      trigger("graduationpassingyear");
    } else if (otherDetails?.length > 0) {
      reset({ ...otherDetails[0] });

      dispatch({
        type: masterActions.GET_HSC_SCHOOL_DISTRICT,
        payload: otherDetails[0]?.hsccollegestateid,
      });
      dispatch({
        type: masterActions.GET_HSC_SCHOOL_TALUKA,
        payload: otherDetails[0]?.hsccollegedistrictid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: courseFullRegistrationDetails[0]?.sscschoolstateid,
      });
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_TALUKA,
        payload: courseFullRegistrationDetails[0]?.sscschooldistrictid,
      });
      setValue("sscpassingyear", otherDetails[0]?.sscpassingyear);
      setValue("ssctotalpercentage", otherDetails[0]?.ssctotalpercentage);
      setValue("sscboardid", otherDetails[0]?.sscboardid);
      setValue("sscschoolstateid", otherDetails[0]?.sscschoolstateid);
      dispatch({
        type: masterActions.GET_SSC_SCHOOL_DISTRICT,
        payload: getValues("sscschoolstateid"),
      });
      setValue("hscboardid", otherDetails[0]?.hscboardid);
      setValue("hscmarksobtained", otherDetails[0]?.hscmarksobtained);
      setValue("hscmarksoutof", otherDetails[0]?.hscmarksoutof);
      setValue("hscpassingyear", otherDetails[0]?.hscpassingyear);
      setValue("hscpercentage", otherDetails[0]?.hscpercentage);
      setValue("hscmarktype", otherDetails[0]?.marktype);
      setValue("hscpassingstatus", otherDetails[0]?.appearingfor);

      trigger("hscpassingyear");
      trigger("graduationpassingyear");
    }
  }, [courseFullRegistrationDetails, otherDetails]);

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
      dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  // mark percentage calculation

  console.log("hscpassingstatus---", getValues("hscpassingstatus"));
  console.log("hscmarksobtained---", getValues("hscmarksobtained"));
  console.log(watch("graduationmarktype"), "12345");
  console.log(getValues("hscpassingyear"), "12344");
  console.log(getValues("graduationpassingyear"), "12344");

  return (
    <Grid
      p={3}
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      {masterFields?.find(
        (field) => field.masterfieldlabel === masterFieldLabel.SSC_PASSING_YEAR
      ) && (
          <Grid item xs={12} md={12}>
            <Typography
              pt={1}
              pb={1}
              sx={{
                fontSize: "24px",
                color: `${cssProperties?.color?.tertiary}`,
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              {" "}
              SSC INFORMATION
            </Typography>
          </Grid>
        )}
      <Grid container spacing={2}>
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSING_YEAR
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscpassingyear}>
                <InputLabel
                  shrink={!!watch("sscpassingyear")}
                  htmlFor="sscpassingyear"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_PASSING_YEAR
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSING_YEAR
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscpassingyear"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSING_YEAR
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscpassingyear", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSING_YEAR
                      )?.ismandatory === "1"
                        ? "SSC Passing Year is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("sscpassingyear", e.target.value);
                    trigger("sscpassingyear");
                  }}
                  value={watch("sscpassingyear") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_PASSING_YEAR,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {years?.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {errors.sscpassingyear && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscpassingyear.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_TOTAL_PERCENTAGE
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                fullWidth
                variant="outlined"
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_TOTAL_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_TOTAL_PERCENTAGE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="SSC/Equivalent Total Percentage*"
                {...register("ssctotalpercentage", {
                  required:
                    getValues("hscpassingstatus") != 1 ||
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_TOTAL_PERCENTAGE
                      )?.ismandatory != "1"
                      ? false
                      : "SSC Total percentage is required",
                  // validate: percentageValidation,
                })}
                onInput={percentageNumberValidation}
                value={
                  parseFloat(getValues("ssctotalpercentage")) > 100
                    ? 100
                    : getValues("ssctotalpercentage")
                }
                error={!!errors.ssctotalpercentage}
                helperText={errors.ssctotalpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("ssctotalpercentage"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SSC_TOTAL_PERCENTAGE,
                  null,
                  isPaid
                )}
              />
              <Box>
                <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small>
              </Box>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_BOARD
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscboardid}>
                <InputLabel
                  shrink={!!watch("sscboardid") || watch("sscboardid") === 0}
                  htmlFor="sscboardid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscboardid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_BOARD
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscboardid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.SSC_BOARD
                      )?.ismandatory === "1"
                        ? "SSC Board is required"
                        : false,
                  })}
                  //   onChange={(e) => {
                  //     const id = e.target.value;
                  //     const filterId = board?.find(
                  //       (row) => row.boardid === parseInt(id)
                  //     );
                  //     const finalId = filterId.boardstateid;
                  //     setValue("sscschoolstateid", finalId);
                  //   }}
                  value={watch("sscboardid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_BOARD,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {board?.map((val, i) => (
                    <MenuItem value={val?.boardid} key={i}>
                      {val?.board}
                    </MenuItem>
                  ))}
                </Select>
                {errors.sscboardid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscboardid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_STATE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscschoolstateid}>
                <InputLabel
                  shrink={!!watch("sscschoolstateid")}
                  htmlFor="sscschoolstateid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_STATE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_STATE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscschoolstateid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_SCHOOL_STATE
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_STATE
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscschoolstateid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_STATE
                      )?.ismandatory === "1"
                        ? "SSC School State is required"
                        : false,
                  })}
                  onChange={(e) => {
                    const stateId = e.target.value;
                    console.log("sscstateId", stateId);
                    setValue("sscschoolstateid", e.target.value);
                    setValue("sscschooltalukaid", "");
                    setValue("sscschooldistrictid", "");

                    dispatch({
                      type: masterActions.GET_SSC_SCHOOL_DISTRICT,
                      payload: stateId,
                    });

                    trigger("sscschoolstateid");
                  }}
                  value={watch("sscschoolstateid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_STATE,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {state?.map((val, i) => (
                    <MenuItem value={val?.stateid} key={i}>
                      {val?.state}
                    </MenuItem>
                  ))}
                </Select>
                {errors.sscschoolstateid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscschoolstateid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_DISTRICT
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscschooldistrictid}>
                <InputLabel
                  shrink={
                    !!watch("sscschooldistrictid") ||
                    watch("sscschooldistrictid") === 0
                  }
                  htmlFor="sscschooldistrictid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_DISTRICT
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_DISTRICT
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscschooldistrictid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_SCHOOL_DISTRICT
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_DISTRICT
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscschooldistrictid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_DISTRICT
                      )?.ismandatory === "1"
                        ? "SSC School District is required"
                        : false,
                  })}
                  onChange={(e) => {
                    const districtId = e.target.value;
                    setValue("sscschooldistrictid", e.target.value);
                    dispatch({
                      type: masterActions.GET_SSC_SCHOOL_TALUKA,
                      payload: districtId,
                    });

                    trigger("sscschooldistrictid");
                  }}
                  value={watch("sscschooldistrictid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_DISTRICT,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {sscSchoolDistrict?.map((val, i) => (
                    <MenuItem key={i} value={val?.districtid}>
                      {val?.district}
                    </MenuItem>
                  ))}
                </Select>
                {errors.sscschooldistrictid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscschooldistrictid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_TALUKA
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscschooltalukaid}>
                <InputLabel
                  shrink={
                    !!watch("sscschooltalukaid") ||
                    watch("sscschooltalukaid") === 0
                  }
                  htmlFor="sscschooltalukaid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_TALUKA
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_SCHOOL_TALUKA
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscschooltalukaid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_SCHOOL_TALUKA
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_TALUKA
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscschooltalukaid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_TALUKA
                      )?.ismandatory === "1"
                        ? "SSC School Taluka is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("sscschooltalukaid", e.target.value);
                    trigger("sscschooltalukaid");
                  }}
                  value={watch("sscschooltalukaid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_SCHOOL_TALUKA,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {sscSchoolTaluka?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
                </Select>
                {errors.sscschooltalukaid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscschooltalukaid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SSC_PASSED_FROM_INDIA
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.sscpassedfromindia}>
                <InputLabel
                  shrink={
                    !!watch("sscpassedfromindia") ||
                    watch("sscpassedfromindia") === 0
                  }
                  htmlFor="sscpassedfromindia"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_PASSED_FROM_INDIA
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SSC_PASSED_FROM_INDIA
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="sscpassedfromindia"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.SSC_PASSED_FROM_INDIA
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSED_FROM_INDIA
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("sscpassedfromindia", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_PASSED_FROM_INDIA
                      )?.ismandatory === "1"
                        ? "SSC Passed From India is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("sscpassedfromindia", e.target.value);
                    trigger("sscpassedfromindia");
                  }}
                  value={watch("sscpassedfromindia") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.SSC_PASSED_FROM_INDIA,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
                {errors.sscpassedfromindia && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.sscpassedfromindia.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SSC_SCHOOL_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SSC_SCHOOL_NAME
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_NAME
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="SSC SCHOOL NAME*"
                {...register("sscschoolname", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SSC_SCHOOL_NAME
                    )?.ismandatory === "1"
                      ? "SSC School Name is required"
                      : false,
                })}
                // onInput={percentageNumberValidation}
                value={getValues("sscschoolname")}
                error={!!errors.sscschoolname}
                helperText={errors.sscschoolname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("sscschoolname"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SSC_SCHOOL_NAME,
                  null,
                  isPaid
                )}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPEARING_FOR_HSC
        ) && (
            <Grid item xs={12} md={12}>
              <Typography
                pt={1}
                sx={{
                  fontSize: "24px",
                  color: `${cssProperties?.color?.tertiary}`,
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {" "}
                HSC INFORMATION{" "}
              </Typography>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.APPEARING_FOR_HSC
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.isappeared}>
                <InputLabel
                  shrink={
                    !!watch("hscpassingstatus") || watch("hscpassingstatus") === 0
                  }
                  htmlFor="hscpassingstatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.APPEARING_FOR_HSC
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.APPEARING_FOR_HSC
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hscpassingstatus"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.APPEARING_FOR_HSC
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.APPEARING_FOR_HSC
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hscpassingstatus", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.APPEARING_FOR_HSC
                      )?.ismandatory === "1"
                        ? "Select is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("hscpassingstatus", e.target.value);
                    setValue("hscboardid", "");
                    setValue("hscmarksobtained", "");
                    setValue("hscmarksoutof", "");
                    setValue("hscpassingyear", "");
                    setValue("hscpercentage", "");
                    setValue("hscmarktype", "");
                    trigger("hscpassingstatus");
                  }}
                  value={watch("hscpassingstatus") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.APPEARING_FOR_HSC,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
                {errors.hscpassingstatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hscpassingstatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_PASSING_YEAR
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hscpassingyear}>
                <InputLabel
                  shrink={!!watch("hscpassingyear")}
                  htmlFor="hscpassingyear"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_PASSING_YEAR
                    )?.fieldlabel || "HSC passing year"
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_PASSING_YEAR
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>


                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hscpassingyear"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_PASSING_YEAR
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  // {...register("hscpassingyear", {
                  //   required:
                  //     getValues("hscpassingstatus") == 1 ||
                  //       masterFields?.find(
                  //         (field) =>
                  //           field?.masterfieldlabel ===
                  //           masterFieldLabel.HSC_PASSING_YEAR
                  //       )?.ismandatory !== "1"
                  //       ? false
                  //       : " HSC/Diploma Passing Year is required",
                  // })}
                  {...register("hscpassingyear", {
                    required:
                      getValues("hscpassingstatus") == 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.HSC_PASSING_YEAR
                        )?.ismandatory !== "1"
                        ? false
                        : "HSC/Diploma Passing Year is required",
                    validate: (value) => {
                      const sscYear = getValues("sscpassingyear");
                      if (value && sscYear && value < sscYear) {
                        return "HSC Passing Year must not be less than SSC Passing Year";
                      }
                      return true;
                    },
                  })}
                  onChange={(e) => {
                    setValue("hscpassingyear", e.target.value);
                    trigger("hscpassingyear");
                  }}
                  value={watch("hscpassingyear") ?? ""}
                  // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_PASSING_YEAR)}
                  disabled={
                    getValues("hscpassingstatus") == 1
                      ? true
                      : false || isPaid === "1"
                  }
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {
                    // years
                    //   ?.filter((year) => year >= (watch("sscpassingyear") + 2))
                    watch("sscpassingyear") &&
                    Array.from(
                      { length: 2024 - parseInt(watch("sscpassingyear")) + 1 },
                      (v, i) => parseInt(watch("sscpassingyear")) + 2 + i
                    )?.map((year, index) => (
                      <MenuItem key={index} value={year}>
                        {year}
                      </MenuItem>
                    ))
                  }
                </Select>
                {errors.hscpassingyear && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hscpassingyear.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_BOARD
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hscboardid}>
                <InputLabel
                  shrink={!!watch("hscboardid") || watch("hscboardid") === 0}
                  htmlFor="hscboardid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                  )?.ismandatory === "1" && <span className="error"> *</span>}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hscboardid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_BOARD
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hscboardid", {
                    required:
                      getValues("hscpassingstatus") != 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.HSC_BOARD
                        )?.ismandatory != "1"
                        ? false
                        : "HSC/Diploma/Equivalent Board is required",
                  })}
                  value={watch("hscboardid") ?? ""}
                  disabled={isPaid === "1"}
                  error={!!errors?.hscboardid}
                  helperText={errors.hscboardid?.message}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {board?.map((val, i) => (
                    <MenuItem value={val?.boardid} key={i}>
                      {val?.board}
                    </MenuItem>
                  ))}
                </Select>
                {errors.hscboardid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors?.hscboardid?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.HSC_MARKS_TYPE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hscmarktype}>
                <InputLabel
                  shrink={!!watch("hscmarktype") || watch("hscmarktype") === 0}
                  htmlFor="hscmarktype"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_TYPE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.HSC_MARKS_TYPE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hscmarktype"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_MARKS_TYPE
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_TYPE
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hscmarktype", {
                    required:
                      getValues("hscpassingstatus") == 1
                        ? false
                        : "Mark type is required",
                  })}
                  onChange={(e) => {
                    setValue("hscmarktype", e.target.value);
                    trigger("hscmarktype");
                    setValue("hscpercentage", "");
                    setValue("hscmarksobtained", "");
                    setValue("hscmarksoutof", "");
                    const value = parseInt(getValues("hscmarksobtained"));
                    const maxValue = parseInt(getValues("hscmarksoutof"));

                    let percentage = (value / maxValue) * 100;
                    parseInt(e.target.value) === 2 &&
                      setValue(
                        "hscpercentage",
                        !isNaN(percentage)
                          ? parseFloat(percentage).toFixed(2)
                          : ""
                      );
                  }}
                  value={watch("hscmarktype") ?? ""}
                  disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  <MenuItem value={2}>Percentage</MenuItem>
                  <MenuItem value={1}>CGPA</MenuItem>
                </Select>
                {errors.hscmarktype && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hscmarktype.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OBTAINED
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_OBTAINED
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC/Diploma Marks Obtained"
                {...register("hscmarksobtained", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC/Diploma Marks is required",
                  validate: (value) => {
                    if (getValues("hscpassingstatus") == 0) {
                      const outOfValue = parseInt(getValues("hscmarksoutof"));
                      return (
                        parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                        "Marks obtained cannot be greater than marks out of "
                      );
                    }
                  },
                })}
                onInput={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("hscmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("hscmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                inputProps={{
                  min: 0,
                  max:
                    parseInt(getValues("hscmarksoutof")) <= 900
                      ? parseInt(getValues("hscmarksoutof"))
                      : 900,
                }}
                error={!!errors.hscmarksobtained}
                helperText={errors.hscmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: true, // Explicitly set to true
                }}
                disabled={watch("hscpassingstatus") == 1 || isPaid === "1" || watch("hscmarktype") === ""}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_MARKS_OUT_OF
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_MARKS_OUT_OF
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC/Diploma Marks Out Of*"
                {...register("hscmarksoutof", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC/Diploma Marks Out Of is required",
                })}
                onInput={percentageNumberValidation}
                // value={}
                onChange={(e) => {
                  const maxValue = parseInt(e.target.value);
                  const value = parseInt(getValues("hscmarksobtained"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscmarksoutof", e.target.value);
                  // if (e.target.value < value) {
                  //   setValue("hscmarksobtained", e.target.value);
                  // }
                  // if(e.target.value < watch("hscmarksobtained") ){
                  //   setValue("hscmarksoutof", watch("hscmarksobtained"));
                  // }
                  trigger("hscmarksobtained");

                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                error={!!errors?.hscmarksoutof}
                helperText={errors.hscmarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscmarksoutof"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_MARKS_OUT_OF)}
                disabled={
                  getValues("hscpassingstatus") == 1
                    ? true
                    : false || isPaid === "1" || watch("hscmarktype") === ""
                }
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.HSC_CALCULATED_PERCENTAGE
        ) && (
            <Grid item xs={12} md={6}>
              {/* <TextField
              fullWidth
              variant="outlined"
              inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
              type="text"
              label={
                <span> {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_CALCULATED_PERCENTAGE
                  )?.fieldlabel
                }
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC/Diploma Calculated/Equivalent Percentage*"
                {...register("hscpercentage", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC/Diploma Calculated/Equivalent percentage is required",
                      validate: parseInt( watch("hscmarktype")) === 1 ? cgpaValidation : percentageValidation,
                })}
                error={!!errors?.hscpercentage}
                helperText={errors?.hscpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscpercentage"),
                }}
                disabled={(watch("hscmarktype") != 1 )}
              /> */}
              <TextField
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                  inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                  pattern: "[0-9]*",
                  onKeyDown: (e) => {
                    if (e.key === "-" || e.key === "e" || e.key === "E") {
                      e.preventDefault(); // Prevent negative signs and exponential inputs
                    }
                  },
                  onInput: (e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0; // Ensure value doesn't go below 0
                    }
                  },
                }}
                fullWidth
                disabled={watch("hscmarktype") != 1}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                variant="outlined"
                type="number" // Allows both CGPA and Percentage to accept decimal numbers
                min="0"
                label={
                  <span>
                    {"HSC / Diploma Percentage/CGPA"}{" "}
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                placeholder={
                  watch("hscmarktype") === 1
                    ? "Enter the CGPA"
                    : "Enter the HSC Total percentage"
                }
                {...register("hscpercentage", {
                  required:
                    parseInt(watch("hscpassingstatus")) === 0
                      ? watch("hscmarktype") === 1
                        ? "CGPA is required"
                        : "HSC / Diploma Total percentage % is required"
                      : false,
                  validate:
                    watch("hscmarktype") === 1
                      ? cgpaValidation
                      : percentageValidation,
                })}
                error={!!errors.hscpercentage}
                helperText={errors.hscpercentage?.message}
                FormHelperTextProps={{ style: { margin: 0 } }}
                InputLabelProps={{ shrink: !!watch("hscpercentage") }}
                InputProps={{ inputMode: "decimal" }} // Specifies decimal input mode
              />

              <Box>
                <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small>
              </Box>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_STATE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hsccollegestateid}>
                <InputLabel
                  shrink={!!watch("hsccollegestateid")}
                  htmlFor="hsccollegestateid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_STATE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_STATE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hsccollegestateid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_STATE
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_STATE
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hsccollegestateid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_STATE
                      )?.ismandatory === "1"
                        ? "HSC College State is required"
                        : false,
                  })}
                  onChange={(e) => {
                    const stateId = e.target.value;
                    setValue("hsccollegestateid", e.target.value);
                    setValue("hsccollegedistrictid", "");
                    setValue("hsccollegetalukaid", "");

                    dispatch({
                      type: masterActions.GET_HSC_SCHOOL_DISTRICT,
                      payload: stateId,
                    });

                    trigger("hsccollegestateid");
                  }}
                  value={watch("hsccollegestateid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_STATE,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {state?.map((val, i) => (
                    <MenuItem value={val?.stateid} key={i}>
                      {val?.state}
                    </MenuItem>
                  ))}
                </Select>
                {errors.hsccollegestateid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hsccollegestateid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_DISTRICT
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hsccollegedistrictid}>
                <InputLabel
                  shrink={
                    !!watch("hsccollegedistrictid") ||
                    watch("hsccollegedistrictid") === 0
                  }
                  htmlFor="hsccollegedistrictid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_DISTRICT
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_DISTRICT
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hsccollegedistrictid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_DISTRICT
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_DISTRICT
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hsccollegedistrictid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_DISTRICT
                      )?.ismandatory === "1"
                        ? "HSC College District is required"
                        : false,
                  })}
                  onChange={(e) => {
                    const districtId = e?.target?.value;
                    setValue("hsccollegedistrictid", e.target.value);
                    setValue("hsccollegetalukaid", "");

                    dispatch({
                      type: masterActions.GET_HSC_SCHOOL_TALUKA,
                      payload: districtId,
                    });

                    trigger("hsccollegedistrictid");
                  }}
                  value={watch("hsccollegedistrictid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_DISTRICT,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {hscSchoolDistrict?.map((val, i) => (
                    <MenuItem value={val?.districtid} key={i}>
                      {val?.district}
                    </MenuItem>
                  ))}
                </Select>
                {errors.hsccollegedistrictid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hsccollegedistrictid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_TALUKA
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.hsccollegetalukaid}>
                <InputLabel
                  shrink={
                    !!watch("hsccollegetalukaid") ||
                    watch("hsccollegetalukaid") === 0
                  }
                  htmlFor="hsccollegetalukaid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_TALUKA
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.HSC_COLLEGE_TALUKA
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="hsccollegetalukaid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.HSC_COLLEGE_TALUKA
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_TALUKA
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("hsccollegetalukaid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_TALUKA
                      )?.ismandatory === "1"
                        ? "HSC College Taluka is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("hsccollegetalukaid", e.target.value);
                    trigger("hsccollegetalukaid");
                  }}
                  value={watch("hsccollegetalukaid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.HSC_COLLEGE_TALUKA,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  <MenuItem value={0}>Not Applicable</MenuItem>
                  {hscSchoolTaluka?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))}
                </Select>
                {errors.hsccollegetalukaid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.hsccollegetalukaid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_COLLEGE_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_COLLEGE_NAME
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_COLLEGE_NAME
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC COLLEGE NAME*"
                {...register("hsccollegename", {
                  required:
                    "HSC College Name is required",
                })}
                // onInput={percentageNumberValidation}
                value={getValues("hsccollegename")}
                error={!!errors.hsccollegename}
                helperText={errors.hsccollegename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hsccollegename"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.HSC_COLLEGE_NAME,
                  null,
                  isPaid
                )}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OBTAINED
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OBTAINED
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC English Marks Obtained"
                {...register("hscenglishmarksobtained", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Obtained is required",
                  validate: (value) => {
                    if (getValues("hscpassingstatus") == 0 && value > 0) {
                      const outOfValue = parseInt(
                        getValues("hscenglishmarksoutof")
                      );
                      return (
                        parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                        "Marks obtained cannot be greater than marks out of"
                      );
                    }
                  },
                })}
                onInput={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("hscenglishmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscenglishmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscenglishpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("hscenglishmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscenglishmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscenglishpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                inputProps={{
                  min: 0,
                  max:
                    parseInt(getValues("hscenglishmarksoutof")) <= 900
                      ? parseInt(getValues("hscenglishmarksoutof"))
                      : 900,
                  style: { height: 56, boxSizing: "border-box" },
                }}
                error={!!errors.hscenglishmarksobtained}
                helperText={errors.hscenglishmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: true, // Explicitly set to true
                }}
                disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.HSC_ENG_MARKS_OUT_OF
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_MARKS_OUT_OF
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC English Marks Out Of*"
                {...register("hscenglishmarksoutof", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Marks Out Of is required",
                })}
                onInput={percentageNumberValidation}
                onChange={(e) => {
                  const maxValue = parseInt(e.target.value);
                  const value = parseInt(getValues("hscenglishmarksobtained"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("hscenglishmarksoutof", e.target.value);

                  // if (e.target.value < value) {
                  //   setValue("hscenglishmarksobtained", e.target.value);
                  // }
                  // if (e.target.value > watch("hscenglishmarksobtained")) {
                  //   setValue(
                  //     "hscenglishmarksoutof",
                  //     watch("hscenglishmarksobtained")
                  //   );
                  // }
                  trigger("hscenglishmarksobtained");

                  let percentage = (value / maxValue) * 100;
                  watch("hscmarktype") == 2 &&
                    setValue(
                      "hscenglishpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                // value={}
                error={!!errors?.hscenglishmarksoutof}
                helperText={errors.hscenglishmarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishmarksoutof"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.HSC_ENG_MARKS_OUT_OF)}
                disabled={
                  getValues("hscpassingstatus") == 1
                    ? true
                    : false || isPaid === "1"
                }
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.HSC_ENG_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="HSC English Calculated/Equivalent Percentage*"
                {...register("hscenglishpercentage", {
                  required:
                    getValues("hscpassingstatus") == 1
                      ? false
                      : "HSC English Calculated/Equivalent percentage is required",
                })}
                error={!!errors?.hscenglishpercentage}
                helperText={errors?.hscenglishpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("hscenglishpercentage"),
                }}
                disabled={getValues("hscpassingstatus") == 1 || isPaid === "1"}
              />
              <Box>
                <small style={{ color: "#666666" }}>
                  Up to 2 decimal places are allowed
                </small>
              </Box>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ELIGIBLITY_QUALIFICATION
            || field.masterfieldlabel === masterFieldLabel.GRADUATION_PASSING_YEAR
        ) && (
            <Grid item xs={12} md={12}>
              <Typography
                pt={1}
                sx={{
                  fontSize: "24px",
                  color: `${cssProperties?.color?.tertiary}`,
                  fontWeight: 500,
                  lineHeight: "30px",
                }}
              >
                {" "}
                GRADUATION INFORMATION{" "}
              </Typography>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.ELIGIBLITY_QUALIFICATION
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                error={!!errors.eligibilityqualification}
              >
                <InputLabel
                  shrink={
                    !!watch("eligibilityqualification") ||
                    watch("eligibilityqualification") === 0
                  }
                  htmlFor="eligibilityqualification"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ELIGIBLITY_QUALIFICATION
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.ELIGIBLITY_QUALIFICATION
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="eligibilityqualification"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ELIGIBLITY_QUALIFICATION
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ELIGIBLITY_QUALIFICATION
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("eligibilityqualification", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ELIGIBLITY_QUALIFICATION
                      )?.ismandatory === "1"
                        ? "Eligibility Qualification is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("eligibilityqualification", e.target.value);
                    trigger("eligibilityqualification");
                  }}
                  value={watch("eligibilityqualification") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.ELIGIBLITY_QUALIFICATION,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  {/* <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem> */}
                  <MenuItem value={1} key={1}>
                    Graduation
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Post Graduation
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.eligibilityqualification && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.eligibilityqualification.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_STATUS
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                error={!!errors.graduationstatus}
              >
                <InputLabel
                  shrink={
                    !!watch("graduationstatus") || watch("graduationstatus") === 0
                  }
                  htmlFor="graduationstatus"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_STATUS
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_STATUS
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="graduationstatus"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_STATUS
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_STATUS
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("graduationstatus", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_STATUS
                      )?.ismandatory === "1"
                        ? "Graduation Status is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("graduationstatus", e.target.value);
                    trigger("graduationstatus");
                    setValue("graduationpassingyear", "");
                    setValue("graduationbranchid", "");
                    setValue("graduationmarktype", "");
                    setValue("graduationpercentage", "");
                    setValue("awardedclassid", "");
                    setValue("graduationmarksobtained", "");
                    setValue("graduationmarksoutof", "");
                    setValue("graduationduration", "");
                  }}
                  value={watch("graduationstatus") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_STATUS,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  {/* <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem> */}
                  <MenuItem value={1} key={1}>
                    Appearing
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    Passed
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.graduationstatus && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.graduationstatus.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_PASSING_YEAR
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                error={!!errors.graduationpassingyear}
              >
                <InputLabel
                  shrink={!!watch("graduationpassingyear")}
                  htmlFor="graduationpassingyear"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_PASSING_YEAR
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_PASSING_YEAR
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="graduationpassingyear"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_PASSING_YEAR
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_PASSING_YEAR
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("graduationpassingyear", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.GRADUATION_PASSING_YEAR,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_PASSING_YEAR
                        )?.ismandatory === "1"
                        ? "Graduation Passing Year is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("graduationpassingyear", e.target.value);
                    trigger("graduationpassingyear");
                  }}
                  value={watch("graduationpassingyear") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_PASSING_YEAR,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {graduationYears?.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))} */}
                  {/* {  console.log( watch("hscpassingyear"),'console.log( watch("hscpassingyear"))',Array.from(
                        {
                          length: 2024 - parseInt(watch("hscpassingyear")) + 1,
                        },
                        (v, i) => parseInt(watch("hscpassingyear")) + 2 + i
                      ))} */}
                  {
                    // years
                    // ?.filter((year) => year >= (watch("hscpassingyear") + 3))

                    watch("hscpassingyear")
                      ? Array.from(
                        {
                          length: 2024 - parseInt(watch("hscpassingyear")) + 1,
                        },
                        (v, i) => parseInt(watch("hscpassingyear")) + 2 + i
                      )
                        .map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))
                      : Array.from(
                        {
                          length: 2024 - parseInt(watch("sscpassingyear")) + 1,
                        },
                        (v, i) => parseInt(watch("sscpassingyear")) + 4 + i
                      )
                        .map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))
                  }
                </Select>
                {errors.graduationpassingyear && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.graduationpassingyear.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_BRANCH_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.graduationbranchid}>
                <InputLabel
                  shrink={!!watch("graduationbranchid")}
                  htmlFor="graduationbranchid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_BRANCH_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_BRANCH_NAME
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="graduationbranchid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_BRANCH_NAME
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_BRANCH_NAME
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("graduationbranchid", {
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_BRANCH_NAME
                      )?.ismandatory === "1"
                        ? "Graduation Branch Name is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("graduationbranchid", e.target.value);
                    trigger("graduationbranchid");
                  }}
                  value={watch("graduationbranchid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_BRANCH_NAME,
                    null,
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  {graduationBranchName?.map((val, i) => (
                    <MenuItem key={i} value={val?.graduationbranchid}>
                      {val?.graduationbranch}
                    </MenuItem>
                  ))}
                </Select>
                {errors.graduationbranchid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.graduationbranchid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_TYPE
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                error={!!errors.graduationmarktype}
              >
                <InputLabel
                  shrink={
                    !!watch("graduationmarktype") ||
                    watch("graduationmarktype") === 0
                  }
                  htmlFor="graduationmarktype"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_TYPE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_MARK_TYPE
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  id="graduationmarktype"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_MARK_TYPE
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_TYPE
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  // {...register("graduationmarktype", {
                  //   required:
                  //     !isFieldDisabled(
                  //       masterFields,
                  //       masterFieldLabel.GRADUATION_MARK_TYPE,
                  //       watch("graduationstatus")
                  //     ) &&
                  //       masterFields?.find(
                  //         (field) =>
                  //           field?.masterfieldlabel ===
                  //           masterFieldLabel.GRADUATION_MARK_TYPE
                  //       )?.ismandatory === "1"
                  //       ? "Graduation Marks Type is required"
                  //       : false,
                  // })}
                  {...register("graduationmarktype", {
                    required:
                      getValues("graduationstatus") == 1
                        ? false
                        : "Graduation Marks Type is required",
                  })}
                  onChange={(e) => {
                    setValue("graduationpercentage", "");
                    setValue("graduationmarktype", e.target.value);
                    trigger("graduationmarktype");
                    const value = parseInt(getValues("graduationmarksobtained"));
                    const maxValue = parseInt(getValues("graduationmarksoutof"));
                    setValue("graduationpercentage", "");
                    setValue("graduationmarksoutof", "");
                    setValue("graduationmarksobtained", "");
                    // let percentage = (value / maxValue) * 100;
                    // parseInt(e.target.value) === 1 &&
                    //   setValue(
                    //     "graduationpercentage",
                    //     !isNaN(percentage)
                    //       ? parseFloat(percentage).toFixed(2)
                    //       : ""
                    //   );
                  }}
                  value={watch("graduationmarktype") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_MARK_TYPE,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  {/* <MenuItem value="">Select One</MenuItem> */}
                  {/* {state?.map((val, i) => ( */}
                  {/* <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem> */}
                  <MenuItem value={1} key={1}>
                    Percentage
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    CGPA
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.graduationmarktype && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.graduationmarktype.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OBTAINED
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_OBTAINED
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_OBTAINED
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Graduation Marks Obtained"
                {...register("graduationmarksobtained", {
                  required:
                    getValues("graduationstatus") === 1
                      ? // ||
                      // watch("graduationmarksobtained") !== 0
                      false
                      : "Graduation Marks Obtained is required",
                  validate: (value) => {
                    if (
                      isFieldDisabled(
                        masterFields,
                        masterFieldLabel.GRADUATION_MARK_OBTAINED,
                        watch("graduationstatus")
                      )
                    ) {
                      return true;
                    }

                    if (
                      getValues("graduationstatus") !== 0 &&
                      value &&
                      value?.toISOString?.trim() !== ""
                    ) {
                      const outOfValue = parseInt(
                        getValues("graduationmarksoutof")
                      );
                      return (
                        parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                        "Marks obtained cannot be greater than marks out of or 900"
                      );
                    }
                    return true;
                  },
                })}
                onInput={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("graduationmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("graduationmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("graduationmarktype") === 1 &&
                    setValue(
                      "graduationpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  const maxValue = parseInt(getValues("graduationmarksoutof"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  setValue("graduationmarksobtained", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("graduationmarktype") === 1 &&
                    setValue(
                      "graduationpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                value={watch("graduationmarksobtained") ?? ""}
                inputProps={{
                  min: 0,
                  max:
                    parseInt(getValues("graduationmarksoutof")) <= 900
                      ? parseInt(getValues("graduationmarksoutof"))
                      : 900,
                  style: { height: 56, boxSizing: "border-box" },
                }}
                error={!!errors.graduationmarksobtained}
                helperText={errors.graduationmarksobtained?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: true, // Explicitly set to true
                }}
                disabled={getValues("graduationstatus") == 1 || isPaid === "1" || watch("graduationmarktype") === ""}
              // disabled={getValues("graduationstatus") == 1 || isPaid === "1"}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_MARK_OUT_OF
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_OUT_OF
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_OUT_OF
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Graduation Marks Out Of*"
                // {...register("graduationmarksoutof", {
                //   required:
                //     !isFieldDisabled(
                //       masterFields,
                //       masterFieldLabel.GRADUATION_MARK_OUT_OF,
                //       watch("graduationstatus")
                //     ) &&
                //       masterFields?.find(
                //         (field) =>
                //           field?.masterfieldlabel ===
                //           masterFieldLabel.GRADUATION_MARK_OUT_OF
                //       )?.ismandatory === "1"
                //       ? "Graduation Marks Out Of is required"
                //       : false,
                // })}
                {...register("graduationmarksoutof", {
                  required:
                    getValues("graduationstatus") == 1
                      ? false
                      : "Graduation Marks Out Of is required",
                })}
                onInput={percentageNumberValidation}
                onChange={(e) => {
                  const maxValue = parseInt(e.target.value);
                  const value = parseInt(getValues("graduationmarksobtained"));
                  const maxAllowed = maxValue <= 900 ? maxValue : 900;
                  if (value > maxAllowed) {
                    e.target.value = maxAllowed;
                  }
                  // if (e.target.value < value) {
                  //   setValue("graduationmarksobtained", e.target.value);
                  // }
                  // if(e.target.value > watch("graduationmarksobtained") ){
                  //   setValue("graduationmarksoutof", watch("graduationmarksobtained"));
                  // }
                  setValue("graduationmarksoutof", e.target.value);
                  let percentage = (value / maxValue) * 100;
                  watch("graduationmarktype") === 1 &&
                    setValue(
                      "graduationpercentage",
                      !isNaN(percentage) ? parseFloat(percentage).toFixed(2) : ""
                    );
                }}
                // value={}
                error={!!errors?.graduationmarksoutof}
                helperText={errors.graduationmarksoutof?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("graduationmarksoutof"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_OUT_OF)}
                // disabled={getValues("hscpassingstatus") == 1 ? true : false}
                disabled={getValues("graduationstatus") == 1 || isPaid === "1" || watch("graduationmarktype") === ""}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{
                  style: { height: 56, boxSizing: "border-box" },
                  inputMode: "numeric", // Helps mobile keyboards show numeric keypad
                  pattern: "[0-9]*",
                  onKeyDown: (e) => {
                    if (e.key === "-" || e.key === "e" || e.key === "E") {
                      e.preventDefault(); // Prevent negative signs and exponential inputs
                    }
                  },
                  onInput: (e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0; // Ensure value doesn't go below 0
                    }
                  },
                }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Graduation Marks Percentage"
                // {...register("graduationpercentage", {
                //   required:
                //     !isFieldDisabled(
                //       masterFields,
                //       masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE,
                //       watch("graduationstatus")
                //     ) &&
                //       masterFields?.find(
                //         (field) =>
                //           field?.masterfieldlabel ===
                //           masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE
                //       )?.ismandatory === "1"
                //       ? "Graduation Percentage is required"
                //       : false,
                // })}
                {...register("graduationpercentage", {
                  required:
                    parseInt(watch("graduationstatus")) === 0
                      ? watch("graduationmarktype") === 2
                        ? "CGPA is required"
                        : "Graduation percentage % is required"
                      : false,
                  validate:
                    watch("graduationmarktype") === 2
                      ? cgpaValidation
                      : percentageValidation,
                })}
                onInput={percentageNumberValidation}
                // value={}
                error={!!errors?.graduationpercentage}
                helperText={errors.graduationpercentage?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("graduationpercentage"),
                }}
                // disabled={isFieldDisabled(masterFields, masterFieldLabel.GRADUATION_MARK_CALCULATED_PERCENTAGE)}
                // disabled={getValues("hscpassingstatus") == 1 ? true : false}
                disabled={parseInt(watch("graduationmarktype")) !== 2}
              // disabled={getValues("graduationstatus") == 1 || isPaid === "1"}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.AWARDED_CLASS_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.awardedclassid}>
                <InputLabel
                  shrink={!!watch("awardedclassid")}
                  htmlFor="awardedclassid"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.AWARDED_CLASS_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.AWARDED_CLASS_NAME
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="awardedclassid"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.AWARDED_CLASS_NAME
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.AWARDED_CLASS_NAME
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("awardedclassid", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.AWARDED_CLASS_NAME,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.AWARDED_CLASS_NAME
                        )?.ismandatory === "1"
                        ? "Awarded Class Name is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("awardedclassid", e.target.value);
                    trigger("awardedclassid");
                  }}
                  value={watch("awardedclassid") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.AWARDED_CLASS_NAME,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {awardedClass?.map((val, i) => (
                    <MenuItem key={i} value={val?.awardedclassid}>
                      {val?.awardedclass}
                    </MenuItem>
                  ))}
                </Select>
                {errors.awardedclassid && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.awardedclassid.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.GRADUATION_DURATION
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.awardedclassid}>
                <InputLabel
                  shrink={!!watch("graduationduration")}
                  htmlFor="graduationduration"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.GRADUATION_DURATION
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.GRADUATION_DURATION
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="graduationduration"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.GRADUATION_DURATION
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.GRADUATION_DURATION
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("graduationduration", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.GRADUATION_DURATION,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.GRADUATION_DURATION
                        )?.ismandatory === "1"
                        ? "Graduation Duration is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("graduationduration", e.target.value);
                    trigger("graduationduration");
                  }}
                  value={watch("graduationduration") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.GRADUATION_DURATION,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  {Array.from({ length: 8 }, (_, i) => i + 3).map((value) => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1} key={1}>
                  3
                </MenuItem>
                <MenuItem value={2} key={2}>
                  4
                </MenuItem>
                <MenuItem value={3} key={3}>
                  5
                </MenuItem>

                <MenuItem value={4} key={4}>
                  6
                </MenuItem> */}

                  {/* ))} */}
                </Select>
                {errors.graduationduration && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.graduationduration.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {/* <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            POST GRADUATION INFORMATION
          </Typography>
        </Grid> */}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.DOCUMENT_FOR_TYPE_A
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.documentfortypea}>
                <InputLabel
                  shrink={!!watch("documentfortypea")}
                  htmlFor="documentfortypea"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.DOCUMENT_FOR_TYPE_A
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="documentfortypea"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.DOCUMENT_FOR_TYPE_A
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DOCUMENT_FOR_TYPE_A
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("documentfortypea", {
                    required: !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.DOCUMENT_FOR_TYPE_A,
                      watch("graduationstatus")
                    )
                      ? masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.DOCUMENT_FOR_TYPE_A
                      )?.ismandatory === "1"
                        ? "Document for Type A is required"
                        : false
                      : false,
                  })}
                  onChange={(e) => {
                    setValue("documentfortypea", e.target.value);
                    trigger("documentfortypea");
                  }}
                  value={watch("documentfortypea") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.DOCUMENT_FOR_TYPE_A,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>

                  <MenuItem value={1} key={1}>
                    A
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    B
                  </MenuItem>

                  <MenuItem value={1} key={1}>
                    C
                  </MenuItem>
                </Select>
                {errors.documentfortypea && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.documentfortypea.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.documentof}>
                <InputLabel shrink={!!watch("documentof")} htmlFor="documentof">
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="documentof"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.DOCUMENT_OF
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("documentof", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.DOCUMENT_OF,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel === masterFieldLabel.DOCUMENT_OF
                        )?.ismandatory === "1"
                        ? "Document of is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("documentof", e.target.value);
                    trigger("documentof");
                  }}
                  value={watch("documentof") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.DOCUMENT_OF,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>
                    Select One
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    type 1
                  </MenuItem>

                  <MenuItem value={1} key={1}>
                    type 2
                  </MenuItem>
                </Select>
                {errors.documentof && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.documentof.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
        {/*     
{masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CASTE_NAME_FOR_OPEN
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              
              error={!!errors.castenameforopen}
            >
              <InputLabel
                shrink={!!watch("castenameforopen")}
                htmlFor="castenameforopen"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CASTE_NAME_FOR_OPEN
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                id="castenameforopen"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CASTE_NAME_FOR_OPEN
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CASTE_NAME_FOR_OPEN
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("castenameforopen", {
                  required: masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CASTE_NAME_FOR_OPEN
                  )?.ismandatory === "1" ? "SSC School State is required" : false,
                })}
                onChange={(e) => {
                  setValue("castenameforopen", e.target.value);
                  trigger("castenameforopen");
                }}
                value={watch("castenameforopen") ?? ""}
                disabled={isFieldDisabled(masterFields, masterFieldLabel.CASTE_NAME_FOR_OPEN)}
              >
                <MenuItem value="">Select One</MenuItem>
                
              </Select>
              {errors.castenameforopen && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.castenameforopen.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
     */}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.cetexamname}>
                <InputLabel shrink={!!watch("cetexamname")} htmlFor="cetexamname">
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.CET_EXAM_NAME
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="cetexamname"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.CET_EXAM_NAME
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.CET_EXAM_NAME
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("cetexamname", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.CET_EXAM_NAME,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.CET_EXAM_NAME
                        )?.ismandatory === "1"
                        ? "CET Exam Name is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("cetexamname", e.target.value);
                    trigger("cetexamname");
                  }}
                  value={watch("cetexamname") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.CET_EXAM_NAME,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    type 1
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    type 2
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.cetexamname && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.cetexamname.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                error={!!errors.passinstuteonmh}
              >
                <InputLabel
                  shrink={!!watch("passinstuteonmh")}
                  htmlFor="passinstuteonmh"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="passinstuteonmh"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("passinstuteonmh", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH
                        )?.ismandatory === "1"
                        ? "Whether Passed Institute on MH is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("passinstuteonmh", e.target.value);
                    trigger("passinstuteonmh");
                  }}
                  value={watch("passinstuteonmh") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.WHETHER_PASS_INSTUTE_ON_MH,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Yes
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    No
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.passinstuteonmh && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.passinstuteonmh.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.PF_GRADUATION_DATE
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="pfgraduationdate"
                  control={control}
                  rules={{
                    required:
                      getValues("pfgraduationdate") !== 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.PF_GRADUATION_DATE
                        )?.ismandatory !== "1"
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.PF_GRADUATION_DATE
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.PF_GRADUATION_DATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        getValues("pfgraduationdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.pfgraduationdate,
                          helperText: errors.pfgraduationdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.INTERN_TRAINING_DATE
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="internshiptrainingdate"
                  control={control}
                  rules={{
                    required:
                      getValues("internshiptrainingdate") !== 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.INTERN_TRAINING_DATE
                        )?.ismandatory !== "1"
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.INTERN_TRAINING_DATE
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.INTERN_TRAINING_DATE
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        getValues("internshiptrainingdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.internshiptrainingdate,
                          helperText: errors.internshiptrainingdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.MH_COUNCIL_REGISTRATION
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="councilregdate"
                  control={control}
                  rules={{
                    required:
                      getValues("councilregdate") !== 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.MH_COUNCIL_REGISTRATION
                        )?.ismandatory !== "1"
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: "56px", boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.MH_COUNCIL_REGISTRATION
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.MH_COUNCIL_REGISTRATION
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        getValues("councilregdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.councilregdate,
                          helperText: errors.councilregdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.RE_COUNCIL_REGISTRATION
        ) && (
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="recouncilregdate"
                  control={control}
                  rules={{
                    required:
                      getValues("recouncilregdate") !== 1 ||
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.RE_COUNCIL_REGISTRATION
                        )?.ismandatory !== "1"
                        ? false
                        : "PF graduation date is required",
                  }}
                  render={({ field }) => (
                    <DatePicker
                      views={["year", "month", "day"]}
                      maxDate={dayjs()}
                      minDate={dayjs("1900-01-01")}
                      inputProps={{
                        style: { height: 56, boxSizing: "border-box" },
                        readOnly: true,
                      }}
                      {...field}
                      label={
                        <span>
                          {
                            masterFields?.find(
                              (field) =>
                                field?.masterfieldlabel ===
                                masterFieldLabel.RE_COUNCIL_REGISTRATION
                            )?.fieldlabel
                          }
                          {masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.RE_COUNCIL_REGISTRATION
                          )?.ismandatory === "1" && (
                              <span className="error" style={{ color: "red" }}>
                                {" "}
                                *
                              </span>
                            )}
                        </span>
                      }
                      disabled={
                        getValues("recouncilregdate") == 0 ||
                        watch("graduationstatus") == 1 ||
                        isPaid === "1"
                      }
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = dayjs(date)
                            .hour(18)
                            .minute(30)
                            .second(0)
                            .millisecond(0)
                            .toISOString();
                          field.onChange(formattedDate);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.recouncilregdate,
                          helperText: errors.recouncilregdate?.message,
                          FormHelperTextProps: {
                            style: { margin: 0 },
                          },
                          style: {
                            width: "100%",
                            borderRadius: "8px",
                          },
                        },
                        layout: {
                          sx: {
                            borderRadius: "58px",
                          },
                        },
                      }}
                    />

                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.ALREADY_ADMITED
        ) && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                error={!!errors.alreadyadmitted}
              >
                <InputLabel
                  shrink={!!watch("alreadyadmitted")}
                  htmlFor="alreadyadmitted"
                >
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.ALREADY_ADMITED
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel === masterFieldLabel.ALREADY_ADMITED
                  )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                </InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                  id="alreadyadmitted"
                  label={
                    <span>
                      <span>
                        {
                          masterFields?.find(
                            (field) =>
                              field?.masterfieldlabel ===
                              masterFieldLabel.ALREADY_ADMITED
                          )?.fieldlabel
                        }
                      </span>
                      {masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.ALREADY_ADMITED
                      )?.ismandatory === "1" && (
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        )}
                    </span>
                  }
                  {...register("alreadyadmitted", {
                    required:
                      !isFieldDisabled(
                        masterFields,
                        masterFieldLabel.ALREADY_ADMITED,
                        watch("graduationstatus")
                      ) &&
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.ALREADY_ADMITED
                        )?.ismandatory === "1"
                        ? "Already Admitted is required"
                        : false,
                  })}
                  onChange={(e) => {
                    setValue("alreadyadmitted", e.target.value);
                    trigger("alreadyadmitted");
                  }}
                  value={watch("alreadyadmitted") ?? ""}
                  disabled={isFieldDisabled(
                    masterFields,
                    masterFieldLabel.ALREADY_ADMITED,
                    watch("graduationstatus"),
                    isPaid
                  )}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {/* {state?.map((val, i) => ( */}
                  <MenuItem value={0} key={0}>
                    Not Applicable
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    Yes
                  </MenuItem>
                  <MenuItem value={1} key={1}>
                    No
                  </MenuItem>

                  {/* ))} */}
                </Select>
                {errors.alreadyadmitted && (
                  <FormHelperText style={{ margin: 0 }}>
                    {errors.alreadyadmitted.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.G_CollegeName
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.G_CollegeName
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel === masterFieldLabel.G_CollegeName
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter College Name"
                {...register("gcollegename", {
                  required:
                    !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.G_CollegeName,
                      watch("graduationstatus")
                    ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel === masterFieldLabel.G_CollegeName
                      )?.ismandatory === "1"
                      ? "Graduation College Name name is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.gcollegename}
                helperText={errors.gcollegename?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("gcollegename"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.G_CollegeName,
                  watch("graduationstatus"),
                  isPaid
                )}
              />
            </Grid>
          )}

        {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.University_Name
        ) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputProps={{ style: { height: 56, boxSizing: "border-box" } }}
                type="text"
                label={
                  <span>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.University_Name
                      )?.fieldlabel
                    }
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.University_Name
                    )?.ismandatory === "1" && (
                        <span className="error" style={{ color: "red" }}>
                          {" "}
                          *
                        </span>
                      )}
                  </span>
                }
                placeholder="Enter Caste Name"
                {...register("guniversityname", {
                  required:
                    !isFieldDisabled(
                      masterFields,
                      masterFieldLabel.University_Name,
                      watch("graduationstatus")
                    ) &&
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.University_Name
                      )?.ismandatory === "1"
                      ? "Graduation University name is required"
                      : false,
                })}
                // onInput={AlphabetsValidation}
                error={!!errors.guniversityname}
                helperText={errors.guniversityname?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
                InputLabelProps={{
                  shrink: !!watch("guniversityname"),
                }}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.University_Name,
                  watch("graduationstatus"),
                  isPaid
                )}
              />
            </Grid>
          )}

        {/* <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            FIRST YEAR MARKS{" "}
          </Typography>
        </Grid> */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              
              type="number"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_MARKS_OBTAINED
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_MARKS_OBTAINED
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Fy Marks Obtained"
              {...register("fymarksobtained", {
                required:
                  getValues("hscpassingstatus") !== 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_MARKS_OBTAINED
                  )?.ismandatory !== "1"
                    ? false
                    : "FY  Marks is required",
                validate: (value) => {
                  if (getValues("hscpassingstatus") !== 0) {
                    const outOfValue = parseInt(getValues("fymarksoutof"));
                    return (
                      parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                      "Marks obtained cannot be greater than marks out of or 900"
                    );
                  }
                },
              })}
              onInput={(e) => {
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("fymarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
              }}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("fymarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
              }}
              inputProps={{
                min: 0,
                max:
                  parseInt(getValues("fymarksoutof")) <= 900
                    ? parseInt(getValues("fymarksoutof"))
                    : 900,
              }}
              error={!!errors.fymarksobtained}
              helperText={errors.fymarksobtained?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: true, // Explicitly set to true
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.FY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="FY Marks Out Of*"
              {...register("fymarksoutof", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_MARKS_OUT_OF
                  )?.ismandatory != "1"
                    ? false
                    : "FY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.fymarksoutof}
              helperText={errors.fymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("fymarksoutof"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.FY_MARKS_OUT_OF)}
              disabled={getValues("hscpassingstatus") == 0 ? true : false}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_CALCULATED_PERCENTAGE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_CALCULATED_PERCENTAGE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Fy Calculated/Equivalent Percentage*"
              {...register("fypercentage", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_CALCULATED_PERCENTAGE
                  )?.ismandatory != "1"
                    ? false
                    : "Fy Calculated/Equivalent percentage is required",
              })}
              error={!!errors?.fypercentage}
              helperText={errors?.fypercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("fypercentage"),
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.FY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.fynoofattempts}>
              <InputLabel
                shrink={!!watch("fynoofattempts")}
                htmlFor="fynoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.FY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.FY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                id="fynoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.FY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("fynoofattempts", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.FY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1"
                      ? "SSC School State is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("fynoofattempts", e.target.value);
                  trigger("fynoofattempts");
                }}
                value={watch("fynoofattempts") ?? ""}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.FY_NO_OF_ATTEMPTS
                )}
              >
                <MenuItem value="">Select One</MenuItem>
                
                <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem>
                <MenuItem value={1} key={1}>
                  1
                </MenuItem>
                <MenuItem value={1} key={1}>
                  2
                </MenuItem>

                 ))} 
              </Select>
              {errors.fynoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.fynoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            SECOND YEAR MARKS
          </Typography>
        </Grid> */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_MARKS_OBTAINED
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_MARKS_OBTAINED
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Sy Marks Obtained"
              {...register("symarksobtained", {
                required:
                  getValues("hscpassingstatus") !== 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_MARKS_OBTAINED
                  )?.ismandatory !== "1"
                    ? false
                    : "FY  Marks is required",
                validate: (value) => {
                  if (getValues("hscpassingstatus") !== 0) {
                    const outOfValue = parseInt(getValues("symarksoutof"));
                    return (
                      parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                      "Marks obtained cannot be greater than marks out of or 900"
                    );
                  }
                },
              })}
              onInput={(e) => {
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("symarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
              }}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("symarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
              }}
              inputProps={{
                min: 0,
                max:
                  parseInt(getValues("symarksoutof")) <= 900
                    ? parseInt(getValues("symarksoutof"))
                    : 900,
              }}
              error={!!errors.symarksobtained}
              helperText={errors.symarksobtained?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: true, // Explicitly set to true
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.SY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="SY Marks Out Of*"
              {...register("symarksoutof", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_MARKS_OUT_OF
                  )?.ismandatory != "1"
                    ? false
                    : "SY Marks Out Of is required",
              })}
              onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.symarksoutof}
              helperText={errors.symarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("symarksoutof"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.SY_MARKS_OUT_OF)}
              disabled={getValues("hscpassingstatus") == 0 ? true : false}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_CALCULATED_PERCENTAGE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_CALCULATED_PERCENTAGE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Sy Calculated/Equivalent Percentage*"
              {...register("sypercentage", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_CALCULATED_PERCENTAGE
                  )?.ismandatory != "1"
                    ? false
                    : "Fy Calculated/Equivalent percentage is required",
              })}
              error={!!errors?.sypercentage}
              helperText={errors?.sypercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("sypercentage"),
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.SY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.synoofattempts}>
              <InputLabel
                shrink={!!watch("synoofattempts")}
                htmlFor="synoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                id="synoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("synoofattempts", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1"
                      ? "SSC School State is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("synoofattempts", e.target.value);
                  trigger("synoofattempts");
                }}
                value={watch("synoofattempts") ?? ""}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.SY_NO_OF_ATTEMPTS
                )}
              >
                <MenuItem value="">Select One</MenuItem>

                <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem>
                <MenuItem value={1} key={1}>
                  1
                </MenuItem>
                <MenuItem value={1} key={1}>
                  2
                </MenuItem>

              </Select>
              {errors.synoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.synoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={12}>
          <Typography
            pt={1}
            sx={{
              fontSize: "24px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {" "}
            THIRD YEAR MARKS
          </Typography>
        </Grid> */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_MARKS_OBTAINED
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_MARKS_OBTAINED
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_MARKS_OBTAINED
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Ty Marks Obtained"
              {...register("tymarksobtained", {
                required:
                  getValues("hscpassingstatus") !== 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_MARKS_OBTAINED
                  )?.ismandatory !== "1"
                    ? false
                    : "TY  Marks is required",
                validate: (value) => {
                  if (getValues("hscpassingstatus") !== 0) {
                    const outOfValue = parseInt(getValues("symarksoutof"));
                    return (
                      parseInt(value) <=
                        (outOfValue <= 900 ? outOfValue : 900) ||
                      "Marks obtained cannot be greater than marks out of or 900"
                    );
                  }
                },
              })}
              onInput={(e) => {
                const value = parseInt(e.target.value);
                const maxValue = parseInt(getValues("tymarksoutof"));
                const maxAllowed = maxValue <= 900 ? maxValue : 900;
                if (value > maxAllowed) {
                  e.target.value = maxAllowed;
                }
              }}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                // const maxValue = parseInt(getValues("tymarksoutof"));
                // const maxAllowed = maxValue <= 900 ? maxValue : 900;
                // if (value > maxAllowed) {
                //   e.target.value = maxAllowed;
                // }
              }}
              inputProps={{
                min: 0,
                max:
                  parseInt(getValues("tymarksoutof")) <= 900
                    ? parseInt(getValues("tymarksoutof"))
                    : 900,
              }}
              error={!!errors.tymarksobtained}
              helperText={errors.tymarksobtained?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: true, // Explicitly set to true
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) => field.masterfieldlabel === masterFieldLabel.TY_MARKS_OUT_OF
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_MARKS_OUT_OF
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_MARKS_OUT_OF
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="TY Marks Out Of*"
              {...register("tymarksoutof", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_MARKS_OUT_OF
                  )?.ismandatory != "1"
                    ? false
                    : "SY Marks Out Of is required",
              })}
              // onInput={percentageNumberValidation}
              // value={}
              error={!!errors?.tymarksoutof}
              helperText={errors.tymarksoutof?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("tymarksoutof"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.TY_MARKS_OUT_OF)}
              disabled={getValues("hscpassingstatus") == 0 ? true : false}
            />
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_CALCULATED_PERCENTAGE
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_CALCULATED_PERCENTAGE
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_CALCULATED_PERCENTAGE
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Ty Calculated/Equivalent Percentage*"
              {...register("typercentage", {
                required:
                  getValues("hscpassingstatus") != 1 ||
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_CALCULATED_PERCENTAGE
                  )?.ismandatory != "1"
                    ? false
                    : "Fy Calculated/Equivalent percentage is required",
              })}
              error={!!errors?.typercentage}
              helperText={errors?.typercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("typercentage"),
              }}
              disabled={getValues("hscpassingstatus") == 0}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>
        )} */}

        {/* {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.TY_NO_OF_ATTEMPTS
        ) && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" error={!!errors.tynoofattempts}>
              <InputLabel
                shrink={!!watch("tynoofattempts")}
                htmlFor="tynoofattempts"
              >
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.TY_NO_OF_ATTEMPTS
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.TY_NO_OF_ATTEMPTS
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </InputLabel>
              <Select  MenuProps={{
                  disableScrollLock: true,
              }}
                id="tynoofattempts"
                label={
                  <span>
                    <span>
                      {
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.TY_NO_OF_ATTEMPTS
                        )?.fieldlabel
                      }
                    </span>
                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </span>
                }
                {...register("tynoofattempts", {
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.TY_NO_OF_ATTEMPTS
                    )?.ismandatory === "1"
                      ? "SSC School State is required"
                      : false,
                })}
                onChange={(e) => {
                  setValue("tynoofattempts", e.target.value);
                  trigger("tynoofattempts");
                }}
                value={watch("tynoofattempts") ?? ""}
                disabled={isFieldDisabled(
                  masterFields,
                  masterFieldLabel.TY_NO_OF_ATTEMPTS
                )}
              >
                <MenuItem value="">Select One</MenuItem>
               
                <MenuItem value={0} key={0}>
                  Not Applicable
                </MenuItem>
                <MenuItem value={1} key={1}>
                  1
                </MenuItem>
                <MenuItem value={1} key={1}>
                  2
                </MenuItem>

             
              </Select>
              {errors.tynoofattempts && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.tynoofattempts.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )} */}

        {/* <Grid item xs={12} md={12}>
         <Typography
        //     pt={1}
        //     sx={{
        //       fontSize: "24px",
        //       color: `${cssProperties?.color?.tertiary}`,
        //       fontWeight: 500,
        //       lineHeight: "30px",
        //     }}
        //   >
        //     {" "}
        //     FOURTH YEAR MARKS
        //   </Typography>
        // </Grid> */}

        {/* {masterFields?.find(
         (field) =>
           field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        // ) && (
           <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="number"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="Four Y Marks Obtained"
        //       {...register("tymarksobtained", {
        //         required:
        //           getValues("hscpassingstatus") !== 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_MARKS_OBTAINED
        //           )?.ismandatory !== "1"
        //             ? false
        //             : "TY  Marks is required",
        //         validate: (value) => {
        //           if (getValues("hscpassingstatus") !== 0) {
        //             const outOfValue = parseInt(getValues("symarksoutof"));
        //             return (
        //               parseInt(value) <=
        //                 (outOfValue <= 900 ? outOfValue : 900) ||
        //               "Marks obtained cannot be greater than marks out of or 900"
        //             );
        //           }
        //         },
        //       })}
        //       onInput={(e) => {
        //         const value = parseInt(e.target.value);
        //         const maxValue = parseInt(getValues("tymarksoutof"));
        //         const maxAllowed = maxValue <= 900 ? maxValue : 900;
        //         if (value > maxAllowed) {
        //           e.target.value = maxAllowed;
        //         }
        //       }}
        //       onChange={(e) => {
        //         const value = parseInt(e.target.value);
        //         const maxValue = parseInt(getValues("tymarksoutof"));
        //         const maxAllowed = maxValue <= 900 ? maxValue : 900;
        //         if (value > maxAllowed) {
        //           e.target.value = maxAllowed;
        //         }
        //       }}
        //       inputProps={{
        //         min: 0,
        //         max:
        //           parseInt(getValues("tymarksoutof")) <= 900
        //             ? parseInt(getValues("tymarksoutof"))
        //             : 900,
        //       }}
        //       error={!!errors.tymarksobtained}
        //       helperText={errors.tymarksobtained?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: true, // Explicitly set to true
        //       }}
        //       disabled={getValues("hscpassingstatus") == 0}
        //     />
        //   </Grid>
        // )} */}

        {/* {masterFields?.find(
           (field) =>
        //     field.masterfieldlabel === masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        // ) && (
           <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="text"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="TY Marks Out Of*"
        //       {...register("tymarksoutof", {
        //         required:
        //           getValues("hscpassingstatus") != 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_MARKS_OUT_OF
        //           )?.ismandatory != "1"
        //             ? false
        //             : "SY Marks Out Of is required",
        //       })}
        //       onInput={percentageNumberValidation}
        //       // value={}
        //       error={!!errors?.tymarksoutof}
        //       helperText={errors.tymarksoutof?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: !!watch("tymarksoutof"),
        //       }}
        //       // disabled={isFieldDisabled(masterFields, masterFieldLabel.FOUR_Y_MARKS_OUT_OF)}
        //       disabled={getValues("hscpassingstatus") == 0 ? true : false}
        //     />
        //   </Grid>
        // )} */}

        {/* {masterFields?.find(
           (field) =>
        //     field.masterfieldlabel ===
        //     masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        // ) && (
           <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="text"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="Ty Calculated/Equivalent Percentage*"
        //       {...register("typercentage", {
        //         required:
        //           getValues("hscpassingstatus") != 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_CALCULATED_PERCENTAGE
        //           )?.ismandatory != "1"
        //             ? false
        //             : "Fy Calculated/Equivalent percentage is required",
        //       })}
        //       error={!!errors?.typercentage}
        //       helperText={errors?.typercentage?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: !!watch("typercentage"),
        //       }}
        //       disabled={getValues("hscpassingstatus") == 0}
        //     />
        //     <Box>
        //       <small style={{ color: "#666666" }}>
        //         Up to 2 decimal places are allowed
        //       </small>
        //     </Box>
        //   </Grid>
        // )} */}

        {/* {masterFields?.find(
           (field) =>
        //     field.masterfieldlabel === masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        // ) && (
          <Grid item xs={12} md={6}>
        //     <FormControl fullWidth size="small" error={!!errors.tynoofattempts}>
        //       <InputLabel
        //         shrink={!!watch("tynoofattempts")}
        //         htmlFor="tynoofattempts"
        //       >
        //         {
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //           )?.fieldlabel
        //         }
        //         {masterFields?.find(
        //           (field) =>
        //             field?.masterfieldlabel ===
        //             masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //         )?.ismandatory === "1" && (
        //           <span className="error" style={{ color: "red" }}>
        //             {" "}
        //             *
        //           </span>
        //         )}
        //       </InputLabel>
        //       <Select  MenuProps={{
                  disableScrollLock: true,
              }}
        //         id="tynoofattempts"
        //         label={
        //           <span>
        //             <span>
        //               {
        //                 masterFields?.find(
        //                   (field) =>
        //                     field?.masterfieldlabel ===
        //                     masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //                 )?.fieldlabel
        //               }
        //             </span>
        //             {masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //             )?.ismandatory === "1" && (
        //               <span className="error" style={{ color: "red" }}>
        //                 {" "}
        //                 *
        //               </span>
        //             )}
        //           </span>
        //         }
        //         {...register("tynoofattempts", {
        //           required:
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //             )?.ismandatory === "1"
        //               ? "SSC School State is required"
        //               : false,
        //         })}
        //         onChange={(e) => {
        //           setValue("tynoofattempts", e.target.value);
        //           trigger("tynoofattempts");
        //         }}
        //         value={watch("tynoofattempts") ?? ""}
        //         disabled={isFieldDisabled(
        //           masterFields,
        //           masterFieldLabel.FOUR_Y_NO_OF_ATTEMPTS
        //         )}
        //       >
        //         <MenuItem value="">Select One</MenuItem>
        //      
        //         <MenuItem value={0} key={0}>
        //           Not Applicable
        //         </MenuItem>
        //         <MenuItem value={1} key={1}>
        //           1
        //         </MenuItem>
        //         <MenuItem value={1} key={1}>
        //           2
        //         </MenuItem>

        //       
        //       </Select>
        //       {errors.tynoofattempts && (
        //         <FormHelperText style={{ margin: 0 }}>
        //           {errors.tynoofattempts.message}
        //         </FormHelperText>
        //       )}
        //     </FormControl>
        //   </Grid>
        // )} */}

        {/* <Grid item xs={12} md={12}>
           <Typography
        // //     pt={1}
        // //     sx={{
        // //       fontSize: "24px",
        // //       color: `${cssProperties?.color?.tertiary}`,
        // //       fontWeight: 500,
        // //       lineHeight: "30px",
        // //     }}
        // //   >
        // //     {" "}
        // //     AGGREGATE MARKS
        // //   </Typography>
        // // </Grid> */}

        {/* {masterFields?.find(
           (field) =>
        //     field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OBTAINED
        // ) && (
           <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="number"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.AGG_MARKS_OBTAINED
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_MARKS_OBTAINED
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="Aggregate Marks Obtained"
        //       {...register("tymarksobtained", {
        //         required:
        //           getValues("hscpassingstatus") !== 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_MARKS_OBTAINED
        //           )?.ismandatory !== "1"
        //             ? false
        //             : "TY  Marks is required",
        //         validate: (value) => {
        //           if (getValues("hscpassingstatus") !== 0) {
        //             const outOfValue = parseInt(getValues("symarksoutof"));
        //             return (
        //               parseInt(value) <=
        //                 (outOfValue <= 900 ? outOfValue : 900) ||
        //               "Marks obtained cannot be greater than marks out of or 900"
        //             );
        //           }
        //         },
        //       })}
        //       onInput={(e) => {
        //         const value = parseInt(e.target.value);
        //         const maxValue = parseInt(getValues("tymarksoutof"));
        //         const maxAllowed = maxValue <= 900 ? maxValue : 900;
        //         if (value > maxAllowed) {
        //           e.target.value = maxAllowed;
        //         }
        //       }}
        //       onChange={(e) => {
        //         const value = parseInt(e.target.value);
        //         const maxValue = parseInt(getValues("tymarksoutof"));
        //         const maxAllowed = maxValue <= 900 ? maxValue : 900;
        //         if (value > maxAllowed) {
        //           e.target.value = maxAllowed;
        //         }
        //       }}
        //       inputProps={{
        //         min: 0,
        //         max:
        //           parseInt(getValues("tymarksoutof")) <= 900
        //             ? parseInt(getValues("tymarksoutof"))
        //             : 900,
        //       }}
        //       error={!!errors.tymarksobtained}
        //       helperText={errors.tymarksobtained?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: true, // Explicitly set to true
        //       }}
        //       disabled={getValues("hscpassingstatus") == 0}
        //     />
        //   </Grid>
        // )} */}

        {/* {masterFields?.find(
          (field) =>
        //     field.masterfieldlabel === masterFieldLabel.AGG_MARKS_OUT_OF
        // ) && (
        <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="text"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.AGG_MARKS_OUT_OF
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_MARKS_OUT_OF
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="TY Marks Out Of*"
        //       {...register("tymarksoutof", {
        //         required:
        //           getValues("hscpassingstatus") != 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_MARKS_OUT_OF
        //           )?.ismandatory != "1"
        //             ? false
        //             : "SY Marks Out Of is required",
        //       })}
        //       onInput={percentageNumberValidation}
        //       // value={}
        //       error={!!errors?.tymarksoutof}
        //       helperText={errors.tymarksoutof?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: !!watch("tymarksoutof"),
        //       }}
        //       // disabled={isFieldDisabled(masterFields, masterFieldLabel.AGG_MARKS_OUT_OF)}
        //       disabled={getValues("hscpassingstatus") == 0 ? true : false}
        //     />
        //   </Grid>
        // )} */}

        {/* {masterFields?.find(
        (field) =>
        //     field.masterfieldlabel ===
        //     masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        // ) && (
           <Grid item xs={12} md={6}>
        //     <TextField
        //       fullWidth
        //       variant="outlined"
        //       size="small"
        //       type="text"
        //       label={
        //         <span>
        //           {
        //             masterFields?.find(
        //               (field) =>
        //                 field?.masterfieldlabel ===
        //                 masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        //             )?.fieldlabel
        //           }
        //           {masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        //           )?.ismandatory === "1" && (
        //             <span className="error" style={{ color: "red" }}>
        //               {" "}
        //               *
        //             </span>
        //           )}
        //         </span>
        //       }
        //       placeholder="Ty Calculated/Equivalent Percentage*"
        //       {...register("typercentage", {
        //         required:
        //           getValues("hscpassingstatus") != 1 ||
        //           masterFields?.find(
        //             (field) =>
        //               field?.masterfieldlabel ===
        //               masterFieldLabel.AGG_CALCULATED_PERCENTAGE
        //           )?.ismandatory != "1"
        //             ? false
        //             : "Fy Calculated/Equivalent percentage is required",
        //       })}
        //       error={!!errors?.typercentage}
        //       helperText={errors?.typercentage?.message}
        //       FormHelperTextProps={{
        //         style: { margin: 0 },
        //       }}
        //       InputLabelProps={{
        //         shrink: !!watch("typercentage"),
        //       }}
        //       disabled={getValues("hscpassingstatus") == 0}
        //     />
        //     <Box>
        //       <small style={{ color: "#666666" }}>
        //         Up to 2 decimal places are allowed
        //       </small>
        //     </Box>
        //   </Grid>
        // )} */}

        {/* 


        {masterFields?.find(
          (field) =>
            field.masterfieldlabel === masterFieldLabel.CANDIDATE_NAME_ON_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_NAME_ON_HSC
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_NAME_ON_HSC
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Candidate's Name as on HSC Marksheet*"
              {...register("cndnameonhscmarksheet", {
                required:( (getValues("hscpassingstatus") != 1 )  ||  masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CANDIDATE_NAME_ON_HSC
                )?.ismandatory != "1" )? false: "Candidate's Name as on HSC Marksheet is required" ,
              })}
              onInput={AlphabetsValidation}
              error={!!errors.cndnameonhscmarksheet}
              helperText={errors.cndnameonhscmarksheet?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("cndnameonhscmarksheet"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.CANDIDATE_NAME_ON_HSC)}
              disabled={getValues('hscpassingstatus') == 0 ? true :false}
          
          />
          </Grid>
        )}

        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
        ) && (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="text"
              label={
                <span>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                    )?.fieldlabel
                  }
                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </span>
              }
              placeholder="Candidate's Mother Name as on HSC Marksheet*"
              {...register("cndmothernameonhscmarksheet", {
                required: ( (getValues("hscpassingstatus") != 1 ) || masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC
                )?.ismandatory != "1" )?false : "Candidate's Mother Name as on HSC Marksheet is required",
              })}
              onInput={AlphabetsValidation}
              error={!!errors.cndmothernameonhscmarksheet}
              helperText={errors.cndmothernameonhscmarksheet?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("cndmothernameonhscmarksheet"),
              }}
              // disabled={isFieldDisabled(masterFields, masterFieldLabel.CANDIDATE_MOTHER_NAME_ON_HSC)}
              disabled={getValues('hscpassingstatus') == 0 ? true :false}
          />
          </Grid>
        )} */}

        <Grid
          item
          md={12}
          pt={2}
          pb={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <BackButton handleBack={handleBack} />
          {/* <EditButton /> */}
          <SubmitButton
            disabled={isPaid === "1"}
            name={buttonLoader ? "loader" : "Save and Proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { masterFieldLabel } from "../../utils/courseValues";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import masterActions from "../../redux/master/action";
import { LuPencilLine } from "react-icons/lu";
import SubmitButton from "../../common/submitButton";
import { DecryptObjectFunction, EncryptObjectFunction } from "../../utils/cryptoFunction";
import Cookies from 'js-cookie';

export default function Index() {
  const [ subjectGroup, setSubjectGroup] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { masterFields, uploadDetails, courseFormDetails, personalDetails } =
    useSelector((state) => state.candidatesReducer);
  const { gender } = useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useForm();
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get('c_data');
  let decrypted = DecryptObjectFunction(encryptedParams);
  const courseid = decrypted?.courseid;
  const coursename = decrypted?.coursename;
  console.log(decrypted,'decrypted');
  
  
  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: masterActions.GET_GENDER });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseFormDetails?.length > 0 && personalDetails?.length > 0) {
      const formattedDob = dayjs(personalDetails[0]?.dob).format("DD/MM/YYYY");
      reset({ ...personalDetails[0], dob: formattedDob });
      setValue('subjectgroup',courseFormDetails[0]?.subjectgroup);
      setSubjectGroup(courseFormDetails[0]?.subjectgroup);
    }
  }, [courseFormDetails, personalDetails]);

  const handleSave = (data) => {
    let dataValue = data?.subjectgroup;
    let group = ['PCMB']
    let isNoExtraFee = (subjectGroup === 'PCMB' || dataValue === 'PCMB') && group.includes(subjectGroup);
    dispatch({
      type:candidatesActions.EDIT_COURSE_DETAILS,
      payload: { data : {...data,courseid:courseid,candidateid:candidateid},
      navigate:navigate,
      edit:true,
      isNoExtraFee:isNoExtraFee,
      coursename:coursename
    }
    })
  };

  const handleImageNavigation = () => {
    const queryParams = {
      courseid: courseid,
      coursename: coursename,
      status : 1
    };
    const encryptedParams = EncryptObjectFunction(queryParams);
    navigate(`/${Cookies.get("mhet_cnd_project")}/registration?page=uploads&&c_data=${encryptedParams}`);
  }

  return (
    <Grid
      style={{
        padding: "20px 25px 20px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography sx={{fontSize:24}}>Update Details</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label={
              <>
                Candidate Name<span className="error">*</span>
              </>
            }
            placeholder="Enter candidate name"
            {...register("candidatename", {
              required: "Candidate name is required",
            })}
            error={!!errors.candidatename}
            helperText={errors?.candidatename?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              shrink: !!watch("candidatename"),
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              label={
                <>
                  DOB<span className="error">*</span>
                </>
              }
              name="dob"
              id="dob"
              {...register("dob", {
                required: "DOB is required",
              })}
              value={watch("dob") ? dayjs(watch("dob"), "DD/MM/YYYY") : null}
              onChange={(date) => {
                const localDate = dayjs(date).format("DD/MM/YYYY");
                setValue("dob", localDate, { shouldValidate: true });
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  helperText: errors.dob ? errors.dob.message : "",
                  error: !!errors.dob,
                  style: {
                    width: "100%",
                    borderRadius: "8px",
                  },
                  FormHelperTextProps: {
                    style: {
                      margin: 0,
                    },
                  },
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth error={!!errors.genderid}>
            <InputLabel shrink={!!watch("genderid")} htmlFor="genderid">
              Gender
              <span className="error" style={{ color: "red" }}>
                {" "}
                *
              </span>
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              id="genderid"
              label="Gender"
              {...register("genderid", { required: "Gender is required" })}
              onChange={(e) => {
                setValue("genderid", e.target.value);
                trigger("genderid");
              }}
              value={watch("genderid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {gender?.map((val, i) => (
                <MenuItem value={val?.genderid} key={i}>
                  {val?.gender}
                </MenuItem>
              ))}
            </Select>
            {errors.genderid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.genderid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth error={!!errors.genderid}>
            <InputLabel shrink={!!watch("subjectgroup")} htmlFor="subjectgroup">
              <>
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_SUBJECT_GROUP
                  )?.fieldlabel
                }

                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SELECT_SUBJECT_GROUP
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </>
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              inputProps={{
                style: { height: "56px", boxSizing: "border-box" },
              }}
              id="subjectgroup"
              label={
                <>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_SUBJECT_GROUP
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_SUBJECT_GROUP
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </>
              }
              {...register("subjectgroup", {
                required: "Subject group is required",
              })}
              onChange={(e) => {
                setValue("subjectgroup", e.target.value);
                trigger("subjectgroup");
              }}
              value={watch("subjectgroup") || ""}
            >
              <MenuItem value="" disabled>
                Select one
              </MenuItem>
              <MenuItem value="PCM">PCM</MenuItem>
              <MenuItem value="PCB">PCB</MenuItem>
              <MenuItem value="PCM and PCB">PCM & PCB</MenuItem>
            </Select>
            {errors.subjectgroup && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.subjectgroup.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item md={12} style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel style={{ fontWeight: "bold" }}>
            Upload Image
            <span className="error" style={{ color: "#red" }}>
              *
            </span>
          </InputLabel>
          <Box
            sx={{
              display: "flex",
              gap: "2%",
              marginTop: ".4rem",
              flexDirection: isMobile ? "column" : "",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                <img
                  src={
                    uploadDetails?.find((item) => item?.documenttype == 1)
                      ?.fileurl
                  }
                  alt="Original Image"
                  style={{
                    height: "140px",
                    width: "auto",
                    borderRadius: "4px",
                  }}
                />

                <IconButton
                  onClick={() => handleImageNavigation()}
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-11px",
                    backgroundColor: "#0F4F96",
                    borderRadius: "50%",
                    padding: "4px",
                    color: "#ffffff",
                    border: "2px solid #FFFFFF",
                  }}
                >
                  <LuPencilLine style={{ width: "16px", height: "16px" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} style={{ display: "flex", flexDirection: "column" }}>
          <InputLabel style={{ fontWeight: "bold" }}>
            Upload Signature
            <span className="error" style={{ color: "#666666" }}>
              *
            </span>
          </InputLabel>
          <Box
            style={{
              display: "flex",
              gap: "2%",
              marginTop: ".4rem",
              flexDirection: isMobile ? "column" : "",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={
                    uploadDetails?.find((item) => item?.documenttype == 2)
                      ?.fileurl
                  }
                  alt="Original Signature"
                  style={{
                    height: "140px",
                    width: "auto",
                    borderRadius: "4px",
                  }}
                />

                <IconButton
                  onClick={() => handleImageNavigation()}
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-18px",
                    backgroundColor: "#0F4F96",
                    borderRadius: "50%",
                    padding: "4px",
                    color: "#ffffff",
                    border: "2px solid #FFFFFF",
                  }}
                >
                  <LuPencilLine style={{ width: "16px", height: "16px" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
            marginBottom: 1,
          }}
        >
          <SubmitButton
            name={buttonLoader ? "loader" : "Save and Proceed"}
            handleSubmit={handleSubmit(handleSave)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import ExamCenterForm from "./examCenterForm";
import PreviewForm from "./previewForm";
import PayApplicationFeeForm from "./payApplicationFeeForm";
import PrintApplicationForm from "./printApplicationForm";
import AcademicDetailsForm from "./academicDetailsForm";
import ReservationDetailsForm from "./reservationDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import authActions from "../../redux/auth/actions";
import { RiFileUserLine } from "react-icons/ri";
import { IoIosHome } from "react-icons/io";
import { PiGraduationCap } from "react-icons/pi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { cssProperties } from "../../utils/commonCssProperties";
import { DecryptObjectFunction } from "../../utils/cryptoFunction";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const coursename = location.state?.coursename;
  const { courseTabs } = useSelector((state) => state.candidatesReducer);
  const initialTab = courseTabs[0]?.tablabel?.toLowerCase().replaceAll(" ", "");
  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);
  const [nextPageName, setNextPageName] = useState("");
  const [previousPageName, setPreviousPageName] = useState("");
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const [courseTab, setCourseTab] = useState(`${initialTab}`);
  const encryptedData = searchParams.get("c_data");
  const decrypted = DecryptObjectFunction(encryptedData);
  const courseid = location?.state?.courseid || decrypted?.courseid;
  const edit = decrypted?.edit;

  const getDynamicSpanStyle = (isActive) => ({
    // backgroundColor: isActive ? `${cssProperties?.backgroundcolor?.primary}` : '#fff',
    borderRadius: "50%",
    width: "30px",
    height: "30px",
  });
  const iconstyle = {
    width: "23px",
    height: "23px",
    color: "#658F9D",
    marginTop: "2px",
  };

  const candidateid = useSelector(
    (state) => state.authReducer?.candidateTokenDetails?.candidateid
  );
  const { courseActiveTab } = useSelector((state) => state.candidatesReducer);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  const iconMapping = {
    personalinformation: (
      <span style={getDynamicSpanStyle(null)}>
        {" "}
        <RiFileUserLine style={iconstyle} />
      </span>
    ),
    address: (
      <span style={getDynamicSpanStyle(null)}>
        <IoIosHome style={iconstyle} />
      </span>
    ),
    qualificationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    domicileandcategorydetails: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    preview: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    applicationfee: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    printapplication: (
      <span style={getDynamicSpanStyle(null)}>
        <IoDocumentTextOutline style={iconstyle} />
      </span>
    ),
    examinationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    academicdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
    reservationdetails: (
      <span style={getDynamicSpanStyle(null)}>
        <PiGraduationCap style={iconstyle} />
      </span>
    ),
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_TAB,
        payload: {
          data: {
            courseid: courseid,
          },
        },
      });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_ACTIVE_TAB,
        payload: {
          data: {
            courseid: courseid,
          },
        },
      });
    }
  }, [courseid]);

  useEffect(() => {
    const tabMapping = courseTabs.reduce((acc, tab, index) => {
      const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
      acc[pageName] = index;
      return acc;
    }, {});
    tabMapping["preview"] = courseTabs.length;
    tabMapping["applicationfee"] = courseTabs.length + 1;
    tabMapping["printapplication"] = courseTabs.length + 2;
    const currentActiveTab = tabMapping[page] ?? null;
    if (currentActiveTab !== null) {
      setActiveTab(currentActiveTab);
    }
    setCourseTab(page);
    const reverseMapping = Object.fromEntries(
      Object.entries(tabMapping).map(([key, value]) => [value, key])
    );
    const nextPageIndex = (currentActiveTab + 1) % (courseTabs.length + 3);
    const nextPageName = reverseMapping[nextPageIndex];
    setNextPageName(nextPageName);

    const previousPageIndex =
      (currentActiveTab - 1 + (courseTabs.length + 3)) %
      (courseTabs.length + 3);
    const previousPageName = reverseMapping[previousPageIndex];
    setPreviousPageName(previousPageName);

    console.log("previous page name:", previousPageName);
  }, [page, courseTabs, courseActiveTab]);

  useEffect(() => {
    if (!edit) {
      if (
        courseActiveTab === undefined ||
        courseActiveTab === "" ||
        courseActiveTab === null
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=personalinformation`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (parseInt(courseActiveTab?.ispaid) === 1) {
        console.log("navigating on this condition");
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=printapplication`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.exampreference_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=preview`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.academic_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=examinationdetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.reservation_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=qualificationdetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.address_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=domicileandcategorydetails`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      } else if (courseActiveTab?.personalinformation_status === 1) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/course-registration?page=address`,
          { state: { courseid: courseid, coursename: coursename } }
        );
      }
    }
  }, [courseActiveTab,edit]);

  const courseRegistrationData = courseTabs.map((tab, index) => {
    const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
    const isActive = activeTab === index;
    return {
      title: tab.tablabel,
      icon: (
        <span style={getDynamicSpanStyle(isActive)}>
          {iconMapping[pageName] || <IoDocumentTextOutline style={iconstyle} />}
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${pageName}`,
    };
  });

  courseRegistrationData.push(
    {
      title: "Preview & Validate Information",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=preview`,
    },
    {
      title: "Pay Application Fee",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=applicationfee`,
    },
    {
      title: "Print Application Form",
      icon: (
        <span style={getDynamicSpanStyle(null)}>
          <IoDocumentTextOutline style={iconstyle} />
        </span>
      ),
      to: `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=printapplication`,
    }
  );

  console.log(index, activeTab, "index");
  if (courseTabs.length > 0) {
    return (
      <>
        <Tabs
          setIndex={setIndex}
          activeTab={activeTab}
          courseRegistrationData={courseRegistrationData}
        />
        {courseTabs?.find((obj) => obj.tablabel === "Personal Information") && (
          <>
            {courseTab === "personalinformation" && (
              <PersonalDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find((obj) => obj.tablabel === "Address") && (
          <>
            {courseTab === "address" && (
              <AddressForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find(
          (obj) => obj.tablabel === "Qualification Details"
        ) && (
          <>
            {courseTab === "qualificationdetails" && (
              <AcademicDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find(
          (obj) => obj.tablabel === "Domicile and Category Details"
        ) && (
          <>
            {courseTab === "domicileandcategorydetails" && (
              <ReservationDetailsForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTabs?.find((obj) => obj.tablabel === "Examination Details") && (
          <>
            {courseTab === "examinationdetails" && (
              <ExamCenterForm
                nextPageName={nextPageName}
                previousPageName={previousPageName}
              />
            )}
          </>
        )}

        {courseTab === "preview" && <PreviewForm />}
        {courseTab === "applicationfee" && <PayApplicationFeeForm />}
        {courseTab === "printapplication" && <PrintApplicationForm />}
      </>
    );
  }
}

// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from "js-cookie";
// import Tabs from "./tabs";
// import PersonalDetailsForm from "./personalDetailsForm";
// import AddressForm from "./addressForm";
// import ExamCenterForm from "./examCenterForm";
// import PreviewForm from "./previewForm";
// import PayApplicationFeeForm from "./payApplicationFeeForm";
// import PrintApplicationForm from "./printApplicationForm";
// import AcademicDetailsForm from "./academicDetailsForm";
// import ReservationDetailsForm from "./reservationDetailsForm";
// import { useDispatch, useSelector } from "react-redux";
// import candidatesActions from "../../redux/candidates/actions";
// import { RiFileUserLine } from "react-icons/ri";
// import { IoIosHome } from "react-icons/io";
// import { PiGraduationCap } from "react-icons/pi";
// import { IoDocumentTextOutline } from "react-icons/io5";
// import { cssProperties } from "../../utils/commonCssProperties";

// export default function Index() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const courseid = location.state?.courseid;
//   const { courseTabs } = useSelector(
//     (state) => state.candidatesReducer
//   );
//   const { courseActiveTab } = useSelector(
//     (state) => state.candidatesReducer
//   );
//   // const  courseTabs=["personalinformation","address","qualificationdetails","domicileandcategorydetails",
//   //   "examinationdetails"
//   // ]
//  console.log(courseTabs,"courseTabs");
//   console.log(courseActiveTab,'courseActiveTab');
//   const candidateid = useSelector(
//     (state) => state.authReducer?.candidateTokenDetails?.candidateid
//   );
//   const initialTab = courseTabs[0]?.tablabel?.toLowerCase().replaceAll(" ", "");

// const [courseTab, setCourseTab] = useState(`${initialTab}`);

//   const [activeTab, setActiveTab] = useState(0);
//   const [nextPageName, setNextPageName] = useState("");
//   const [previousPageName, setPreviousPageName] = useState("");
//   const [searchParams] = useSearchParams();
//   const page = searchParams.get("page");

//   useEffect(() => {
//     if (candidateid) {
//       dispatch({
//         type: candidatesActions.GET_COURSE_TAB,
//         payload: { data: { courseid } },
//       });
//       dispatch({
//         type: candidatesActions.GET_COURSE_ACTIVE_TAB,
//         payload: { data: { courseid } },
//       });
//     }
//   }, [candidateid, courseid, dispatch]);

//   useEffect(() => {
//     if (courseTabs.length === 0) return;

//     const tabMapping = courseTabs.reduce((acc, tab, index) => {
//       const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//       acc[pageName] = index;
//       return acc;
//     }, {});

//     const additionalTabs = {
//       preview: courseTabs.length,
//       applicationfee: courseTabs.length + 1,
//       printapplication: courseTabs.length + 2,
//     };

//     const currentActiveTab = additionalTabs[page] ?? tabMapping[page] ?? null;
//     if (currentActiveTab !== null) {
//       setActiveTab(currentActiveTab);
//     }
//     setCourseTab(page);
//   }, [page, courseTabs]);

//     useEffect(() => {
//     const tabMapping = courseTabs.reduce((acc, tab, index) => {
//       const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//       acc[pageName] = index;
//       return acc;
//     }, {});
//     tabMapping["preview"] = courseTabs.length;
//     tabMapping["applicationfee"] = courseTabs.length + 1;
//     tabMapping["printapplication"] = courseTabs.length + 2;
//     const currentActiveTab = tabMapping[page] ?? null;
//     if (currentActiveTab !== null) {
//       setActiveTab(currentActiveTab);
//     }
//     setCourseTab(page);
//     const reverseMapping = Object.fromEntries(
//       Object.entries(tabMapping).map(([key, value]) => [value, key])
//     );
//     const nextPageIndex = (currentActiveTab + 1) % (courseTabs.length + 3);
//     const nextPageName = reverseMapping[nextPageIndex];
//     setNextPageName(nextPageName);

//     const previousPageIndex =
//       (currentActiveTab - 1 + (courseTabs.length + 3)) %
//       (courseTabs.length + 3);
//     const previousPageName = reverseMapping[previousPageIndex];
//     setPreviousPageName(previousPageName);

//     console.log("previous page name:", previousPageName);
//   }, [page, courseTabs,courseActiveTab]);
//   useEffect(() => {
//     if (!courseActiveTab) return;

//     const projectPath = Cookies.get("mhet_cnd_project");
//     const navigateTo = (pageName) => {
//       navigate(`/${projectPath}/course-registration?page=${pageName}`, {
//         state: { courseid },
//       });
//     };

//     if (parseInt(courseActiveTab?.ispaid) === 1) {
//       navigateTo("printapplication");
//     } else if (courseActiveTab?.exampreference_status === 1) {
//       navigateTo("preview");
//     } else if (courseActiveTab?.academic_status === 1) {
//       navigateTo("examinationdetails");
//     } else if (courseActiveTab?.reservation_status === 1) {
//       navigateTo("academicdetails");
//     } else if (courseActiveTab?.address_status === 1) {
//       navigateTo("domicileandcategorydetails");
//     } else if (courseActiveTab?.personalinformation_status === 1) {
//       navigateTo("address");
//     }
//   }, [courseActiveTab, navigate, courseid]);

//   const iconMapping = {
//     personalinformation:<span style={getDynamicSpanStyle(null)}> <RiFileUserLine style={iconstyle} /></span>,
//     address: <span style={getDynamicSpanStyle(null)}><IoIosHome style={iconstyle} /></span>,
//     qualificationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     domicileandcategorydetails: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     preview: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     applicationfee: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     printapplication: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//     examinationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     academicdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//     reservationdetails: <span style={getDynamicSpanStyle(null)}><PiGraduationCap style={iconstyle} /></span>,
//   };

//   const courseRegistrationData = courseTabs.map((tab) => {
//     const pageName = tab.tablabel.toLowerCase().replace(/\s+/g, "");
//     return {
//       title: tab.tablabel,
//       icon: iconMapping[pageName] || <IoDocumentTextOutline style={iconstyle} />,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=${pageName}`,
//     };
//   });

//   courseRegistrationData.push (
//     {
//       title: "Preview & Validate Information",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=preview`,
//     },
//     {
//       title: "Pay Application Fee",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=applicationfee`,
//     },
//     {
//       title: "Print Application Form",
//       icon: <span style={getDynamicSpanStyle(null)}><IoDocumentTextOutline style={iconstyle} /></span>,
//       to: `/${Cookies.get("mhet_cnd_project")}/course-registration?page=printapplication`,
//     },
//   )

//   return (
//     <>
//       <Tabs
//         activeTab={activeTab}
//         courseRegistrationData={courseRegistrationData}
//       />
// {courseTabs?.find((obj) => obj.tablabel === "Personal Information") && (
//   <>
//     {courseTab === "personalinformation" && (
//       <PersonalDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Address") && (
//   <>
//     {courseTab === "address" && (
//       <AddressForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Qualification Details") && (
//   <>
//     {courseTab === "qualificationdetails" && (
//       <AcademicDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Domicile and Category Details") && (
//   <>
//     {courseTab === "domicileandcategorydetails" && (
//       <ReservationDetailsForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTabs?.find((obj) => obj.tablabel === "Examination Details") && (
//   <>
//     {courseTab === "examinationdetails" && (
//       <ExamCenterForm
//         nextPageName={nextPageName}
//         previousPageName={previousPageName}
//       />
//     )}
//   </>
// )}

// {courseTab === "preview" && <PreviewForm />}
// {courseTab === "applicationfee" && <PayApplicationFeeForm />}
// {courseTab === "printapplication" && <PrintApplicationForm />}
//    </>
//   );
// }

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { IoCloudUploadOutline } from "react-icons/io5";
import { PiXBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SubmitButton from "../../common/submitButton";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import Cookies from "js-cookie";
import commonActions from "../../redux/common/actions";
import { cssProperties } from "../../utils/commonCssProperties";

export default function Index() {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { grievance } = useSelector((state) => state.masterReducer);

  const { buttonLoader } = useSelector((state) => state.commonReducer);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
  } = useForm();

  const [grievanceData, setGrievanceData] = useState();

  let [document, setDocument] = useState({ file: null, name: null });

  useEffect(() => {
    dispatch({ type: masterActions.GET_GRIEVANCE });
  }, []);

  const handleGrievance = (e) => {
    const selectedGrievance = parseInt(e.target.value);
    const grievanceItem = grievance.find(
      (item) => item.grievanceid === selectedGrievance
    );
    setGrievanceData(grievanceItem);
    setValue("grievanceid", grievanceItem?.grievanceid);
    resetField("grievancedetails");
    setDocument({ file: null, name: null });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy"; // Show the copy cursor when dragging
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleDocumentUpload(e);
  };

  const handleDocumentUpload = (e) => {
    const file = e.target.files || e.dataTransfer.files;
    if (file?.length) {
      const acceptedTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];
      if (file[0].size > 5 * 1024 * 1024) {
        dispatch({
          type: commonActions.SET_ALERT,
          payload: {
            status: "failed",
            show: true,
            message: "File size exceeds 5mb. Maximum file size is 5mb",
          },
        });
      } else {
        if (acceptedTypes.includes(file[0].type)) {
          setDocument({ file: file[0], name: file[0]?.name });
        } else {
          // } else {
          e.target.value = null;
          setDocument({ file: null, name: null });
          dispatch({
            type: commonActions.SET_ALERT,
            payload: {
              status: "failed",
              show: true,
              message: "Please upload a JPEG, JPG, PNG, GIF, or PDF file",
            },
          });
        }
      }
    }
    setTimeout(() => {
      dispatch({
        type: commonActions.SET_ALERT,
        payload: { show: false },
      });
    }, 2000);
  };

  const onSubmit = (data) => {
    if (document?.file) {
      data["document"] = document?.file;
    }
    data["grievanceid"] = parseInt(data["grievanceid"]);
    data["candidateid"] = parseInt(candidateid);
    data["tickettype"] = 1;
    data["createdby"] = parseInt(candidateid);
    data["status"] = 1;
    data["iscandidate"] = '1';
    dispatch({
      type: candidatesActions.GRIEVANCE_QUERY_CREATION,
      payload: { data: data, navigate: navigate },
    });
  };

  const handleClickView = () => {
    navigate(`/${Cookies.get("mhet_cnd_project")}/query-list`);
  };

  return (
    <>
      <Grid
        Container
        style={{
          padding: "20px 25px 0px 25px",
          minHeight: "40vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <Grid item md={6}>
          <FormControl fullWidth size="small">
            <InputLabel>
              Select Grievance <span className="error">*</span>
            </InputLabel>
            {/* <InputLabel>Select Grievance</InputLabel> */}
            <Select  MenuProps={{
                  disableScrollLock: true,
              }}
              label="Select Grievance"
              {...register("grievanceid", {
                required: "Grievance type is required",
              })}
              defaultValue={""}
              onChange={(e) => handleGrievance(e)}
            >
              <MenuItem value={""} disabled>
                Select Grievance
              </MenuItem>
              {grievance?.map((val, i) => (
                <MenuItem key={i} value={val?.grievanceid}>
                  {val.grievance}
                </MenuItem>
              ))}
            </Select>
            {errors.grievanceid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.grievanceid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {grievanceData?.isanswerable === "1" && (
          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={grievanceData?.answer}
              disabled={true}
              InputProps={{
                sx: {
                  color: "#3F41D1",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#3F41D1",
                    fontWeight: 500,
                  },
                },
              }}
            />
          </Box>
        )}

        <Grid item mt={2} md={6}>
          <TextField
            fullWidth
            multiline
            label={
              <span>
                Grievance Details
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            // label="Grievance Details"
            rows={2}
            {...register("grievancedetails", {
              required: "Grievance details is required",
            })}
            error={!!errors.grievancedetails}
            helperText={errors.grievancedetails?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            disabled={grievanceData?.israiseticket === "1" ? false : true}
            inputProps={{ maxLength: 200 }}
          />
          <Typography
            variant="subtitle2"
            sx={{ marginTop: "2px", marginLeft: "2px" }}
          >
            Not more than 200 characters
          </Typography>
        </Grid>
        {grievanceData?.israiseticket === "1" && (
          <Grid item mt={2}>
            <InputLabel>Upload Documents</InputLabel>
            <Box>
              {document?.file ? (
                <Box
                  pl={2}
                  style={{
                    width: "fit-content",
                    // maxWidth: "18em",
                    height: "45px",
                    border: "1px solid #D5D5D5",
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    marginTop: "1rem",
                    paddingLeft: "0",
                    // overflow: "hidden",
                    // whiteSpace: "nowrap",
                    // position: 'relative'
                  }}
                >
                  <Box sx={{ paddingLeft: "10px" }}>{document?.name}</Box>
                  <Box
                    onClick={() => setDocument({ file: null, name: null })}
                    sx={{ paddingRight: "10px",cursor:"pointer" }}
                    //  sx={{position: 'absolute', right: 0, width: '1.5rem', paddingLeft: '5px', backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`}}
                  >
                    <PiXBold />
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    border: "1px dashed gray",
                    maxWidth: "330px",
                    minHeight: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center",
                    flexShrink: 340,
                    position: "relative",
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    name="document"
                    id="document"
                    accept=".jpeg,.jpg,.png,.gif,.pdf"
                    alt=""
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={(e) => handleDocumentUpload(e)}
                  />  
                  <label
                    htmlFor="document"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection:"column",
                      alignItems: "center",
                      justifyContent:"center"
                    }}
                  >
                    <IoCloudUploadOutline
                      style={{ marginLeft: "8px" }}
                      size={22}
                    />
                    <span> &nbsp; Drag or drop your file, or{" "}
                    <span style={{ color: "blue" }}>
                      &nbsp;browse
                    </span></span>
                    <small style={{ color: "rgb(102, 102, 102)" }}>
                      (JPEG, PNG, GIF and PDF) (5mb max.)
                      </small>
                  </label>
                </Box>
              )}
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}
              >
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  Supported file format: JPEG, PNG, GIF and PDF
                </small>
                <small style={{ color: "rgb(102, 102, 102)" }}>
                  Max limit 5mb
                </small>
              </Box> */}
            </Box>
          </Grid>
        )}
        <Grid
          pt={2}
          pb={2}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
              gap: 2,
              // position: "fixed",
              bottom: 1,
            }}
          >
            <SubmitButton
              handleSubmit={handleSubmit(onSubmit)}
              name={buttonLoader ? "loader" : "Submit Grievance"}
              disabled={grievanceData?.israiseticket === "1" ? false : true}
            />
            <MauvelousPrimaryButton
              title={"Cancel"}
              handleClick={handleClickView}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import dayjs from "dayjs";
import authActions from "../../redux/auth/actions";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
import CetImage from "../../assets/images/courseRegistration/Cet.png";

export default function ApplicationForm({ targetRef, courseId }) {
  const [CheckboxValue, setCheckBoxValue] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const { courseFormDetails, personalDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  console.log(courseFormDetails, "courseFormDetails");

  const applicantid = useSelector(
    (state) => state.candidatesReducer.applicantid.cnd_getapplicantid_bycourseid
  );

  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  }, []);

  const { uploadDetails,courseUploadDetails } = useSelector((state) => state.candidatesReducer);
console.log('courseUploadDetails---',courseUploadDetails);

  const status = courseFormDetails[0]?.status;
  const isPaid = courseFormDetails[0]?.ispaid;

  //   const handleProcced = () => {
  //     if (CheckboxValue) {
  //       dispatch({ type:candidatesActions.COURSE_PREVIEW_UPDATION,
  //         payload :{ data : { courseid:courseid, candidateid:candidateid }, coursename:coursename, navigate:navigate}
  //        })
  //     } else {
  //       if (!CheckboxValue) {
  //         setError("Select the checkbox is required");
  //       }
  //     }
  //   };
  //   const handleCheckbox = (e) => {
  //     const checked = e.target.checked;
  //     setCheckBoxValue(checked);
  //     setError("");
  //   };

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_APPLICANTID,
        payload: {
          courseid: courseid ? courseid : courseId,
          candidateid: candidateid,
        },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      
    }
  }, [candidateid]);

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (uploadDetails) {
      if (uploadDetails[2]?.document) {
        try {
          const base64Data =
            uploadDetails[2]?.document.split(",")[1] ||
            uploadDetails[2]?.document;
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          return () => URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error decoding Base64 string:", error);
        }
      }
      
    }

    setTimeout(() => {
      
      dispatch({ type: candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,data:{courseid:courseFormDetails ? courseFormDetails[0]?.courseid : null} });
    }, 2000);

  }, [uploadDetails[2]?.document, courseFormDetails]);





  return (
    <Box
      //   p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        // borderRadius: "8px",
        border: "2px solid #000",
      }}
      ref={targetRef}
    >
      <Box
        style={{
          borderBottom: "1px solid #000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontWeight={600}>
          Online Registration For {coursename}
        </Typography>
        <Typography variant="h6" fontWeight={600}>
          Application Form
        </Typography>
      </Box>
      <Grid
        container
        //   p={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #000",
        }}
      >
        <Grid item sx={{ width: "100px" }}>
          <img
            style={{ height: "100px", width: "auto" }}
            src={CetImage}
            alt="image"
          />
        </Grid>
        <Grid
          item
          // md={8}

          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            flex: 1,
          }}
        >
          <img
            style={{ height: "75px", width: "auto" }}
            src={GovernmentImage}
            alt="image"
          />
          <Typography variant="h5" fontWeight={600}>
            GOVERNMENT OF MAHARASHTRA
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            STATE COMMON ENTRANCE TEST CELL, MAHARASHTRA STATE
          </Typography>
          <Typography variant="p" fontWeight={600}>
            8th Floor, New Excelsior Building, A.K.Nayak Marg, Fort,
            Mumbai-400001. (M.S.)
          </Typography>
          <Typography variant="p" sx={{ marginTop: 1 }} fontWeight={600}>
            Application Form for Online Registration For {coursename}
          </Typography>
          {/* <Typography>APPLICATION FORM</Typography> */}
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "flex-end", width: "100px" }}
        >
          <img
            style={{ height: "100px", width: "auto" }}
            src={CetImage}
            alt="image"
          />
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: "1px solid #000" }}>
        {location.pathname + location.search ===
          "/mhcet/course-registration?page=printapplication" &&
          applicantid !== "" && (
            <Typography
              variant="h6"
              sx={{
                textWrap: "wrap",
                fontWeight: "bold",
                color: "#000",
                textAlign: "center",
              }}
            >
              Application No : {applicantid} {"  "}
              Version No : 1
            </Typography>
          )}
      </Box>
      <Grid container sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              borderBottom: "1px solid #000",
            }}
          >
            <Box>
              <Typography variant="p">Personal Details</Typography>
            </Box>
          </Box>

          <Grid container xs={12} sx={{ minWidth: "700px" }}>
            <Grid item md={7} sx={{ width: "450px" }}>
              <Grid container xs={12}>
                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Candidate’s Full name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {personalDetails[0]?.candidatename}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Father’s Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.fathersname}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Mother’s Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.mothername}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Gender
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.gender}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Marital Status
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.maritalstatusid) === 1
                      ? "Married"
                      : parseInt(courseFormDetails[0]?.maritalstatusid) === 2
                      ? "Unmarried"
                      : "-"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Spouse Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails?.length > 0 &&
                      (courseFormDetails[0]?.spousename || "-")}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Date of Birth
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {personalDetails[0]?.dob &&
                      dayjs(personalDetails[0]?.dob).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Religion
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.religion}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Region
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.region}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Mother Tongue
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.mothertongue}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Annual Family Income
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.incomerange}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Nationality
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.nationality}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    {coursename} Application Fee Paid (&#x20B9;)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.amount} /-
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} sx={{ border: "1px solid #000", width: "250px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: "120px", height: "auto", aspectRatio: "3/4" }}
                  src={courseUploadDetails ? courseUploadDetails?.find((item,index)=>item?.documenttype==1)?.fileurl : ""}
                  alt="photo"
                />

                <img
                  style={{ width: "120px", height: "auto", aspectRatio: "4/3" }}
                  src={courseUploadDetails ? courseUploadDetails?.find((item,index)=>item?.documenttype==2)?.fileurl : ""}
                  alt="signatute"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                //   height: "48px",
                //   backgroundColor: "#EDEDED",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                border: "1px solid #000",
                // width: "348px",
                // borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>Permanent Address</Typography>
              </Box>
            </Box>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  {" "}
                  Address Line 1
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.paddressline1}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Address Line 2
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.paddressline2}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Address Line 3
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails?.length > 0 &&
                    (courseFormDetails[0]?.paddressline3 || "-----")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  State
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.pstate}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  District
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.pdistrict}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Taluka
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.ptaluka}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Village
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.pvillage}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Pin code
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "274px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.ppincode}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                border: "1px solid #000",
                // width: "350px",

                // borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Address for Correspondence
                </Typography>
              </Box>
            </Box>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  {" "}
                  Address Line 1
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.caddressline1}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Address Line 2
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.caddressline2}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "148px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Address Line 3
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "200px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails?.length > 0 &&
                    (courseFormDetails[0]?.caddressline3 || "-----")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  State
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.cstate}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  District
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.cdistrict === null
                    ? "Not Applicable"
                    : courseFormDetails[0]?.cdistrict}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Taluka
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.ctaluka === null
                    ? "Not Applicable"
                    : courseFormDetails[0]?.ctaluka}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Village
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "100px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.cvillage === null
                    ? "Not Applicable"
                    : courseFormDetails[0]?.cvillage}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "74px" }}
              >
                <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Pin code
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sx={{ border: "1px solid #000", width: "274px" }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.cpincode}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sx={{ border: "1px solid #000" }}>
            <Box sx={{ width: "346px" }}>
              <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                Telephone No
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} sx={{ border: "1px solid #000" }}>
            <Box sx={{ width: "346px" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {courseFormDetails[0]?.phoneno}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              border: "1px solid #000",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Domicile and Category Details
              </Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            {courseFormDetails[0]?.ismhcandidate && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Are You Domiciled In The State Of Maharastra
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.ismhcandidate) == 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.ismhcandidate) == 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.category && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Category
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.category}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.castename && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.castename}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.caste && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste To Which You Belong
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.caste}
                  </Typography>
                </Grid>
              </>
            )}
            {courseFormDetails[0]?.castecertificatestatus && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Do You Possess Caste Certificate
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.castecertificatestatus) ===
                    1
                      ? "Available"
                      : parseInt(
                          courseFormDetails[0]?.castecertificatestatus
                        ) === 2
                      ? "Applied But Not Received"
                      : parseInt(
                          courseFormDetails[0]?.castecertificatestatus
                        ) === 3
                      ? "Not Applied"
                      : "-----"}
                  </Typography>
                </Grid>
              </>
            )}

            {((courseFormDetails[0]?.castevaliditystatus !== null &&
              courseFormDetails[0]?.castevaliditystatus !== undefined &&
              courseFormDetails[0]?.castevaliditystatus !== "") ||
              courseFormDetails[0]?.castevaliditystatus === 0) && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Do You Possess Caste/Tribe Validity Certificate
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.castevaliditystatus) === 1
                      ? "Available"
                      : parseInt(courseFormDetails[0]?.castevaliditystatus) ===
                        2
                      ? "Applied But Not Received"
                      : parseInt(courseFormDetails[0]?.castevaliditystatus) ===
                        0
                      ? "Not Applied"
                      : "-----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.castevaliditycertificateno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste Validity Certificate No
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.castevaliditycertificateno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.cvcapplicationno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste/Tribe Validity Certificate Application Number
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvcapplicationno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.cvcapplicationdate && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste/Tribe Validity Certificate Application Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvcapplicationdate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.cvcauthority && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste/Tribe Validity Certificate Issuing Authority Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvcauthority}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.cvcdistrict && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste/Tribe Validity Certificate Issuing District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvcdistrict}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.cvcname && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Candidate Name As Per Caste Certificate
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvcname}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.castecertificateno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste Certificate/Receipt No
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.castecertificateno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.castecertificatereceiptno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste Certificate Receipt No
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.castecertificatereceiptno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.castecertificateappdate && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Caste Certificate Application Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.castecertificateappdate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.appliedforews && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Do You Want To Apply For EWS
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.appliedforews) === 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.appliedforews) == 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.ewscertificatestatus !== null &&
              (courseFormDetails[0]?.ewscertificatestatus === 0 ||
                courseFormDetails[0]?.ewscertificatestatus === 1 ||
                courseFormDetails[0]?.ewscertificatestatus === 2) && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      EWS Certificate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.ewscertificatestatus) ===
                      1
                        ? "Available"
                        : parseInt(
                            courseFormDetails[0]?.ewscertificatestatus
                          ) === 2
                        ? "Not Available"
                        : parseInt(
                            courseFormDetails[0]?.ewscertificatestatus
                          ) === 0
                        ? "Applied But Not Received"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

            {courseFormDetails[0]?.ewsapplicationno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    EWS Certificate Application Number
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ewsapplicationno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.ewsapplicationdate && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    EWS Certificate Application Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ewsapplicationdate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.ewsdistrict && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    EWS Certificate Issuing District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ewsdistrict}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.ewstaluka && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    EWS Certificate Issuing Taluka
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ewstaluka}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.nclstatus && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Do You Have Non Creamy Layer Certificate
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.nclstatus) === 1
                      ? "Available"
                      : parseInt(courseFormDetails[0]?.nclstatus) === 2
                      ? "Applied But Not Received"
                      : parseInt(courseFormDetails[0]?.nclstatus) === 3
                      ? "Not Applied"
                      : "-----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.nclapplicationno && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Non Creamy Layer Certificate Application Number
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.nclapplicationno}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.nclapplicationdate && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Non Creamy Layer Certificate Application Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.nclapplicationdate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.nclauthority && (
              <>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    NCL Authority
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.nclauthority}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {/* {(courseFormDetails[0]?.isminority ||
              courseFormDetails[0]?.linguisticminority ||
              courseFormDetails[0]?.religiousminority) && (
              <>
                <Box
                  sx={{
                    border: "1px solid #000",
                    marginTop: ".1rem",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Minority Information
                    </Typography>
                  </Box>
                </Box>
              </>
            )} */}
          <Grid container md={12}>
            {courseFormDetails[0]?.isdisability && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Are You Person With Disability
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.isdisability) == 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.isdisability) == 0
                      ? "No"
                      : "---"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.pwdtype && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Type Of Disability
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.pwdtype}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.pwdsubtype && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    PWD Sub Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.pwdsubtype}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.isextratime && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Require Extra Time
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.isextratime) == 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.isextratime) == 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.isscribe && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Require Scribe
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.isscribe) == 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.isscribe) == 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container md={12}>
            {courseFormDetails[0]?.isminority && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Do You Belong To Minority Category
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.isminority) === 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.isminority) === 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.linguisticminority && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Linguistic Minority
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.linguisticminority}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.religiousminority && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Religious Minority
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.religiousminority}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          {(courseFormDetails[0]?.isorphan ||
            courseFormDetails[0]?.orphantype) && (
            <>
              <Box
                sx={{
                  // border: "1px solid #000",
                  // marginTop: ".1rem",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Orphan Information
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Grid container md={12}>
            {courseFormDetails[0]?.isorphan && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Are You Orphan
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.isorphan) == 1
                      ? "Yes"
                      : parseInt(courseFormDetails[0]?.isorphan) == 0
                      ? "No"
                      : "----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.orphantype && (
              <>
                <Grid item md={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "right",
                      width: "348px",
                    }}
                  >
                    Orphan Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.orphantype}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container md={12}>
            {(courseFormDetails[0]?.issports ||
              courseFormDetails[0]?.sportsparticipation ||
              courseFormDetails[0]?.sportsparticipationyear ||
              courseFormDetails[0]?.sportsrank ||
              courseFormDetails[0]?.sports ||
              courseFormDetails[0]?.sportsbody ||
              courseFormDetails[0]?.isphysicaleducation ||
              courseFormDetails[0]?.isteachingexperience ||
              courseFormDetails[0]?.isappliedforelct ||
              courseFormDetails[0]?.isappliedforksu ||
              courseFormDetails[0]?.isappliedforspecialeducation ||
              courseFormDetails[0]?.specialeducationmethods) && (
              <>
                <Box
                  sx={{
                    // border: "1px solid #000",
                    // marginTop: ".1rem",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Sports information
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            <Grid container md={12}>
              {courseFormDetails[0]?.issports && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Sports
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.issports) === 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sportsparticipation && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Sports Participation Level
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sportsparticipation}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sportsparticipationyear && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Sports Participation Year
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sportsparticipationyear}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sportsrank && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Sports Rank
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sportsrank}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sports && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Sports Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sports}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sportsbody && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Sports Body
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sportsbody}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.isphysicaleducation && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Studied Physical Education
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isphysicaleducation) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isphysicaleducation) ==
                          0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.isteachingexperience && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Teaching Experience
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isteachingexperience) == 1
                        ? "Yes"
                        : parseInt(
                            courseFormDetails[0]?.isteachingexperience
                          ) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.isappliedforelct && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Applied For ELCT
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isappliedforelct) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isappliedforelct) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.isappliedforksu && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Applied For KSU
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isappliedforksu) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isappliedforksu) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.isappliedforspecialeducation && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Is Applied For Special Education
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {parseInt(
                        courseFormDetails[0]?.isappliedforspecialeducation
                      ) == 1
                        ? "Yes"
                        : parseInt(
                            courseFormDetails[0]?.isappliedforspecialeducation
                          ) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.specialeducationmethods && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Special Education Methods
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      {parseInt(
                        courseFormDetails[0]?.specialeducationmethods
                      ) == 1
                        ? "Yes"
                        : parseInt(
                            courseFormDetails[0]?.specialeducationmethods
                          ) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Box
              sx={{
                border: "1px solid #000",
                marginTop: ".1rem",
                width: "100%",
              }}
            >
              {/* <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Category information
                </Typography>
              </Box> */}
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {(courseFormDetails[0]?.sscpassedfromindia ||
            courseFormDetails[0]?.sscschoolstate ||
            courseFormDetails[0]?.sscschooldistrict ||
            courseFormDetails[0]?.sscschooltaluka ||
            courseFormDetails[0]?.sscpassingyear ||
            courseFormDetails[0]?.sscboard ||
            courseFormDetails[0]?.sscschoolname ||
            courseFormDetails[0]?.ssctotalpercentage) && (
            <>
              <Box
                sx={{
                  border: "1px solid #000",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Qualification Details
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",
                  marginTop: ".1rem",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>SSC Information</Typography>
                </Box>
              </Box>
            </>
          )}

          <Grid container md={12}>
            {courseFormDetails[0]?.sscpassedfromindia && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC Passed From India
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscpassedfromindia == 1
                      ? "Yes"
                      : courseFormDetails[0]?.sscpassedfromindia == 0
                      ? "No"
                      : "---"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscschoolstate && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC School State
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscschoolstate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscschooldistrict && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC School District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscschooldistrict}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscschooltaluka && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC School Taluka
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscschooltaluka}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscpassingyear && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC/Equivalent Passing Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscpassingyear}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscboard && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC/Equivalent Board
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscboard}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.sscschoolname && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC School Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.sscschoolname}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.ssctotalpercentage && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    SSC/Equivalent Total Percentage
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ssctotalpercentage}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {(courseFormDetails[0]?.hsccollegestate ||
            courseFormDetails[0]?.hsccollegedistrict ||
            courseFormDetails[0]?.hsccollegetaluka ||
            courseFormDetails[0]?.hsccollegename ||
            courseFormDetails[0]?.hscmarktype ||
            courseFormDetails[0]?.hscmarksobtained ||
            courseFormDetails[0]?.hscmarksoutof ||
            courseFormDetails[0]?.hscpercentage ||
            courseFormDetails[0]?.hscenglishmarksobtained ||
            courseFormDetails[0]?.hscenglishmarksoutof ||
            courseFormDetails[0]?.hscenglishpercentage ||
            courseFormDetails[0]?.hscpassingyear ||
            courseFormDetails[0]?.hscboard) && (
            <Box
              sx={{
                // border: "1px solid #000",
                marginTop: ".1rem",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>HSC Information</Typography>
              </Box>
            </Box>
          )}
          <Grid container md={12}>
            {courseFormDetails[0]?.hsccollegestate && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC College State
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hsccollegestate}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hsccollegedistrict && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC College District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hsccollegedistrict}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hsccollegetaluka && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC College Taluka
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hsccollegetaluka}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscpassingyear && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC Passing Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscpassingyear}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscboard && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC Board
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscboard}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hsccollegename && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC College Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hsccollegename}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscmarktype && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Marks Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.hscmarktype) === 1
                      ? "CGPA"
                      : parseInt(courseFormDetails[0]?.hscmarktype) === 2
                      ? "Percentage"
                      : "-----"}
                  </Typography>
                </Grid>
              </>
            )}
            {courseFormDetails[0]?.hscmarksobtained && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC Marks Obtained
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscmarksobtained}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscmarksoutof && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC Marks Out Of
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscmarksoutof}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscpercentage && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC Percentage
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscpercentage}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscenglishmarksobtained && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC English Marks Obtained
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscenglishmarksobtained}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscenglishmarksoutof && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC English Marks Out Of
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscenglishmarksoutof}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.hscenglishpercentage && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    HSC English Percentage
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.hscenglishpercentage}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          {(courseFormDetails[0]?.eligibilityqualification ||
            courseFormDetails[0]?.graduationstatus ||
            courseFormDetails[0]?.graduationpassingyear ||
            courseFormDetails[0]?.graduationbranch ||
            courseFormDetails[0]?.graduationmarktype ||
            courseFormDetails[0]?.graduationmarksobtained ||
            courseFormDetails[0]?.graduationmarksoutof ||
            courseFormDetails[0]?.graduationpercentage ||
            courseFormDetails[0]?.awardedclass ||
            courseFormDetails[0]?.gradudationduration) && (
            <Box
              sx={{
                // border: "1px solid #000",
                marginTop: ".1rem",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Graduation Information
                </Typography>
              </Box>
            </Box>
          )}
          <Grid container md={12}>
            {courseFormDetails[0]?.eligibilityqualification && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Eligiblity Qualification
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.eligibilityqualification}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationstatus && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Status
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationstatus == 1
                      ? "Appearing"
                      : courseFormDetails[0]?.graduationstatus == 2
                      ? "Passed"
                      : courseFormDetails[0]?.graduationstatus == 0
                      ? "Not Applicable"
                      : "---"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationpassingyear && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Passing Year
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationpassingyear}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationbranch && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Branch Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationbranch}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationmarktype && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Marks Type
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {parseInt(courseFormDetails[0]?.graduationmarktype) == 1
                      ? "Percentage"
                      : parseInt(courseFormDetails[0]?.graduationmarktype) === 2
                      ? "CGPA"
                      : "-----"}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationmarksobtained && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Marks Obtained
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationmarksobtained}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationmarksoutof && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Marks Out Of
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationmarksoutof}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.graduationpercentage && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Percentage
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.graduationpercentage}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.awardedclass && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Awarded Class Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.awardedclass}
                  </Typography>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.gradudationduration && (
              <>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Graduation Duration
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.gradudationduration}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          {(courseFormDetails[0]?.subjectgroup ||
            courseFormDetails[0]?.examstate ||
            courseFormDetails[0]?.language ||
            courseFormDetails[0]?.preference1district ||
            courseFormDetails[0]?.preference2district ||
            courseFormDetails[0]?.preference3district ||
            courseFormDetails[0]?.preference4district) && (
            <>
              <Box
                sx={{
                  border: "1px solid #000",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Exam Center Selection Details
                  </Typography>
                </Box>
                {location.search !== "?page=printapplication" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(
                        `/${Cookies.get(
                          "mhet_cnd_project"
                        )}/course-registration?page=examinationdetails`,
                        {
                          state: {
                            courseid: courseid,
                            coursename: coursename,
                            status: status >= 5 ? 1 : 0,
                          },
                        }
                      )
                    }
                  >
                    <MdOutlineModeEdit size={20} /> &nbsp;
                    <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
          {courseFormDetails[0]?.subjectgroup && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Subject Group for Exam
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.subjectgroup}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.examstate && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Examination Center State
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.examstate?.map((val, i) => (
                      <Typography
                        key={i}
                        sx={{
                          fontSize: 15,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {courseFormDetails[0]?.examstate.length > 1 &&
                          `${i + 1}.`}
                        {val}
                      </Typography>
                    ))}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.language && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Language / Medium of the Question Paper
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  {courseFormDetails[0]?.language?.map((val, i) => (
                    <Typography
                      key={i}
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.language.length > 1 && `${i + 1}.`}
                      {val}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.preference1district && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Examination Center District at Preference Number 1
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.preference1district}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.preference2district && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Examination Center District at Preference Number 2
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.preference2district}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.preference3district && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Examination Center District at Preference Number 3
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.preference3district}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {courseFormDetails[0]?.preference4district && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Examination Center District at Preference Number 4
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails?.length > 0 &&
                      (courseFormDetails[0]?.preference4district || "-----")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}

          {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Post Graduation Information</Typography>
            </Box>
          </Box> */}
          {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Document For Type A
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Document Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                CET Exam Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Whether Pass Institution Situated MH?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
             <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                PF Graduation Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Internship Training Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                MH Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Re Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
             <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Already Admitted
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                College Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                University Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
          {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>First Year Marks</Typography>
            </Box>
          </Box> */}
          {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
          {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Second Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Third Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Fourth Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Aggregate Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>

        {/* <Grid item md={12} xs={12}>
          <Box
            sx={{
              border: "1px solid #000",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Photograph and Signature
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    {
                      state: {
                        courseid: courseid,
                        coursename: coursename,
                        status: status >= 5 ? 1 : 0,
                      },
                    }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photograph
              </Typography>
            </Grid>
            <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails[0]?.document}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "53px", width: "auto" }}
                src={uploadDetails[1]?.document}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>
*/}
        <Grid item md={12} xs={12}>
          <Grid
            container
            // md={12}
            // xs={12}

            sx={{}}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
            </Box>
          </Grid>
          <Grid container md={12}>
            <Grid item md={2} xs={2} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "center",

                  fontWeight: 500,
                }}
              >
                S.No
              </Typography>
            </Grid>
            <Grid item md={10} xs={10} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "left",

                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Document Name
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={2} xs={2} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                1
              </Typography>
            </Grid>
            <Grid item md={10} xs={10} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {uploadDetails[0]?.documentname}
              </Typography>
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="a"
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",

                  fontWeight: 500,
                  textAlign: "center",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                target="_blank"
                href={pdfUrl}
              >
                View
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} sx={{ border: "1px solid #000" }}>
          <Box
            sx={{
              width: "696px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 12, px: 1, fontWeight: 600 }}>
                Note :
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 3,
                  fontSize: 12,
                  color: "#e6393f",
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste Certificate clearly
                  mentioning the Category of the Candidate and also the remarks
                  that the Caste is recognised as backward class in the State of
                  Maharashtra at the time of Centralised Admission Process(CAP
                  2024).
                </ListItem>

                <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste / Tribe Validity
                  Certificate in the name of the Candidate, issued by the caste
                  validity committee of Maharashtra State at the time of
                  Centralised Admission Process(CAP 2024).
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #000",
              width: "696px",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                px: 1,
                textAlign: "left",
                color: "black",
                fontWeight: 600,
                textAlign: "left",
              }}
            >
              Declaration:
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                px: 1,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              I {courseFormDetails[0]?.candidatename} have downloaded the
              Information Brochure of {coursename} and have read all the rules
              for appearing the CET and on understanding these Rules, I have
              filled this Application Form for appearing to {coursename} for the
              Academic Year 2024-25. This information given by me in this
              application is true to the best of my knowledge & belief. If at
              later stage, it is found that I have furnished wrong informatation
              and/or submitted false certificate(s), I am aware that my
              admission stands cancelled and fees paid by me will be forfeited.
              Further I will be subject to legal and/or penal action as per the
              provisions of the law.
            </Typography>
          </Box>
          <Grid container sx={{ width: "598px" }}>
            <Grid item md={8} xs={8}>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Date :
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Last Modified By :
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Last Modified On :
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Printed On :
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={4}>
              <Box
                sx={{
                  border: "1px solid #000",
                  display: "grid",
                  placeItems: "center",
                  width: "298px",
                  height: "100%",
                }}
              >
               <img
                style={{ width: "75px", height: "auto",aspectRatio:'4/3' }}
                src={courseUploadDetails ? courseUploadDetails?.find((item,index)=>item?.documenttype==2)?.fileurl : ""}
                alt="signatute"
              />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import OtherDetailsForm from "./otherDetailsForm";
import UploadsForm from "./uploadsForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { RiFileUserLine } from "react-icons/ri";
import { IoIosHome } from "react-icons/io";
import { PiGraduationCap } from "react-icons/pi";
import { IoDocumentTextOutline } from "react-icons/io5";

import { cssProperties } from "../../utils/commonCssProperties";
import candidatesActions from "../../redux/candidates/actions";
import { FaCheckCircle } from "react-icons/fa";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");
  const [registrationSection, setRegistrationSection] =
    useState("personaldetails");

  useEffect(() => {
    const currentActiveTab =
      Page === "personaldetails"
        ? 0
        : Page === "address"
        ? 1
        : Page === "otherdetails"
        ? 2
        : Page === "uploads"
        ? 3
        : null;

    if (currentActiveTab !== null) {
      setActiveTab(currentActiveTab);
    }
    setRegistrationSection(Page);
  }, [Page]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [Page]);
  const { candidateTokenDetails, profileRegistrationActiveTab } = useSelector(
    (state) => state.authReducer
  );

console.log(profileRegistrationActiveTab,activeTab,'profileRegistrationActiveTab')


  useEffect(() => {
    if (candidateTokenDetails?.candidateid) {
      dispatch({
        type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
        payload: { id: candidateTokenDetails?.candidateid },
      });
    }
  }, [candidateTokenDetails]);

  const RegistrationData = [
    {
      title: "Personal Details",
      icon: (
        <span
          style={{
            ...cssProperties.spanstyle,
            backgroundColor:
              profileRegistrationActiveTab?.candidate_status === 2
                ? "white"
                : activeTab === 0
                ? "#0F4F96"
                : "transparent",
          }}
        >
          {profileRegistrationActiveTab?.candidate_status === 2 ? (
            <FaCheckCircle
              style={{ color: "green", ...cssProperties.iconstyle }}
            />
          ) : (
            <RiFileUserLine
              style={{
                width: "23px",
                height: "23px",
                marginTop: "2px",
                marginLeft: "1px",
                color:
                  activeTab === 0 ? "white" : `${cssProperties.color.primary}`,
              }}
            />
          )}
        </span>
      ),
      to: (() => {
        if (profileRegistrationActiveTab?.candidate_status === 2) {
          dispatch({ type: candidatesActions.SHOW_IMAGE, payload: null });
          dispatch({ type: candidatesActions.SHOW_SIGNATURE, payload: null });
          return `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=personaldetails`;
        }
      })(),
    },
    {
      title: "Address",
      icon: (
        <span
          style={{
            ...cssProperties.spanstyle,
            backgroundColor:
              profileRegistrationActiveTab?.address_status === 2
                ? "white"
                : activeTab === 1
                ? "#0F4F96"
                : "white",
          }}
        >
          {profileRegistrationActiveTab?.address_status === 2 ? (
            <FaCheckCircle
              style={{ color: "green", ...cssProperties.iconstyle }}
            />
          ) : (
            <IoIosHome
              style={{
                width: "23px",
                height: "23px",
                marginTop: "2px",
                color:
                  activeTab === 1 ? "white" :  '#658F9D',
              }}
            />
          )}
        </span>
      ),
      to: (() => {
        if (profileRegistrationActiveTab?.candidate_status === 2) {
          dispatch({ type: candidatesActions.SHOW_IMAGE, payload: null });
          dispatch({ type: candidatesActions.SHOW_SIGNATURE, payload: null });
          return `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=address`;
        }
      })(),
    },
    {
      title: "Other Details",
      icon: (
        <span
          style={{
            ...cssProperties.spanstyle,
            backgroundColor:
              profileRegistrationActiveTab?.other_status === 2
                ? "white"
                : activeTab === 2
                ? "#0F4F96"
                : "white",
          }}
        >
          {profileRegistrationActiveTab?.other_status === 2 ? (
            <FaCheckCircle
              style={{ color: "green", ...cssProperties.iconstyle }}
            />
          ) : (
            <PiGraduationCap
              style={{
                width: "23px",
                height: "23px",
                marginTop: "2px",
                color:
                  activeTab === 2 ? "white" :  '#658F9D',
              }}
            />
          )}
        </span>
      ),
      to: (() => {
        if (profileRegistrationActiveTab?.candidate_status === 2) {
          dispatch({ type: candidatesActions.SHOW_IMAGE, payload: null });
          dispatch({ type: candidatesActions.SHOW_SIGNATURE, payload: null });
          return `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=otherdetails`;
        }
      })(),
    },
    {
      title: "Uploads",
      icon: (
        <span
          style={{
            ...cssProperties.spanstyle,
            backgroundColor:
              profileRegistrationActiveTab?.cu1_status === 2 &&
              profileRegistrationActiveTab?.cu2_status === 2 &&
              profileRegistrationActiveTab?.cu3_status === 2
                ? "white"
                : activeTab === 3
                ? "#0F4F96"
                : "white",
          }}
        >
          {profileRegistrationActiveTab?.cu1_status === 2 &&
          profileRegistrationActiveTab?.cu2_status === 2 &&
          profileRegistrationActiveTab?.cu3_status === 2 ? (
            <FaCheckCircle
              style={{ color: "green", ...cssProperties.iconstyle }}
            />
          ) : (
            <IoDocumentTextOutline
              style={{
                width: "23px",
                height: "23px",
                marginTop: "2px",
                marginLeft: "1px",
                color:
                  activeTab === 3 ? "white" : '#658F9D',
              }}
            />
          )}
        </span>
      ),
      to: (() => {
        if (profileRegistrationActiveTab?.candidate_status === 2) {
          dispatch({ type: candidatesActions.SHOW_IMAGE, payload: null });
          dispatch({ type: candidatesActions.SHOW_SIGNATURE, payload: null });
          return `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=uploads`;
        }
      })(),
    },
  ];

  useEffect(() => {
    if (!profileRegistrationActiveTab) {
      return;
    } else if (
      profileRegistrationActiveTab &&
      profileRegistrationActiveTab === "new"
    ) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/registration?page=personaldetails`
      );
    } else {
      if (
        !profileRegistrationActiveTab?.candidate_status &&
        profileRegistrationActiveTab?.other_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=uploads`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        profileRegistrationActiveTab?.address_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        profileRegistrationActiveTab?.candidate_status === 2
      ) {
        navigate(
          `/${Cookies.get("mhet_cnd_project")}/registration?page=address`
        );
      } else if (
        !profileRegistrationActiveTab?.candidate_status &&
        !profileRegistrationActiveTab?.other_status &&
        !profileRegistrationActiveTab?.address_status &&
        !profileRegistrationActiveTab?.candidate_status
      ) {
        navigate(
          `/${Cookies.get(
            "mhet_cnd_project"
          )}/registration?page=personaldetails`
        );
      }
    }
  }, [profileRegistrationActiveTab]);

  const preprocessFormData = (data) => {
    const processedData = { ...data };
    for (const key in processedData) {
      if (processedData.hasOwnProperty(key)) {
        if (
          typeof processedData[key] === "string" &&
          (processedData[key].trim() === "" ||
            processedData[key].toLowerCase() === "null")
        ) {
          processedData[key] = null;
        }
      }
    }
    return processedData;
  };

  return (
    <>
      <Tabs
        activeTab={activeTab}
        RegistrationData={RegistrationData}
        profileRegistrationActiveTab={profileRegistrationActiveTab}
      />
      {registrationSection === "personaldetails" && (
        <PersonalDetailsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "address" && (
        <AddressForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "otherdetails" && (
        <OtherDetailsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "uploads" && (
        <UploadsForm preprocessFormData={preprocessFormData} />
      )}
    </>
  );
}

export default Index;

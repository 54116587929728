import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  useMediaQuery,
  MenuItem,
  ListSubheader,
  Select,
  Typography,
  useTheme,
  Box,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/action";
import SubmitButton from "../../common/submitButton";
import { useLocation, useNavigate } from "react-router-dom";
import candidatesActions from "../../redux/candidates/actions";

import { masterFieldLabel, isFieldDisabled } from "../../utils/courseValues";
import BackButton from "../../common/backButton";
import Cookies from "js-cookie";

export default function ExamCenterForm({ nextPageName, previousPageName }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  const [filteredDistricts, setFilteredDistricts] = useState([]);

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { masterFields } = useSelector((state) => state.candidatesReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const { courseFullRegistrationDetails, courseFormDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const { examState, examDistrict, examLanguage } = useSelector(
    (state) => state.candidatesReducer
  );
  const isPaid = courseFormDetails[0]?.ispaid;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: {
          courseid: courseid,
        },
      });
    }
  }, [candidateid]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch({ type: masterActions.GET_STATE });
  //     // dispatch({
  //     //   type: candidatesActions.GET_EXAM_DISTRICT,
  //     //   payload: getValues("stateid"),
  //     // });
  //   }, 2000);
  // }, []);

  useEffect(() => {
    if (courseFullRegistrationDetails?.length > 0) {
      reset({ ...courseFullRegistrationDetails[0] });
      setValue(
        "preference1districtid",
        courseFullRegistrationDetails[0]?.preference1districtid
      );
      console.log("stateid---------", getValues("stateid"), "....");
    }
  }, [courseFullRegistrationDetails]);
  console.log(courseFullRegistrationDetails);

  const handleProcced = (data) => {
    console.log(data, "data....");
    const corcandidatepreferenceid =
      courseFullRegistrationDetails[0]?.corcandidatepreferenceid;
    dispatch({
      type: candidatesActions.COURSE_EXAM_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: corcandidatepreferenceid
            ? parseInt(corcandidatepreferenceid)
            : 0,
          candidateid: candidateid,
          status: 1,
          courseid: courseid,
          coursename: coursename,
          createdby: candidateid,
          updatedby: corcandidatepreferenceid ? parseInt(candidateid) : "",
        },
        navigate: navigate,
        nextPageName: nextPageName,
      },
    });
  };

  const handleBack = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=${previousPageName}`,
      { state: { courseid: courseid, coursename: coursename } }
    );
  };

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_STATE,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_DISTRICT,
        payload: {
          data: { courseid: courseid },
        },
      });
      dispatch({
        type: candidatesActions.GET_EXAM_LANGUAGE,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  // useEffect(() => {
  //   if (watch("stateid") && !isNaN(parseInt(watch("stateid")))) {
  //     let filtered = examDistrict?.filter(
  //       (obj) => parseInt(obj.stateid) === parseInt(watch("stateid"))
  //     );
  //     console.log(examDistrict, watch("stateid"), filtered, "filtered dist");
  //     setFilteredDistricts(filtered);
  //   }
  // }, [watch("stateid"), examDistrict]);
  const groupedData = (data) => {
    return data.reduce((acc, curr) => {
      const { stateid, statename, district, districtid } = curr;

      if (!acc[stateid]) {
        acc[stateid] = { statename, districts: [] };
      }
      acc[stateid].districts.push({ district, districtid });
      return acc;
    }, {});
  };

  // console.log(groupedData(examDistrict?.all), "examDistrict");

  useEffect(() => {
    console.log(watch("stateid"), "watch");
    let states = watch("stateid");
    if (states?.length > 0) {
      let filtered = examDistrict?.all?.filter((obj) =>
        states?.map((id) => parseInt(id)).includes(parseInt(obj.stateid))
      );
      console.log(filtered.length, examDistrict?.length, "length");
      dispatch({
        type: candidatesActions.SET_EXAM_DISTRICT,
        payload: { ...examDistrict, filtered: filtered },
      });
    } else {
      // console.log('in else ',examDistrict)
      dispatch({
        type: candidatesActions.SET_EXAM_DISTRICT,
        payload: { ...examDistrict, filtered: [] },
      });
    }
  }, [watch("stateid")]);

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #0F4F96",
      }}
    >
      <Typography
        pb={2}
        variant="h4"
        sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 }}
      >
        Exam Center Selection
      </Typography>

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel === masterFieldLabel.SELECT_SUBJECT_GROUP
      ) && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SELECT_SUBJECT_GROUP
                )?.fieldlabel
              }

              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SELECT_SUBJECT_GROUP
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.subjectgroup}>
              <Controller
                name="subjectgroup"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_SUBJECT_GROUP
                    )?.ismandatory === "1"
                      ? "Select Subject Group is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="subjectgroup"
                    value={watch("subjectgroup") || ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.SELECT_SUBJECT_GROUP,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select one
                    </MenuItem>
                    <MenuItem value="PCM">PCM</MenuItem>
                    <MenuItem value="PCB">PCB</MenuItem>
                    <MenuItem value="PCM and PCB">PCM & PCB</MenuItem>
                  </Select>
                )}
              />

              {errors.subjectgroup && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem", color: "red" }}
                >
                  {errors.subjectgroup.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) => field.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel === masterFieldLabel.SELECT_LANGUAGE
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.language}>
              <Controller
                name="language"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_LANGUAGE
                    )?.ismandatory === "1"
                      ? "Select Language is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    multiple
                    fullWidth
                    value={field.value || []}
                    inputProps={{
                      style: { height: 40, boxSizing: "border-box" },
                    }}
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      field.onChange(selectedValues);
                      console.log(selectedValues);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedLanguage = examLanguage?.find(
                            (lang) => lang?.languagename === value
                          );
                          console.log(
                            selectedLanguage?.languagename,
                            "languagename"
                          );

                          return (
                            <Chip
                              key={value}
                              label={selectedLanguage?.languagename}
                            />
                          );
                        })}
                      </Box>
                    )}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.SELECT_LANGUAGE,
                      null,
                      isPaid
                    )}
                  >
                    {examLanguage?.map((val, i) => (
                      <MenuItem key={i} value={val?.languagename}>
                        {val?.languagename}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.language && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.language.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.SELECT_STATE_FOR_EXAMINATION_CENTER
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.SELECT_STATE_FOR_EXAMINATION_CENTER
                )?.fieldlabel
              }{" "}
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.SELECT_STATE_FOR_EXAMINATION_CENTER
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.stateid}>
              {/* <InputLabel
              shrink={!!watch("examstate") || watch("examstate") === 0}
              htmlFor="genderid"
            >
              Select state for MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination
              Centre District<span className="error">*</span>
            </InputLabel> */}

              <Controller
                name="stateid"
                control={control}
                defaultValue={[]}
                rules={{ required: "Preference States is required" }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="stateid"
                    multiple
                    //   label="Select state for  MAH-B.BCA/BBA/BMS/BBM- CET 2024 Examination Centre District"
                    // value={getValues("pstateid")}
                    {...register("stateid", {
                      required:
                        masterFields?.find(
                          (field) =>
                            field?.masterfieldlabel ===
                            masterFieldLabel.SELECT_STATE_FOR_EXAMINATION_CENTER
                        )?.ismandatory === "1"
                          ? "State is required"
                          : false,
                    })}
                    onChange={(e) => {
                      setValue("preference1districtid", "");
                      setValue("preference2districtid", "");
                      setValue("preference3districtid", "");
                      setValue("preference4districtid", "");
                      // const stateid = e.target.value;
                      // setValue("stateid", stateid);
                      // dispatch({
                      //   type: masterActions.GET_DISTRICT,
                      //   payload: stateid,
                      // });
                      // trigger("stateid");
                      const selectedValues = e.target.value;
                      const validValues = selectedValues.filter(
                        (val) => val !== null && val !== undefined
                      );
                      const uniqueValues = Array.from(
                        new Set(validValues.map((val) => val?.toString()))
                      );

                      field.onChange(uniqueValues);
                      setValue("preference1districtid", "");
                      setValue("preference2districtid", "");
                      setValue("preference3districtid", "");
                      setValue("preference4districtid", "");
                    }}
                    value={watch("stateid") ?? []}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.SELECT_STATE_FOR_EXAMINATION_CENTER,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const selectedState = examState?.find(
                            (state) => parseInt(state?.stateid) === parseInt(value)
                          );
                          // console.log(selected,'selected')
                          // console.log(
                          //   selectedState?.state,
                          //   "languagename"
                          // );

                          return (
                            <Chip
                              key={value}
                              label={selectedState?.state}
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {examState?.map((val, i) => (
                      <MenuItem value={val?.stateid} key={i}>
                        {val?.state}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.stateid && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.stateid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.preference1districtid}>
              <Controller
                name="preference1districtid"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1
                    )?.ismandatory === "1"
                      ? "District is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="preference1districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_1,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      console.log(selectedValue, "selectedValue");
                      setValue(field.name, selectedValue);
                      trigger("preference1districtid");
                      const preferences = [
                        "preference2districtid",
                        "preference3districtid",
                        "preference4districtid",
                      ];
                      preferences.forEach((pref) => {
                        if (e.target.value === watch(pref)) {
                          setValue(pref, "");
                        }
                      });
                    }}
                  >
                    {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                    {/* {examDistrict?.filtered?.map((val, i) => (
                      <MenuItem value={val?.districtid} key={i}>
                        {val?.district}
                      </MenuItem>
                    ))} */}
                    {Object.keys(groupedData(examDistrict?.filtered))?.map(
                      (stateId, i) => [
                        <ListSubheader key={`header-${stateId}`}>
                          {
                            groupedData(examDistrict?.filtered)[stateId]
                              ?.statename
                          }
                        </ListSubheader>,
                        groupedData(examDistrict?.filtered)[stateId]?.
                        districts.map(({ district, districtid }) => (
                          <MenuItem
                            onClick={(e) => {
                              console.log(districtid, "parseInt(districtid)");
                              setValue(field.name, districtid.toString());
                              trigger("preference1districtid");
                            }}
                            key={districtid}
                            value={parseInt(districtid)}
                          >
                            {district}
                          </MenuItem>
                        )),
                      ]

                      // <React.Fragment key={i}>
                      //   <ListSubheader key={i}>
                      //     {
                      //       groupedData(examDistrict?.filtered)[stateId]
                      //         ?.statename
                      //     }
                      //   </ListSubheader>
                      //   {groupedData(examDistrict?.filtered)[
                      //     stateId
                      //   ]?.districts.map(({ district, districtid }) => (
                      //     <MenuItem
                      //       onClick={(e) => {
                      //         console.log(districtid,'parseInt(districtid)')
                      //         setValue(field.name, districtid.toString());
                      //         trigger("preference1districtid");
                      //       }}
                      //       key={districtid}
                      //       value={parseInt(districtid)}
                      //     >
                      //       {district}
                      //     </MenuItem>
                      //   ))}
                      // </React.Fragment>
                    )}
                  </Select>
                )}
              />
              {errors.preference1districtid && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.preference1districtid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.preference2districtid}>
              <Controller
                name="preference2districtid"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2
                    )?.ismandatory === "1"
                      ? "District is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="preference2districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_2,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setValue(field.name, selectedValue);
                      trigger("preference2districtid");
                      const preferences = [
                        "preference1districtid",
                        "preference3districtid",
                        "preference4districtid",
                      ];
                      preferences.forEach((pref) => {
                        if (e.target.value === watch(pref)) {
                          setValue(pref, "");
                        }
                      });
                    }}
                  >
                    {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                    {/* {examDistrict
                      ?.filtered?.filter(
                        (val) =>
                          val.districtid !== watch("preference1districtid")
                      )
                      .map((val, i) => (
                        <MenuItem value={val?.districtid} key={i}>
                          {val?.district}
                        </MenuItem>
                      ))} */}
                    {Object.keys(
                      groupedData(
                        examDistrict?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference1districtid")
                        )
                      )
                    )?.map((stateId) => (
                      [
                        <ListSubheader key={`header-${stateId}`}>
                          {
                            groupedData( examDistrict?.filtered?.filter(
                              (val) =>
                                val.districtid !== watch("preference1districtid")
                            ))[stateId]
                              ?.statename
                          }
                        </ListSubheader>,
                        groupedData( examDistrict?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference1districtid")
                        ))[stateId]?.
                        districts.map(({ district, districtid }) => (
                          <MenuItem
                            onClick={(e) => {
                              console.log(districtid, "parseInt(districtid)");
                              setValue(field.name, districtid.toString());
                              trigger("preference1districtid");
                            }}
                            key={districtid}
                            value={parseInt(districtid)}
                          >
                            {district}
                          </MenuItem>
                        )),
                      ]
                    ))}
                  </Select>
                )}
              />
              {errors.preference2districtid && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.preference2districtid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      {masterFields?.find(
        (field) =>
          field.masterfieldlabel ===
          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
      ) && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={5}>
            <Typography>
              {
                masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                )?.fieldlabel
              }
              {masterFields?.find(
                (field) =>
                  field?.masterfieldlabel ===
                  masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
              )?.ismandatory === "1" && (
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth error={!!errors.preference3districtid}>
              <Controller
                name="preference3districtid"
                control={control}
                rules={{
                  required:
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3
                    )?.ismandatory === "1"
                      ? "District is required"
                      : false,
                }}
                render={({ field }) => (
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    {...field}
                    id="preference3districtid"
                    value={field.value ?? ""}
                    disabled={isFieldDisabled(
                      masterFields,
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_3,
                      null,
                      isPaid
                    )}
                    inputProps={{
                      style: { height: 56, boxSizing: "border-box" },
                    }}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setValue(field.name, selectedValue);
                      trigger("preference3districtid");
                      const preferences = [
                        "preference1districtid",
                        "preference2districtid",
                        "preference4districtid",
                      ];
                      preferences.forEach((pref) => {
                        if (e.target.value === watch(pref)) {
                          setValue(pref, "");
                        }
                      });
                    }}
                  >
                    {/* <MenuItem value={0}>Not Applicable</MenuItem> */}

                    {Object.keys(
                      groupedData(
                        examDistrict?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference2districtid") &&
                            val.districtid !== watch("preference1districtid")
                        )
                      )
                    )?.map((stateId) => (
                      [
                        <ListSubheader key={`header-${stateId}`}>
                          {
                            groupedData( examDistrict?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference2districtid") &&
                            val.districtid !== watch("preference1districtid")
                        ))[stateId]
                              ?.statename
                          }
                        </ListSubheader>,
                        groupedData( examDistrict?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference2districtid") &&
                            val.districtid !== watch("preference1districtid")
                        ))[stateId]?.
                        districts.map(({ district, districtid }) => (
                          <MenuItem
                            onClick={(e) => {
                              console.log(districtid, "parseInt(districtid)");
                              setValue(field.name, districtid.toString());
                              trigger("preference1districtid");
                            }}
                            key={districtid}
                            value={parseInt(districtid)}
                          >
                            {district}
                          </MenuItem>
                        )),
                      ]
                    ))}
                  </Select>
                )}
              />
              {errors.preference3districtid && (
                <small
                  className="error"
                  style={{ margin: 0, marginTop: ".2rem" }}
                >
                  {errors.preference3districtid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mt={1}>
        {masterFields?.find(
          (field) =>
            field.masterfieldlabel ===
            masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
        ) && (
          <>
            <Grid item xs={12} md={5}>
              <Typography>
                {
                  masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                  )?.fieldlabel
                }
                {masterFields?.find(
                  (field) =>
                    field?.masterfieldlabel ===
                    masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                )?.ismandatory === "1" && (
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth error={!!errors.preference4districtid}>
                <Controller
                  name="preference4districtid"
                  control={control}
                  rules={{
                    required:
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4
                      )?.ismandatory === "1"
                        ? "District is required"
                        : false,
                  }}
                  render={({ field }) => (
                    <Select
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      {...field}
                      id="preference4districtid"
                      value={field.value ?? ""}
                      disabled={isFieldDisabled(
                        masterFields,
                        masterFieldLabel.EXAM_CENTER_DISTRICT_PREFERENCE_4,
                        null,
                        isPaid
                      )}
                      inputProps={{
                        style: { height: 56, boxSizing: "border-box" },
                      }}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setValue(field.name, selectedValue);
                        trigger("preference4districtid");
                        const preferences = [
                          "preference1districtid",
                          "preference2districtid",
                          "preference3districtid",
                        ];
                        preferences.forEach((pref) => {
                          if (e.target.value === watch(pref)) {
                            setValue(pref, "");
                          }
                        });
                      }}
                    >
                      {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                      {/* {examDistrict
                        ?.filtered?.filter(
                          (val) =>
                            val.districtid !== watch("preference2districtid") &&
                            val.districtid !== watch("preference1districtid") &&
                            val.districtid !== watch("preference3districtid")
                        )
                        .map((val, i) => (
                          <MenuItem value={val.districtid} key={i}>
                            {val.district}
                          </MenuItem>
                        ))} */}
                      {Object.keys(
                        groupedData(
                          examDistrict?.filtered?.filter(
                            (val) =>
                              val.districtid !==
                                watch("preference2districtid") &&
                              val.districtid !==
                                watch("preference1districtid") &&
                              val.districtid !== watch("preference3districtid")
                          )
                        )
                      )?.map((stateId) => (
                        [
                          <ListSubheader key={`header-${stateId}`}>
                            {
                              groupedData( examDistrict?.filtered?.filter(
                            (val) =>
                              val.districtid !==
                                watch("preference2districtid") &&
                              val.districtid !==
                                watch("preference1districtid") &&
                              val.districtid !== watch("preference3districtid")
                          ))[stateId]
                                ?.statename
                            }
                          </ListSubheader>,
                          groupedData( examDistrict?.filtered?.filter(
                            (val) =>
                              val.districtid !==
                                watch("preference2districtid") &&
                              val.districtid !==
                                watch("preference1districtid") &&
                              val.districtid !== watch("preference3districtid")
                          ))[stateId]?.
                          districts.map(({ district, districtid }) => (
                            <MenuItem
                              onClick={(e) => {
                                console.log(districtid, "parseInt(districtid)");
                                setValue(field.name, districtid.toString());
                                trigger("preference1districtid");
                              }}
                              key={districtid}
                              value={parseInt(districtid)}
                            >
                              {district}
                            </MenuItem>
                          )),
                        ]
                      ))}
                    </Select>
                  )}
                />
                {errors.preference4districtid && (
                  <small
                    className="error"
                    style={{ margin: 0, marginTop: ".2rem" }}
                  >
                    {errors.preference4districtid.message}
                  </small>
                )}
              </FormControl>
            </Grid>
          </>
        )}

        <Grid
          item
          md={12}
          pt={2}
          pb={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          <BackButton handleBack={handleBack} />
          <SubmitButton
            disabled={isPaid === "1"}
            name={buttonLoader ? "loader" : "Save and Proceed"}
            handleSubmit={handleSubmit(handleProcced)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

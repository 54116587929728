import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { MdOutlineCancel } from "react-icons/md";
import PrimaryButton from '../primaryButton';
import { Link } from 'react-router-dom';

const ResetPasswordFaillure = () => {
    return (
        <div>
            <Grid
                sx={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px"
                }}
            >
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        borderRadius: 2,
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <MdOutlineCancel
                        size={45}
                        style={{ fontSize: 90, color: "#E6393E" }}
                    />
                    <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 500, color: '#212121', mt: 2, textAlign: "center" }}>
                        Your reset password time has been expired.please try again
                    </Typography>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                        <Box pt={2}>
                            <PrimaryButton title="Try Again" />
                        </Box>
                    </Link>
                </Box>
            </Grid>
        </div>
    )
}

export default ResetPasswordFaillure
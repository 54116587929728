import authActions from "./actions";

const initialState = {
    candidateTokenDetails: null,
    profileRegistrationActiveTab: null,
    forgotPass_MobileVerification: {
        validated: false
    },
    tempCandidateDetails:[]
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActions.SET_CANDIDATE_TOKEN_DETAILS:
            return {
                ...state,
                candidateTokenDetails: action.payload
            }
        case authActions.SET_PROFILE_REGISTRATION_ACTIVE_TAB:
            return {
                ...state,
                profileRegistrationActiveTab: action.payload
            }
        case authActions.FORGOTPASSWORD_MOBILEVERIFICATION:
            return {
                ...state,
                forgotPass_MobileVerification: action.payload
            }
        case authActions.SET_TEMP_CANDIDATE_DETAILS:
            return{
                ...state,
                tempCandidateDetails: action.payload
            }    
        default:
            return state;
    };
};

export default authReducer;
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  colors,
  Divider,
  css,
  Button,
  Checkbox,
} from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { useNavigate } from "react-router-dom";
import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";
import Cookies from "js-cookie";
import { EncryptFunction } from "../utils/cryptoFunction";
import SaveButton from "./saveButton";
import MauvelousPrimaryButton from "./button/mauvelousPrimaryButton";
import PrintDownloadButton from "./button/printDownloadButton";

const tableheadstyle = {
  color: `${cssProperties?.color?.primary}`,
  borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "17.6px",
  letterSpacing: "0.064px",
};

const tablebodystyle = {
  paddingTop: "16px",
  paddingBottom: "20px",
  color: `${cssProperties?.fontcolor?.tableblack}`,
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "17.6px",
  letterSpacing: "0.038px",
  borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
  wordWrap:"break-word",
};

const currentStatusStyles = {
  published: {
    color: "green",
  },
  "yet to be published": {
    color: "red",
  },
  "generated": {
    color: "green",
  },
  "not generated" : {
    color : "red"
  }
};

const explanationStatusStyles = {
  "Explanation uploaded": {
    color: "green",
  },
  "Upload explanation": {
    color: "red",
  },
};

const queryList_Closed = {
  color: "rgba(34, 160, 107, 1)",
};

const queryList_Assigned = {
  color: "rgba(32, 50, 114, 1)",
};  

const queryList_Open = {
  color: "#22BB33",
};

const queryListMap = {
  Closed: queryList_Closed,
  Open: queryList_Open,
  Assigned: queryList_Assigned,
};

export default function DataTable(props) {
  const {
    keys,
    values,
    page,
    handlePaymentReceipt,
    handleViewImage,
    handleaAnsKeys,
    handleObjection,
    handleViewImageAdmitCard,handleView
  } = props;

  return (
    <Box sx={{ paddingTop: "12px" }}>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`,
          borderRadius: "4px",
          padding: "24px 12px 16px 12px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {keys?.map((val, i) => (
                <TableCell sx={{ ...tableheadstyle }} key={i}>
                  {val?.name}
                </TableCell>
              ))}
              {page === "paymentReceiptList" && (
                <TableCell sx={{ ...tableheadstyle }}>View Receipt</TableCell>
              )}
            </TableRow>
          </TableHead>
          {values?.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={keys?.length}
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {values?.map((val, valueInd) => (
                <TableRow
                  key={valueInd}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {keys?.map((k, keyInd) => (
                    <>
                      {k.accessor === "answerKey" ? (
                        <TableCell key={keyInd} sx={{ ...tablebodystyle }}>
                          {val[k.accessor] === "View Answer Keys" ? (
                            <SaveButton
                          disabled = {val.currentStatus !== 'Published' } 
                                                      name={val[k.accessor]}
                              handleDraft={handleaAnsKeys}
                            />
                          ) : (
                            val[k.accessor]
                          )}
                        </TableCell>
                      ) : k.accessor === "select" ? (
                        <TableCell key={keyInd} sx={{ ...tablebodystyle }}>
                          {val[k.accessor] ? <Checkbox /> : "--"}
                        </TableCell>
                      ) : k.accessor === "explanation" ? (
                        <TableCell
                          key={keyInd}
                          sx={{
                            ...tablebodystyle,
                            ...(explanationStatusStyles[val[k.accessor]] || {}),
                          }}
                        >
                          {val[k.accessor] || "--"}
                        </TableCell>
                      ) : k.accessor === "raiseObjection" ? (
                        <TableCell key={keyInd} sx={{ ...tablebodystyle }}>
                          {val[k.accessor] ? (
                            <MauvelousPrimaryButton
                            disabled = {val.currentStatus !== 'Published' }   
                              title={val[k.accessor]}
                              handleClick={handleObjection}
                            />
                          ) : (
                            "--"
                          )}
                        </TableCell>
                      ) : k.accessor === "currentStatus" ? (
                        <TableCell
                          key={keyInd}
                          sx={{
                            ...tablebodystyle,
                            ...(currentStatusStyles[
                              val[k.accessor]
                            ] || {}),
                          }}
                        >
                          {val[k.accessor] || "--"}
                        </TableCell>
                      ) : k?.accessor === "documentname" ? (
                        <TableCell
                          sx={{
                            ...tablebodystyle,
                            textDecoration: val[k?.accessor]
                              ? "underline"
                              : "none",
                            color: val[k?.accessor] ? "#3e63dd" : "inherit",
                            cursor: val[k?.accessor] ? "pointer" : "default",
                          }}
                          onClick={() =>
                            val[k?.accessor] && handleViewImage(val)
                          }
                        >
                          {val[k?.accessor] ? "View" : "--"}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={keyInd}
                          sx={{
                            ...tablebodystyle,
                            ...(k.colorChange === "ticketStatus" &&
                            page === "queryList"
                              ? queryListMap[val[k.accessor]]
                              : k.colorChange === "ticketClosedDate" &&
                                page === "queryList" &&
                                val[k.accessor]
                              ? queryListMap[val[k.accessor]]
                              : ""),
                              whiteSpace: (k.accessor === 'ticketClosedOn' || k.accessor === 'ticketraisedon') ? 'nowrap' : 'normal'
                          }}
                        >
                          {val[k.accessor] ? val[k.accessor] : "--"}
                        </TableCell>
                      )}
                    </>
                  ))}
                  {page === "paymentReceiptList" && (
                    <TableCell sx={{ ...tablebodystyle }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handlePaymentReceipt(val)}
                      >
                        Open
                      </Button>
                    </TableCell>
                  )}
                    {page === "Admit Card List" && (
                    <TableCell sx={{ ...tablebodystyle }}>
                      <Typography
                        sx={{color:"blue","&:hover":{
                            cursor : "pointer",
                            textDecoration : "underline"
                        }}}
                        onClick={handleViewImageAdmitCard}
                      >
                        View
                      </Typography>
                    </TableCell>
                  )}

{page === "raisedobjection" && (
                    <TableCell sx={{ ...tablebodystyle }}>
                      <PrintDownloadButton disabled={val.explanation !== 'Explanation uploaded'} name={"View"} handleSubmit={handleView}/>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}

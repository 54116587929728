import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DataTable from "../../common/dataTable";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {EncryptFunction} from '../../utils/cryptoFunction'

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const paymentReceiptList = useSelector(
    (state) => state.candidatesReducer?.paymentReceiptList
  );

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: "GET_PAYMENT_RECEIPT_LIST",
        payload: { candidateid: candidateid },
      });
    }
  }, [candidateid]);

  const handleViewReceipt = (id) => {
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/payment-receipt-view/${EncryptFunction(id)}`,
      {}
    );
  };

  const statusMapping = {
    0: "Pending",
    1: "Success",
    2: "Failed",
    3: "Cancelled",
    4: "Refund",
    5: "Chargeback",
  };

  const columnData = [
    {
      name: "S No",
      accessor: "s_no",
    },
    {
      name: "Payment For",
      accessor: "payment_for",
    },
    {
      name: "Amount",
      accessor: "amount",
    },
    {
      name: "Paid On",
      accessor: "paid_on",
    },
    {
      name: "Status",
      accessor: "status",
    },
    {
      name: "Gateway",
      accessor: "gatewaycourseid",
    },
    {
      name: "View Receipt",
      accessor: "view_receipt",
    },
  ];

  let tableData = [
    {
      s_no: 1,
      payment_for: "Registration",
      amount: 1000,
      paid_on: "12/04/2024",
      view_receipt: (
        <p
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(241 91 80)",
          }}
          onClick={() => handleViewReceipt(1)}
        >
          View
        </p>
      ),
    },
    {
      s_no: 2,
      payment_for: "Admission",
      amount: 1000,
      paid_on: "12/04/2024",
      view_receipt: (
        <p
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "rgb(241 91 80)",
          }}
          onClick={() => handleViewReceipt(2)}
        >
          View
        </p>
      ),
    },
  ];
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formattedData = (originalData) => {
    return originalData.map((item, index) => ({
      s_no: index + 1, // Serial number (1-based index)
      payment_for: `Registration fees - ${item.coursename.trim()}`, // Static value or you can modify based on your logic
      amount: parseFloat(item.amount), // Convert amount to number
      paid_on: item.orderdate, // Format date
      status: item.status === 1 ? "Paid" : "Failed",
      // status: statusMapping[item.status] || "Unknown",
      view_receipt:
        parseInt(item.status) === 1 ? (
          <p
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "rgb(241 91 80)",
            }}
            onClick={() => handleViewReceipt(parseInt(item.transactionsid))}
          >
            View
          </p>
        ) : (
          "---"
        ),
        gatewaycourseid:item?.gatewaycourseid == 1 ? 'Billdesk' : item?.gatewaycourseid == 2 ? 'PayU' : '' 
    }));
  };

  return (
    <Box>
      {/* <Typography pb={1} variant="h5"  >Receipts</Typography> */}
      <Box paddingTop={2}>
        {/* <Typography pb={1} variant="h6"  >Your Receipts</Typography> */}
        <DataTable
          keys={columnData}
          values={paymentReceiptList ? formattedData(paymentReceiptList) : []}
        />
      </Box>
    </Box>
  );
};

export default Index;

import {
  Box,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { cssProperties } from "../../utils/commonCssProperties";
import { MdOutlineModeEdit } from "react-icons/md";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL, BILLDESK_URL, FRONTEND_URL } from "../../utils/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import { DecryptObjectFunction } from "../../utils/cryptoFunction";
export default function Index() {
  const location = useLocation();
  const courseid = location.state && location.state.courseid ? location.state.courseid : 0;

  const [searchParams] = useSearchParams();
  const encryptedData = searchParams.get("c_data");
  const decrypted = DecryptObjectFunction(encryptedData);
  const courseName = decrypted?.coursename;
  const edit = decrypted?.edit;
  const courseIdVal = decrypted?.courseid;
  const isNoExtraFee = decrypted?.isNoExtraFee;

  const [isChecked, setIsChecked] = useState(false);
  const [amount, setAmount] = useState("");
  const [open, setOpen] = useState(true);
  const [payuMkey, setPayuMkey] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  const {
    courseFullRegistrationDetails,
    personalDetails,
    FullCandidateDetails,
    currentCourseDetails,
  } = useSelector((state) => state.candidatesReducer);

  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
  }, []);

  const handleCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
      dispatch({
        type: candidatesActions.GET_COURSE_REGISTRATION_DETAILS,
        payload: { courseid: courseid || courseIdVal },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      dispatch({
        type: candidatesActions.GET_CURRENT_COURSE,
        payload: { courseid: courseid || courseIdVal},
      });
    } 
  }, [candidateid, courseid]);

  const cndDetails = useSelector(
    (state) => state.authReducer.candidateTokenDetails
  );

  const tableheadstyle = {
    color: `${cssProperties?.color?.primary}`,
    borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "17.6px",
    letterSpacing: "0.064px",
  };

  const tablebodystyle = {
    paddingTop: "16px",
    paddingBottom: "20px",
    color: `${cssProperties?.fontcolor?.tableblack}`,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "17.6px",
    letterSpacing: "0.038px",
    borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
  };

  useEffect(() => {
    if (
      courseFullRegistrationDetails?.length > 0 &&
      currentCourseDetails?.length > 0
    ) {
      let regStartDate = new Date(
        `${currentCourseDetails[0]?.regstartdate}T00:01`
      );
      let regEndDate = new Date(`${currentCourseDetails[0]?.regenddate}T23:59`);

      let lateFeeStartDate = new Date(
        `${currentCourseDetails[0]?.latefeestartdate}T00:01`
      );
      let lateFeeEndDate = new Date(
        `${currentCourseDetails[0]?.latefeeenddate}T23:59`
      );

      let coolingStartDate = new Date(
        `${currentCourseDetails[0]?.coolingstartdate}T00:01`
      );
      let coolingEndDate = new Date(
        `${currentCourseDetails[0]?.coolingenddate}T23:59`
      );

      let editingStartDate = new Date(
        `${currentCourseDetails[0]?.editingstartdate}T00:01`
      );
      let editingEndDate = new Date(
        `${currentCourseDetails[0]?.editingenddate}T23:59`
      );

      let editingExtraStartDate = new Date(
        `${currentCourseDetails[0]?.editingextrastartdate}T00:01`
      );
      let editingExtraEndDate = new Date(
        `${currentCourseDetails[0]?.editingextraenddate}T23:59`
      );

      let currentDate = new Date();
      let genkey = "";
      let nongenkey = "";
      if (currentDate <= regEndDate) {
        genkey = "generalamount";
        nongenkey = "nongeneralamount";
      } else if (
        currentDate <= lateFeeEndDate ||
        currentDate <= coolingEndDate ||
        currentDate <= editingEndDate
      ) {
        genkey = "lategeneralamount";
        nongenkey = "latenongeneralamount";
      } else if (currentDate <= editingExtraEndDate) {
        genkey = "generalextraamount";
        nongenkey = "nongeneralextraamount";
      }

      if (parseInt(courseFullRegistrationDetails[0]?.isdisability) === 1) {
        setAmount(currentCourseDetails[0]?.[nongenkey]);
        setOpen(true)
      } else if (parseInt(courseFullRegistrationDetails[0]?.isorphan) === 1) {
        setAmount(currentCourseDetails[0]?.[nongenkey]);
        setOpen(true)

      } else if (parseInt(courseFullRegistrationDetails[0]?.genderid) > 2) {
        setAmount(currentCourseDetails[0]?.[nongenkey]);
        setOpen(true)

      } else if (
        parseInt(courseFullRegistrationDetails[0]?.ismhcandidate) === 0
      ) {
        setAmount(currentCourseDetails[0]?.[genkey]);
        setOpen(false)

      } else if (
        parseInt(courseFullRegistrationDetails[0]?.ismhcandidate) === 1 &&
        parseInt(courseFullRegistrationDetails[0]?.categoryid) === 1
      ) {
        if (parseInt(courseFullRegistrationDetails[0]?.appliedforews) === 1) {
          if (parseInt(courseFullRegistrationDetails[0]?.familyincomeid) <= 15) {
            setAmount(currentCourseDetails[0]?.[nongenkey]);
            setOpen(true)

          } else {
            setAmount(currentCourseDetails[0]?.[genkey]);
            setOpen(false)

          }
        } else {
          setAmount(currentCourseDetails[0]?.[genkey]);
          setOpen(false)

        }
      } else if (
        parseInt(courseFullRegistrationDetails[0]?.ismhcandidate) === 1 &&
        parseInt(courseFullRegistrationDetails[0]?.categoryid) > 1
      ) {
        setAmount(currentCourseDetails[0]?.[nongenkey]);
        setOpen(true)

      }
    }
  }, [courseFullRegistrationDetails, currentCourseDetails]);

  function generateOrderId() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let orderId = "UATM";
    for (let i = 0; i < 10; i++) {
      orderId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return orderId;
  }

  function formatDate() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const tzHours = String(Math.floor(absOffset / 60)).padStart(2, "0");
    const tzMinutes = String(absOffset % 60).padStart(2, "0");
    const tzSign = offset > 0 ? "-" : "+";

    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0") +
      "T" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      tzSign +
      tzHours +
      ":" +
      tzMinutes
    );
  }

  const handleBilldeskSubmit = async () => {
    const date = formatDate();
    const orderid = generateOrderId();
    const orderDetails = {
      orderid: orderid,
      courseid: courseid || courseIdVal,
      amount: amount,
      currency: "356",
      order_date: date.toString(),
      ru: `${BILLDESK_URL}/billdesk-mhcet/status?furl=${FRONTEND_URL}/mhcet/billdesk-status?courseid=${courseid || courseIdVal}`,
      additional_info: {},
      device: {
        init_channel: "internet",
        ip: "192.192.192.192",
        user_agent: navigator.userAgent,
        accept_header: "text/html",
        browser_tz: "-330",
        browser_color_depth: "32",
        browser_java_enabled: "false",
        browser_screen_height: window.screen.height.toString(),
        browser_screen_width: window.screen.width.toString(),
        browser_language: navigator.language,
        browser_javascript_enabled: "true",
      },
    };
    try {
      const response = await axios.post(
        `${BILLDESK_URL}/billdesk-mhcet/create-order`,
        {
          orderDetails: orderDetails,
          candidateid: candidateid,
          referralcode: "test referal",
        }
      );

      const { bdorderid } = response?.data?.data;
      const merchantid = response?.data?.data?.mercid;
      const rdata = response.data.data.links[1].parameters.rdata;

      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk";

      const hiddenFields = {
        bdorderid,
        rdata: rdata,
        merchantid: merchantid,
      };
      for (const key in hiddenFields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = hiddenFields[key];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const [hash, setHash] = useState("");
  const [orderid, SetOrderId] = useState("");
  function orderidGenForPayU() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let orderId = "";
    for (let i = 0; i < 10; i++) {
      orderId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    console.log("order inside fun--", orderId);
    return String(orderId);
  }

  // Example usage

  const handlePayUSubmit = async (e) => {
    e.preventDefault();
    const date = formatDate();
    let newOrderId = orderidGenForPayU().toString();

    Cookies.set("orid", newOrderId);

    const getHashResponse = await axios
      .post(
        `${API_URL}/payu/hash`,
        {
          candidateid: candidateid,
          orderdate: date,
          name: cndDetails.candidatename,
          email: "test@gmail.com",
          amount: amount,
          transactionId: newOrderId,
          referralcode: "testref",
          courseid: courseid || courseIdVal,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        SetOrderId(res.data.transactionId);
        setPayuMkey(res.data.merchantKey);
        setHash(res.data.hash);
      });
  };

  useEffect(() => {
    if (hash !== "") {
      console.log(hash, orderid);
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://test.payu.in/_payment";

      const fields = [
        { name: "key", value: payuMkey },
        { name: "txnid", value: orderid },
        { name: "productinfo", value: "TEST PRODUCT" },
        { name: "amount", value: amount },
        { name: "email", value: "test@gmail.com" },
        { name: "firstname", value: cndDetails?.candidatename },
        { name: "lastname", value: "" },
        {
          name: "surl",
          value: `${API_URL}/payu/success?furl=${FRONTEND_URL}/mhcet/payu-status?courseid=${courseid || courseIdVal}&receiptno=${orderid}`,
        },
        {
          name: "furl",
          value: `${API_URL}/payu/failure?furl=${FRONTEND_URL}/mhcet/payu-status?courseid=${courseid || courseIdVal}&receiptno=${orderid}`,
        },
        { name: "phone", value: "testphone" },
        { name: "hash", value: hash },
      ];

      fields.forEach(({ name, value }) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);

      form.submit();
    }
  }, [hash]);

  return (
    <Box p={2} borderRadius={"8px"}>
      <Grid
        px={3}
        py={2}
        style={{
          minHeight: "50vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 }}
        >
          Application Fee Cart
        </Typography>
        <Grid container mt={1}>
          <Grid item xs={12}>
            <Box
              p={1}
              sx={{
                height: "48px",
                backgroundColor: "#EDEDED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Details of candidates on which fee is decided
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#212121",
                }}
              ></Box>
            </Box>
            <Grid container md={12}></Grid>
            <Grid container md={12}>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  Is Maharashtra Candidate?
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {parseInt(courseFullRegistrationDetails[0]?.ismhcandidate) ===
                  1
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  Category
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {/* {(courseFullRegistrationDetails[0]?.category?.trim() === "" || courseFullRegistrationDetails[0]?.category === null)
                    ? "-"
                    : courseFullRegistrationDetails[0]?.category === "Open"
                    ? "Open"
                    : "Reserved"} */}
                    {open ? "Reserved" : "Open"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  Applied for EWS?
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFullRegistrationDetails[0]?.appliedforews === "1"
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  Person With Disability
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFullRegistrationDetails[0]?.isdisability === "1"
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={5}>
            <Box
              p={1}
              sx={{
                height: "48px",
                backgroundColor: "#EDEDED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>Fees Details</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#212121",
                }}
              ></Box>
            </Box>
            <Grid container md={12}>
              <Grid
                item
                md={3}
                xs={12}
                lg={6}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  {" "}
                  {"Total Application fee required to Pay (₹)"}
                </Typography>
              </Grid>
              <Grid
                item
                md={9}
                lg={6}
                p={1}
                xs={12}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {amount}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={3}
                lg={6}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  {"Online Application Fee Paid (₹)"}
                </Typography>
              </Grid>
              <Grid
                item
                md={9}
                lg={6}
                p={1}
                xs={12}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {"0/-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid
                item
                md={3}
                lg={6}
                xs={12}
                p={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  {"Online Application Fee Paid (₹)"}
                </Typography>
              </Grid>
              <Grid
                item
                md={9}
                lg={6}
                p={1}
                xs={12}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {amount}
                </Typography>
              </Grid>
              <Grid
                item
                md={9}
                lg={12}
                p={1}
                xs={12}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Box
                  p={1}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: 500,
                  }}
                >
                  <span style={{ color: "#E6393E", padding: "0px 2px" }}>
                    Note:{" "}
                  </span>{" "}
                  {
                    "Following payment has not been done. Select the Fee Type(S) for which you want to pay the fee."
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12}>
          <Box sx={{ color: "#3F41D1", fontWeight: 600, marginTop: "30px" }}>
            {
              " Select the Fee Type to Make Payment & Click On 'Proceed To Payment>>>' Button "
            }
          </Box>
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`,
              borderRadius: "4px",
              margin: "14px 0px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...tableheadstyle }}>Select</TableCell>
                  <TableCell sx={{ ...tableheadstyle }}>Fee Type</TableCell>
                  <TableCell sx={{ ...tableheadstyle }}>
                    {"Total Fee (₹)"}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...tablebodystyle }}>
                    <Checkbox
                      checked={isChecked}
                      onChange={(e) => handleCheckbox(e)}
                    />
                  </TableCell>
                  <TableCell sx={{ ...tablebodystyle }}>
                    Application Fee
                  </TableCell>
                  <TableCell sx={{ ...tablebodystyle, fontWeight: 500 }}>
                    {amount}
                  </TableCell>
                </TableRow>
                {/* <TableRow>
  <TableCell sx={{...tablebodystyle}}>{"Total Fee (₹)"}</TableCell>
  <TableCell sx={{...tablebodystyle}}>0.00</TableCell>
</TableRow> */}
              </TableBody>
            </Table>

            <Grid container md={12}>
              <Grid
                item
                md={6}
                lg={6}
                xs={12}
                sm={6}
                py={2}
                px={1}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{ fontSize: 15, textAlign: "right", color: "#212121" }}
                >
                  {"Total fee(₹)"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                py={2}
                sm={6}
                px={1}
                xs={12}
                sx={{ border: "1px solid #F7F7F7" }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "right",
                    color: "#212121",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {amount}
                </Typography>
              </Grid>
            </Grid>
          </TableContainer>
        </Grid>

        {/* <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "24px" }}>{MauvelousPrimaryButton({ title: "Proceed to payment ", endIcon: <GoArrowRight />, handleClick : ()=> navigate(`/${Cookies.get("mhet_cnd_project")}/course-registration?page=printapplication`) })}  </Box> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          {MauvelousPrimaryButton({
            title: "Proceed to Billdesk payment ",
            endIcon: <GoArrowRight />,
            handleClick: handleBilldeskSubmit,
            disabled: !isChecked,
          })}{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
          {MauvelousPrimaryButton({
            title: "Proceed to PayU payment ",
            endIcon: <GoArrowRight />,
            handleClick: handlePayUSubmit,
            disabled: !isChecked,
          })}{" "}
        </Box>
      </Grid>
    </Box>
  );
}

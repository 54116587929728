import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { Typography } from "@mui/material";
import PageLoader from "../pageLoader";
import commonActions from "../../redux/common/actions";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

export default function VerifyEmailRedirection() {
    const candidateDetails = useSelector(
        (state) => state.authReducer.tempCandidateDetails
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const expiryTime = searchParams.get('expiry');
    console.log(candidateDetails, 'token');
    const mobileno = searchParams.get('mobileno');
    const email = searchParams.get('email');

    useEffect(() => {
        dispatch({ type: commonActions.SET_PAGE_LOADER, payload: true });
        if (token) {
            dispatch({
                type: authActions.VERIFY_EMAILID_FOR_SIGNUP, payload: {
                    data: {
                        token: token, email: email, mobileno: mobileno, expiryTime: expiryTime
                    }, navigate: navigate
                }
            })
        }
    }, [token]);

    return (
        <></>
        // <Typography variant="h4">Loading...</Typography>
    )
};

import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/sideBar";
import PageHeading from "../layouts/pageHeading";
import { cssProperties } from "../utils/commonCssProperties";
import { Typography, Link } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import Index from "../components/editingFacilityCourse";
import { DecryptObjectFunction } from "../utils/cryptoFunction";

export default function EditingFacility() {
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get('c_data');
  let decrypted = DecryptObjectFunction(encryptedParams);
  const coursename = decrypted?.coursename;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_cnd_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="1"
      style={{ textDecoration: "none" }}
      component={NavLink}
      to={`/${Cookies.get("mhet_cnd_project")}/course-selection`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Course selection
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      {coursename}
      <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];
  return (
    <>
      <Layout>
        <Header breadcrumbs={breadcrumbs} />
        <Sidebar />
        <PageHeading />
        <Index />
      </Layout>
    </>
  );
}

import authActions from "./actions";
import commonActions from "../common/actions";
import { takeEvery, call, all, put, delay, select } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import Cookies from "js-cookie";
import setAuthToken from "../../utils/setAuthToken";
import {
  DecryptFunction,
  EncryptFunction,
  EncryptObjectFunction,
} from "../../utils/cryptoFunction";

const authSagas = function* () {
  yield all([
    yield takeEvery(authActions.CANDIDATE_SINUP, candidateSignup),
    yield takeEvery(authActions.CHANGE_PASSWORD, candidateChangePassword),
    yield takeEvery(authActions.CANDIDATE_LOGIN, candidateLogin),
    yield takeEvery(
      authActions.CANDIDATE_FORGETPASSWORD,
      candidateForgetPassword
    ),
    yield takeEvery(authActions.CANDIDATE_TOKEN_VERIFY, candidateTokenVerify),
    yield takeEvery(
      authActions.VERIFY_EMAILID_FOR_SIGNUP,
      verifyEmailidForSignup
    ),
    yield takeEvery(authActions.CANDIDATE_LOGOUT, candidateLogout),
    yield takeEvery(
      authActions.CHECK_EMAIL_VERIFICAITON,
      checkEmailVerification
    ),
    yield takeEvery(
      authActions.RESEND_EMAIL_FOR_VERIFICATION,
      resendEmailForVerification
    ),
    yield takeEvery(
      authActions.VERIFY_MOBILENO_FOR_SIGNUP,
      verifyMobilenoForSignup
    ),
    yield takeEvery(
      authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB,
      getProfileRegistrationActiveTab
    ),
    yield takeEvery(
      authActions.CHECK_CANDIDATE_MOBILENO,
      checkCandidateMobileno
    ),
    yield takeEvery(
      authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      sendOtpForMobileVerification
    ),
    yield takeEvery(
      authActions.FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID,
      forgotPasswordMobileVerification
    ),
    yield takeEvery(
      authActions.CHECK_DUPLICATE_EMAIL_OR_MOBILENO,
      checkDuplicateEmailOrMobileno
    ),
    yield takeEvery(
      authActions.CANDIDATE_RESET_PASSWORD,
      candidateResetPassword
    ),
    yield takeEvery(
      authActions.CANDIDATE_FORGET_EMAILID,
      forgetEmailId
    ),
    yield takeEvery(
      authActions.FORGOTMAIL_OTP_VERIFY,
      forgetEmailIdOTPverify
    ),
    yield takeEvery(
      authActions.CHECK_KEYCLOAK_SID, checkKeycloakSessionId),
    yield takeEvery(
      authActions.GET_TEMP_CANDIDATE_DETAILS,
      getTempCanidateDetails
    ),
  ]);
};

const candidateSignup = function* (data) {
  const { payload } = data;
  const { setResendCountdown } = payload;
  const verifiedEmail = Cookies.get("mhet_cnd_email");
  const currentEmail = payload?.data?.emailid;
  Cookies.set('updated_email_id', currentEmail)
  const isEmailChanged = (verifiedEmail !== currentEmail) && (Cookies.get("mhet_cnd_email_verified") === "false");
  payload.data.isEmailChanged = isEmailChanged;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/signup`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: (Cookies.get("mhet_cnd_email_verified") === "true") ? false : true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      console.log(result, "result");

      // navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
      Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
      if (Cookies.get("mhet_cnd_email_verified") === "true") {
        setResendCountdown(120)
        yield put({
          type: commonActions.SET_SHOW_MODAL,
          payload: {
            show: true,
            type: "MV",
            method: "emailAndMobileVerification",
          },
        });
        yield put({
          type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
          payload: { data: { mobileno: payload?.data?.mobileno } },
        });
        // console.log(OTPResult,"result");
        Cookies.set(
          "mhet_cnd_tempdata",
          EncryptObjectFunction(result?.data?.data),
        );
        Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
      }
      else {
        setResendCountdown(120)
        yield put({
          type: commonActions.SET_SHOW_MODAL,
          payload: {
            show: true,
            type: "EV",
            method: "emailAndMobileVerification",
          },
        });
        Cookies.set("mhet_cnd_temptoken", result?.data?.token, { expires: 7 });
        Cookies.set(
          "mhet_cnd_tempdata",
          EncryptObjectFunction(result?.data?.data),
          { expires: 7 }
        );
        Cookies.set("mhet_cnd_email_verified", false);
        Cookies.set("mhet_cnd_mobileno_verified", false);
        Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
      }
      //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-email`);
      // setAuthToken(result?.data?.token);
      // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    }
    else if (result?.data?.statusCode === 600) {
      Cookies.set("mhet_cnd_email", result?.data?.data?.emailid);
      setResendCountdown(120)
      Cookies.set("mhet_cnd_mobileno_verified", false);
      Cookies.set("mhet_cnd_mobileno", payload?.data?.mobileno);
    }
    else {
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
    // Cookies.remove('mhet_cnd_token');
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};




const candidateLogin = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/login`, payload?.data)
    );
    // debugger
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/home`);
      Cookies.set("mhet_cnd_token", result?.data?.token, { expires: 7 });
      Cookies.set("mhet_cnd_sid", result?.data?.sid);
      setAuthToken(result?.data?.token);
      yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const candidateLogout = function* (data) {
  // const { payload } = data;
  // const { navigate } = payload;

  // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    let token = Cookies.get("mhet_cnd_token");
    if (token) {
      const result = yield call(() =>
        axios.post(`${API_URL}/candidate/logout`, { token: token })
      );
      // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        Cookies.remove("mhet_cnd_token");
        window.location.href = `/${Cookies.get("mhet_cnd_project")}/`;
        window.location.reload()
      }
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const candidateForgetPassword = function* (data) {
  const { payload } = data;
  const { navigate, setReset, setVerified, setEmailid } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/forgot-password`, payload?.data)
    );
    // debugger
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      // navigate(`/${Cookies.get('mhet_cnd_project')}/forgot-password-status`);
      // Cookies.set('mhet_cnd_token', result?.data?.token, { expires: 7 });
      // setAuthToken(result?.data?.token);
      setReset(false);
      setVerified(true);
      setEmailid(payload?.data?.email);
      // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    // window.location.href = `/${Cookies.get('mhet_cnd_project')}/login`;
  }
};

const candidateResetPassword = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/reset-password`, payload?.data)
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      navigate(
        `/${Cookies.get("mhet_cnd_project")}/reset-password?page=success`
      );
    }
    else {
      navigate(`/candidate/reset-password-failure`);
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (error) {
    console.log(error);
  }
};
// const candidateChangePassword = function* (data) {
//   const { payload } = data;
//   const { navigate } = payload;
//   yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
//   try {
//     const result = yield call(() =>
//       axios.put(`${API_URL}/candidate/change-password`, payload?.data)
//     );
//     yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
//     yield put({
//       type: commonActions.CHANGE_PASSWORD,
//       payload: {
//         status: result?.data?.statusCode === 200 ? "success" : "failed",
//         show: true,
//         message: result?.data?.message,
//       },
//     });
//     if (result?.data?.statusCode === 200) {
//     }
//     yield delay(2000);
//     yield put({
//       type: commonActions.SET_ALERT,
//       payload: { status: null, show: false, message: null },
//     });
//   } catch (error) {
//     console.log(error);
//   }
// };
const candidateChangePassword = function* (data) {
  const { payload } = data;
  const { setSuccess } = payload
  console.log(payload, "payload");

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(`${API_URL}/candidate/change-password`, payload?.data)
    );
    console.log(result, "result");

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
        show: true,
        message: result?.data?.message
      }
    });
    if (result?.data?.statusCode === 200) {
      setSuccess(false)

    };
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null }
    });

  } catch (error) {
    console.log(error);
  };
};


const candidateTokenVerify = function* () {
  try {
    const { candidateTokenDetails } = yield select(
      (state) => state.authReducer
    );
    if (!candidateTokenDetails) {
      const result = yield call(() =>
        axios.get(`${API_URL}/candidate/verify-token`, {
          headers: {
            authorization: `Bearer ${Cookies.get("mhet_cnd_token")}`,
          },
        })
      );
      if (result?.data?.statusCode === 200) {
        yield put({
          type: authActions.SET_CANDIDATE_TOKEN_DETAILS,
          payload: result?.data?.result,
        });
      } else {
        Cookies.remove("mhet_cnd_token");
        window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
      }
    }
    // else{
    //     Cookies.remove('mhet_cnd_token');
    //     window.location.href = `/${Cookies.get('mhet_cnd_project')}/`;
    // }
  } catch (err) {
    console.log(err);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

const verifyEmailidForSignup = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  console.log(payload, "payload");

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-emailid`, payload?.data)
    );
    if (result?.data?.statusCode === 200) {
      navigate(`/candidate/email-verification-status?status=success`);
    }
    // else if (result?.data?.statusCode === 600) {
    //   console.log(result?.data?.statusCode,"status");

    // } 
    else {
      navigate(`/candidate/email-verification-status?status=failed`);
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const checkEmailVerification = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-emailid-verified`, payload?.data)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "EV",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "EVS",
          method: "emailAndMobileVerification",
        },
      });
      Cookies.set("mhet_cnd_email_verified", true);
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const resendEmailForVerification = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/resend-verify-email`, payload?.data)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const sendOtpForMobileVerification = function* (data) {
  const { payload } = data;
  const { navigate, mobileno, setResendCountdown } = payload.data;
  try {
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/check-mobileno/${mobileno}`)
    );
    // console.log(result)
    if (result?.data?.statusCode === 200) {

      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: 'success', show: true, message: result?.data?.message },
      });
      yield delay(2000)
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
      Cookies.set("mhet_cnd_mobileno_otp", EncryptFunction(result?.data?.otp));
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "EVS",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "MV",
          method: "emailAndMobileVerification",
        },
      });
      setResendCountdown(120);
      //   navigate(`/${Cookies.get("mhet_cnd_project")}/verify-mobileno`);
    }

  } catch (err) {
    console.log(err);
  }
};
const getTempCanidateDetails = function* (data) {
  const { payload } = data;
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/get/mobileno/${payload?.emailid}`)
    );
    if (result) {
      console.log(result, "result");

      yield put({
        type: authActions.SET_TEMP_CANDIDATE_DETAILS,
        payload: result?.data?.statusCode === 200 ? result?.data?.result : []
      })
    }

  } catch (err) {
    console.log(err);
  }
};
const verifyMobilenoForSignup = function* (data) {
  const { payload } = data;
  const { navigate, setRed, setWrongotp, wrongOTP } = payload;
  const verifiedmob = Cookies.get("mhet_cnd_mobileno");
  const currentMob = payload?.data?.mobileno;
  const isMobChanged = (verifiedmob !== currentMob) && (Cookies.get("mhet_cnd_mobileno_verified") === "false");
  payload.data.isMobChanged = isMobChanged;
  const email = Cookies.get("mhet_cnd_email")
  payload.data.email = email;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-mobileno`, payload?.data)
    );
    if (result?.data?.statusCode === 200) {
      // navigate(`/${Cookies.get("mhet_cnd_project")}/mobileno-verification`);
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "Mv",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: true,
          type: "MVS",
          method: "emailAndMobileVerification",
        },
      });
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
      console.log("Before delay");
      yield delay(2000);
      console.log("After delay")
      yield put({
        type: commonActions.SET_SHOW_MODAL,
        payload: {
          show: false,
          type: "MVS",
          method: "emailAndMobileVerification",
        },
      });
      navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
      Cookies.set("mhet_cnd_mobileno_verified", true);
      Cookies.remove("mhet_cnd_mobileno");
      Cookies.set("mhet_cnd_email_verified", false);
      yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });

      setRed(false)
    }
    else {
      setWrongotp(wrongOTP + 1)
      setRed(true)
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
    // setRed(false)
  } catch (err) {
    console.log(err);
  }
};

const getProfileRegistrationActiveTab = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/profile-activetab/${payload?.id}`)
    );
    yield put({
      type: authActions.SET_PROFILE_REGISTRATION_ACTIVE_TAB,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : "new",
    });
  } catch (err) {
    console.log(err);
  }
};

const checkCandidateMobileno = function* (data) {
  const { payload } = data;
  const { handleShowOtpInput, setForget, setResendCountdown, setVerified } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/check-mobileno/${payload?.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      Cookies.set(
        "mhet_cnd_forgetemail_otp",
        EncryptFunction(result?.data?.otp)
      );
      setForget(false);
      setResendCountdown(120);
      handleShowOtpInput();
      setVerified(false)
      yield delay(2000);
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const forgotPasswordMobileVerification = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/send-mail-by-mobileno/${payload.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const checkDuplicateEmailOrMobileno = function* (data) {
  const { payload } = data;
  const { setAndClearError } = payload;

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-duplicate`, payload?.data)
    );
    if (result?.data) {
      setAndClearError({ ...result?.data, type: payload?.data?.type });
    }
    // if (result?.data?.statusCode === 200) {
    //     navigate(`/${Cookies.get('mhet_cnd_project')}/login`);
    // };
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: {
    //         status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
    //         show: true,
    //         message: result?.data?.message
    //     }
    // });
    // yield delay(2000);
    // yield put({
    //     type: commonActions.SET_ALERT,
    //     payload: { status: null, show: false, message: null }
    // });
  } catch (err) {
    console.log(err);
  }
};

const forgetEmailId = function* (data) {
  const { payload } = data;
  const { setForget, setResendCountdown, setVerified } = payload;

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/forgot-emailid/${payload?.data}`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      Cookies.set(
        "mhet_cnd_forgetemail_otp",
        EncryptFunction(result?.data?.otp)
      );
      Cookies.set('mhet_email', result?.data?.email)
      console.log(result, "result");

      setForget(false);
      setResendCountdown(120);
      setVerified(true)
      Cookies.set("mobile_no_forget_emailid", payload?.data);
      yield delay(2000)
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000)
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};
const forgetEmailIdOTPverify = function* (data) {
  const { payload } = data;
  const { setVerified, setForget, setRed, setWrongotp, wrongOTP } = payload;
  console.log(payload, "data");

  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/verify-OTP`, payload));
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    if (result?.data?.statusCode === 200) {
      setRed(false)
      setForget(false)
      setVerified(false)

    } else {
      setWrongotp(wrongOTP + 1)
      setRed(true)
    }

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },

    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
    setRed(false)
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};





const checkKeycloakSessionId = function* () {

  const data = { emailid: Cookies.get('mhet_cnd_email') }
  // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/check-keycloak-sid`, data)
    );
    // debugger
    // yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    // yield put({
    //   type: commonActions.SET_ALERT,
    //   payload: {
    //     status: result?.data?.statusCode === 200 ? "success" : "failed",
    //     show: true,
    //     message: result?.data?.message,
    //   },
    // });
    if (result?.data?.statusCode === 200) {

      const localSid = Cookies.get("mhet_cnd_sid");
      const sid = result?.data?.sid
      console.log('localSid-sid', localSid, sid);

      if (localSid != sid) {
        yield put({ type: authActions.CANDIDATE_LOGOUT });
      }


      // yield put({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    }
    // yield delay(2000);
    // yield put({
    //   type: commonActions.SET_ALERT,
    //   payload: { status: null, show: false, message: null },
    // });
  } catch (error) {
    console.log(error);
    Cookies.remove("mhet_cnd_token");
    window.location.href = `/${Cookies.get("mhet_cnd_project")}/login`;
  }
};

export default authSagas;

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "../protectedRoute/publicRoute";
import RouteVerification from "../common/routeVerification";
import Cookies from 'js-cookie';
import CandidateRoutes from "./candidateRoutes";
import NotFound from "../common/notfound";
import setAuthToken from "../utils/setAuthToken";
import { store } from '../redux/store';
import authActions from "../redux/auth/actions";
import VerifyEmailRedirection from "../common/verification/verifyEmailRedirection";
import VerifiedEmailPage from "../common/verification/verifiedEmailPage";
import { PiArrowCounterClockwiseDuotone } from "react-icons/pi";
import { useDispatch } from "react-redux";
import candidatesActions from "../redux/candidates/actions";
import ResetPasswordFaillure from "../common/verification/resetPasswordFaillure";


if (Cookies.get('mhet_cnd_token')) {
  setAuthToken(Cookies.get('mhet_cnd_token'));
  store.dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
};

const AppRoutes = () => {
const [count,setCount]  = useState(0);
const dispatch = useDispatch();



  useEffect(() => {
    if (Cookies.get('mhet_cnd_project') && window.location.pathname === '/') {
      if (Cookies.get('mhet_cnd_token')) {
        window.location.href = `${`/${Cookies.get('mhet_cnd_project')}/home`}`;
      } else {
        window.location.href = `${`/${Cookies.get('mhet_cnd_project')}/`}`;
      };
    };

  }, []);

//   useEffect(()=>{
  
//   // const interval = setInterval(() => {
//   // setCount(count+1);
//       console.log("testtt");
//     dispatch({type:authActions.CHECK_KEYCLOAK_SID})
//   // }, 60000);
// // return ()=> clearInterval(interval)
  
// },[])

  return (
    <>
      <Routes>
        <Route path="/candidate" element={<PublicRoute><RouteVerification /></PublicRoute>} />
        <Route path="/candidate/email-verification" element={<PublicRoute><VerifyEmailRedirection /></PublicRoute>} />
        <Route path="/candidate/email-verification-status" element={<PublicRoute><VerifiedEmailPage /></PublicRoute>} />
        <Route path="/candidate/reset-password-failure" element={<PublicRoute><ResetPasswordFaillure/></PublicRoute>} />
        <Route path="/*" element={<PublicRoute><NotFound /></PublicRoute>} />
        <Route path={Cookies.get('mhet_cnd_project') ? `${Cookies.get('mhet_cnd_project')}/*` : '/404'} element={<CandidateRoutes />} />
      </Routes>
    </>
  );
};

export default AppRoutes;

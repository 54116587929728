// import React, { useState } from "react";
// import Cropper from "react-easy-crop";
// import {
//   Modal,
//   Box,
//   Typography,
//   Grid,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   useMediaQuery,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import SubmitButton from "../../common/submitButton";
// import SaveButton from "../../common/saveButton";

// export default function ImageCrop({
//   showModal,
//   handleClose,
//   image,
//   onCropDone,
//   onCropCancel,
// }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedArea, setCroppedArea] = useState(null);
//   const [aspectratio, setAspectRatio] = useState(4 / 3);

//   const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
//     setCroppedArea(croppedAreaPixels);
//   };

//   const onAspectRatioChange = (event) => {
//     setAspectRatio(parseFloat(event.target.value));
//   };

//   return (
//     <Modal
//       open={showModal}
//       // onClose={handleClose}
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Box
//         sx={{
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: isMobile ? 2 : 4,
//           width: isMobile ? "90%" : 800,
//           maxWidth: isMobile ? "100%" : "none",
//         }}
//       >
//         <Typography id="crop-image-modal-title" variant="h6" component="h2">
//           Crop Image
//         </Typography>
//         <Box sx={{ height: isMobile ? "40vh" : "50vh", position: "relative", mt: 2 }}>
//           <Cropper
//             image={image}
//             aspect={aspectratio}
//             crop={crop}
//             zoom={zoom}
//             onCropChange={setCrop}
//             onZoomChange={setZoom}
//             onCropComplete={onCropComplete}
//             style={{
//               containerStyle: {
//                 height: "100%",
//                 width: "100%",
//                 backgroundColor: "#fff",
//               },
//             }}
//           />
//         </Box>
//         <RadioGroup
//           row
//           aria-label="aspect-ratio"
//           name="aspect-ratio"
//           value={aspectratio}
//           onChange={onAspectRatioChange}
//           sx={{
//             mt: 2,
//             display: "flex",
//             justifyContent: "center",
//             flexWrap: isMobile ? "wrap" : "nowrap",
//           }}
//         >
//           <FormControlLabel value={1 / 1} control={<Radio />} label="1:1" />
//           <FormControlLabel value={5 / 4} control={<Radio />} label="5:4" />
//           <FormControlLabel value={4 / 3} control={<Radio />} label="4:3" />
//           <FormControlLabel value={3 / 2} control={<Radio />} label="3:2" />
//           <FormControlLabel value={5 / 3} control={<Radio />} label="5:3" />
//           <FormControlLabel value={16 / 9} control={<Radio />} label="16:9" />
//           <FormControlLabel value={3 / 1} control={<Radio />} label="3:1" />
//         </RadioGroup>
//         <Grid
//           container
//           justifyContent="flex-end"
//           spacing={2}
//           sx={{ mt: 2 }}
//         >
//           <Grid item>
//             <SaveButton name="cancel" handleDraft={onCropCancel} />
//           </Grid>
//           <Grid item>
//             <SubmitButton
//               name="Crop & Apply"
//               handleSubmit={() => {
//                 onCropDone(croppedArea);
//               }}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//     </Modal>
//   );
// }

import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop, Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import './imageCrop.css'

const MIN_DIMENSION = 75;
const MAX_IMAGE_HEIGHT = 350;

export default function ImageCrop({
  showModal,
  handleClose,
  image,
  onCropDone,  
  onCropCancel
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [imgHeight,setImgHeight] =useState(null)
  const [imgWidth,setImgWidth] =useState(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

// useEffect(()=>{

//   alert(1)
// },[])

console.log('imgHeight---imgWidth',imgHeight,imgWidth);
  const onImageLoad = (e) => {
   
    //console.log("imageobject",e);
    const img = new Image();
    //const { width, height } = e.currentTarget;
  
    console.log("--targetimageobject--", e);
    console.log("--CropObject--",crop);
    //alert(e.target.naturalWidth);
    //alert(e.target.naturalHeight);
    setImgHeight(e.target.naturalHeight);
    setImgWidth(e.target.naturalWidth);
    //alert(imgHeight);
    //const cropWidthInPercent = (MIN_DIMENSION /  e.target.naturalWidth) * 100;

    const initialCrop = makeAspectCrop(
      {
        unit: "px",
        width: e.target.naturalWidth,
      },
      aspectRatio,
      e.target.naturalWidth,
      e.target.naturalHeight
    );
    const centeredCrop = centerCrop(initialCrop, e.target.naturalWidth, e.target.naturalHeight);
    setCrop(centeredCrop);
  };

  const onCropImage = () => {
    if (imgRef.current && crop) {
      // Convert percentage crop to pixel crop for accurate dimensions
      const pixelCrop = convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height);

      const canvas = document.createElement("canvas");
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        imgRef.current,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      const dataURL = canvas.toDataURL("image/jpeg");

      // Pass dataURL to the callback function with the crop details
      onCropDone(dataURL, pixelCrop);
    }
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
       
        
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: isMobile ? 2 : 0,
          height:"90%",
          width:"70%",
          // paddingTop:"50px",
          //overflow:"auto",
           maxWidth: isMobile ? "100vh" : "none",
           //overflow:"auto"
        }}
      >
        <Typography pt={1} px={2} id="crop-image-modal-title" variant="h6" component="h2">
          Crop Image
        </Typography>
        {image && (
          <Box
            sx={{
             // height: isMobile ? "40vh" : "80vh",
              //maxHeight: "80vh",
              //width:"80vh",
              height:"80%",
              overflow: "auto",
              //overflowX:"auto",
              //overflowY:"auto",
             // position: "relative",
              // mt: 2,
              display: "flex",
              alignItems: "top",
              justifyContent: "center",
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              aspect={aspectRatio}
              //minWidth={MIN_DIMENSION}
               style={{minHeight:imgHeight, minWidth:imgWidth}}
              //className="no-mask" // Add a custom class name for scoped styling
            >
              <img
                ref={imgRef}
                src={image}
                alt="Crop preview"
                
                //style={{height:imgHeight, width:imgWidth}}
               onLoad={onImageLoad}
                //style={{ height:'100vh',backgroundColor:'transparent'}}
                // style={{
                //   maxWidth: "100%",
                //   maxHeight: `${MAX_IMAGE_HEIGHT}px`,
                //   width: "auto",
                //   height: "auto"
                // }}              
                // style={{ marginTop:"250px" }}

              />
            </ReactCrop>
          </Box>
        )}

        <Grid container justifyContent="flex-end" spacing={2} sx={{ my: 1 }}>
          <Grid item>
            <SaveButton name="Cancel" handleDraft={onCropCancel} />
          </Grid>
          <Grid item>
            <SubmitButton name="Crop & Apply" handleSubmit={onCropImage} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

import React, { useState } from "react";
import DataTable from "../../common/dataTable";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import TablePagination from "../../common/tablePagination";
import SaveButton from "../../common/saveButton";

export default function RaisedObjection() {
const navigate = useNavigate()

const handleView = () =>{
    navigate(`/${Cookies.get('mhet_cnd_project')}/objection-details`)
  }
  
  const [correctAnswer,setCorrectAnswer] = useState("");
  const raisedObjectionsHeaders = [
    { name: "Question NO", accessor: "questionNo" },
    { name: "Question Details", accessor: "questionDetails" },
    { name: "Answer Key", accessor: "answerKey" },
    { name: "Your Response", accessor: "yourResponse" },
    { name: "Explanation", accessor: "explanation" },
  ];

  
  const raisedObjectionData = [
    {
      questionNo: 1,
      questionDetails:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "A",
      yourResponse: "B",
      explanation: "Explanation uploaded",
    },
    {
      questionNo: 2,
      questionDetails:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      answerKey: "B",
      yourResponse: "B",
      explanation: "Upload explanation",
    },
  ];

  const handleRaiseObjection = () => {
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/answer-key?page=objectiontracker`
    );
  };

  return (
    <Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MauvelousPrimaryButton
          title={"Raise Objection"}
          handleClick={handleRaiseObjection}
        />
      </Box>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }} pt={2}>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <Typography>
            Course : <b>B.ED - CET2024</b>
          </Typography>
          <Typography>
            Exam Date : <b>05.02.2024</b>{" "}
          </Typography>
        </Box>
      </Grid>
      <Typography>Raised Objection for the question that you wrote</Typography>
      <DataTable keys={raisedObjectionsHeaders} values={raisedObjectionData} page={"raisedobjection"} handleView={handleView} />
      <TablePagination />
      <Typography style={{ color: "red" }}>
        Plese type your explanation here to justify your answer with reference
      </Typography>
      <Grid mt={2}>
        <FormControl sx={{ width: "18rem" }} size="small">
          <InputLabel htmlFor="selectcorrectanswer">
            Select correct answer
          </InputLabel>
          <Select  MenuProps={{
                  disableScrollLock: true,
              }}
            size="small"
            id="selectcorrectanswer"
            label="Select correct answer"
            value={correctAnswer}
            onChange={(e)=>setCorrectAnswer(e.target.value)}
          >
            <MenuItem value={1}>A</MenuItem>
            <MenuItem value={2}>B</MenuItem>
            <MenuItem value={3}>C</MenuItem>
            <MenuItem value={4}>D</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container mt={2}>
        <Grid item md={6}>
          <TextField fullWidth type="textarea" rows={3}/>
        </Grid>
      </Grid>
      <Grid mt={2}>
        <MauvelousPrimaryButton title={"upload explanation"} />
      </Grid>
      <Grid mt={2} sx={{display:"flex",justifyContent:"flex-end"}}>
        <SaveButton name={"Make payment"} />
      </Grid>
    </Grid>
  );
}

import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { cssProperties } from "../utils/commonCssProperties";
import HelpManualVideoModal from "../components/modals/helpManualVideoModal";
import userManual from "../assets/document/userManual.pdf";

export default function BeforeLoginInformation() {
  const [showModal, setShowModal] = useState({ show: false, type: null });

  const handleTutorialVideo = () => {
    setShowModal({ show: true, type: "video"})
  };
  return (
    <>
      <Grid
        p={3}
        sx={{
          minHeight: "568px",
          maxwidth: "800px",
          background: `${cssProperties?.backgroundcolor?.secondary}`,
          border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
          borderRadius: "8px",
        }}
      >
        <Typography
          pt={1}
          pb={2}
          sx={{
            fontSize: "20px",
            color: `${cssProperties?.color?.tertiary}`,
            fontWeight: "bold",
          }}
        >
          Helpdesk Information:
        </Typography>
        <Typography
          pt={1}
          sx={{
            fontSize: "14px",
            color: `${cssProperties?.color?.tertiary}`,
            fontWeight: 400,
            lineHeight: "30px",
          }}
        >
          1.Candidates have to register and complete their candidate profile
          first to raise a ticket through the Helpdesk Ticketing System.
        </Typography>
        <Typography
          pt={1}
          sx={{
            fontSize: "14px",
            color: `${cssProperties?.color?.tertiary}`,
            fontWeight: 400,
            lineHeight: "30px",
          }}
        >
          2.For the candidates, who are facing any issues in registration and
          are unable to create their profile, please contact the below helpdesk
          numbers:
        </Typography>
        <ul style={{ paddingTop: "8px" }}>
          <li
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              lineHeight: "30px",
            }}
          >
            {" "}
            07969134401
          </li>
          <li
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              lineHeight: "30px",
            }}
          >
            {" "}
            07969134402
          </li>
          <li
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              lineHeight: "30px",
            }}
          >
            {" "}
            18002090191
          </li>
        </ul>

        <Typography
          pt={2}
          sx={{
            fontSize: "14px",
            color: `${cssProperties?.color?.tertiary}`,
            fontWeight: 400,
            lineHeight: "16px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Helpdesk Timings:</span> 09:00 am
          to 06:00 pm (All Days except Public Holidays)
        </Typography>

        <Box pt={2}>
          <Typography
            sx={{
              fontSize: "14px",
              color: `${cssProperties?.color?.tertiary}`,
              fontWeight: 400,
              lineHeight: "30px",
            }}
          >
            Raise a Ticket -{" "}
            <span
              style={{
                fontSize: "14px",
                color: `${cssProperties?.color?.primary}`,
                fontWeight: "bold",
              }}
            >
              <a style={{cursor:"pointer",textDecoration:"none",color:`${cssProperties?.color?.primary}`}} href={userManual} without rel="noopener noreferrer" target="_blank">User Manual</a> | <span style={{cursor:"pointer"}} onClick={handleTutorialVideo}>Tutorial Video</span>
            </span>
          </Typography>
        </Box>
      </Grid>
      <HelpManualVideoModal
        showModal={showModal?.type === "video" && showModal?.show}
        handleClose={() => setShowModal({ show: false, type: null })}
      />
    </>
  );
}
